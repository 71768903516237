/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { FC } from 'react';
import { IUserData, IDocument } from 'Models';
import { Link } from 'react-router-dom';

import BackgroundElement from '../BackgroundElement';
import ProfileInfoSidbar from '../ProfileInfoSidbar';
import DocumentTable from '../DocumentTable';

type Props = {
    userData: IUserData;
    documents: IDocument[];
    handleLogout(): void;
    handleShowDocumentModal(docId: string): void;
};

const Documents: FC<Props> = ({
    userData,
    handleLogout,
    documents,
    handleShowDocumentModal,
}) => {
    return (
        <>
            <BackgroundElement
                title="Mes documents"
                linkTitle="Acceuil"
                subTitle="Mes documents"
            />
            <section className="patient-dashboard bg-color-3">
                <ProfileInfoSidbar
                    userData={userData}
                    handleLogout={handleLogout}
                />
                <div className="right-panel">
                    <div className="content-container">
                        <div className="outer-container">
                            <div className="doctors-appointment my-patients">
                                <div className="title-box clearfix">
                                    <div className="text pull-left">
                                        <h3>Mes documents</h3>
                                    </div>
                                    <div
                                        className="btn-box pull-right"
                                        role="button"
                                        onClick={() => {}}
                                    >
                                        <Link to="/add-documents">
                                            <div className="theme-btn-one">
                                                Ajouter un document
                                                <i className="icon-image"></i>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <DocumentTable
                                    documents={documents}
                                    handleShowDocumentModal={
                                        handleShowDocumentModal
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Documents;
