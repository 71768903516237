/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC } from 'react';
import { IAppointementDetails } from 'Models';

import BackgroundElement from '../../components/BackgroundElement';
import AppointementDetailCard from '../../components/AppointementDetailCard';
import resources from '../../utils/resources';

type Props = {
    appointementDetails: IAppointementDetails;
    handleCancelAppointement(): void;
    handleUpdateAppointement(appointementDetails: IAppointementDetails): void;
};

const AppointmentDetails: FC<Props> = ({
    appointementDetails,
    handleCancelAppointement,
    handleUpdateAppointement,
}) => {
    return (
        <>
            <BackgroundElement
                title="Detail du rendez vous"
                linkTitle="Tableau de bord du patient"
                subTitle="Detail du rendez vous"
                linkPath="/patient-dashboard"
            />

            <section className="appointment-section bg-color-3">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-8 col-md-12 col-sm-12 left-column">
                            <div className="clinic-list-content list-item">
                                <div className="clinic-block-one">
                                    <div className="inner-box">
                                        <div className="pattern">
                                            <div
                                                className="pattern-1"
                                                style={{
                                                    backgroundImage: `url(${resources['ic-shap-24']})`,
                                                }}
                                            />
                                            <div
                                                className="pattern-2"
                                                style={{
                                                    backgroundImage: `url(${resources['ic-shap-25']})`,
                                                }}
                                            />
                                        </div>
                                        <figure className="image-box">
                                            <img
                                                src={
                                                    appointementDetails.data
                                                        .attributes.resource
                                                        .photo.medium_url
                                                }
                                                alt=""
                                            />
                                        </figure>
                                        <div className="content-box">
                                            {_.isEqual(
                                                appointementDetails.data
                                                    .attributes
                                                    .video_consultation,
                                                true,
                                            ) && (
                                                <div className="like-box">
                                                    <a
                                                        href={
                                                            appointementDetails
                                                                .data.attributes
                                                                .video_meet_url
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <i
                                                            className="fas fa-video"
                                                            style={{
                                                                animation:
                                                                    'blinker 2s linear infinite',
                                                            }}
                                                        />
                                                    </a>
                                                </div>
                                            )}

                                            <ul className="name-box clearfix">
                                                <li className="name">
                                                    <h3>
                                                        <span>
                                                            {
                                                                appointementDetails
                                                                    .data
                                                                    .attributes
                                                                    .resource
                                                                    .name
                                                            }
                                                        </span>
                                                    </h3>
                                                </li>
                                                <li>
                                                    <i className="icon-Trust-1" />
                                                </li>
                                                <li>
                                                    <i className="icon-Trust-2" />
                                                </li>
                                            </ul>
                                            <span className="designation">
                                                {
                                                    appointementDetails.data
                                                        .attributes.resource
                                                        .speciality
                                                }
                                            </span>
                                            <div className="text">
                                                <p>
                                                    Lorem ipsum dolor sit amet
                                                    consectur adipisc elit sed
                                                    eiusmod tempor incididunt
                                                    labore dolore magna.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="appointment-information">
                                <div className="title-box">
                                    <h3>Mes ordonnances</h3>
                                </div>

                                <div className="inner-box">
                                    <div className="single-box">
                                        <h3>
                                            Pas ordonnances disponible pour le
                                            moment
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AppointementDetailCard
                            handleCancelAppointement={handleCancelAppointement}
                            appointementDetails={appointementDetails}
                            handleUpdateAppointement={handleUpdateAppointement}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default AppointmentDetails;
