/* eslint-disable react/no-unescaped-entities */
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { InputEvent } from 'Types';

import resources from '../../utils/resources';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Form from '../../components/Form';
import BackgroundElement from '../../components/BackgroundElement';

type Props = {
    fields: any;
    errors: any;
    handleInputChange(e: InputEvent): void;
    handleOnClick(e: React.FormEvent<HTMLFormElement>): void;
};

const Login: FC<Props> = ({
    fields,
    errors,
    handleInputChange,
    handleOnClick,
}) => {
    return (
        <>
            <BackgroundElement
                title="Se connecter"
                linkTitle="Acceuil"
                subTitle="Se connecter"
            />

            <section className="registration-section bg-color-3">
                <div className="pattern">
                    <div
                        className="pattern-1"
                        style={{
                            backgroundImage: `url(${resources['ic-shap-85']})`,
                        }}
                    />
                    <div
                        className="pattern-2"
                        style={{
                            backgroundImage: `url(${resources['ic-shap-86']})`,
                        }}
                    />
                </div>
                <div className="auto-container">
                    <div className="inner-box">
                        <div className="content-box">
                            <div className="title-box">
                                <h3>Se connecter</h3>
                            </div>
                            <div className="inner">
                                <Form
                                    className="registration-form needs-validation"
                                    autoComplete="off"
                                    noValidate
                                >
                                    <div className="row clearfix">
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <Input
                                                type="email"
                                                name="email"
                                                label="Email"
                                                placeholder="Entrer votre Email"
                                                required
                                                htmlFor="validationCustom03"
                                                onChange={handleInputChange}
                                                value={fields.email}
                                                error={errors.email}
                                                className=""
                                                disabled={false}
                                                autocomplete=""
                                                onClick={() => {}}
                                                checked={false}
                                                id="email"
                                            />
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <Input
                                                type="password"
                                                name="password"
                                                label="Mot de passe"
                                                placeholder="Votre mot de passe"
                                                required
                                                htmlFor="validationCustom04"
                                                onChange={handleInputChange}
                                                value={fields.password}
                                                error={errors.password}
                                                className=""
                                                disabled={false}
                                                autocomplete=""
                                                onClick={() => {}}
                                                checked={false}
                                                id="password"
                                            />
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <div className="forgot-passowrd clearfix">
                                                <Link to="/forgot-passowrd">
                                                    Mot de passe oublié?
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn">
                                            <Button
                                                className="theme-btn-one"
                                                onClick={handleOnClick}
                                            >
                                                Se connecter
                                                <i className="icon-Arrow-Right" />
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                                <div className="text">
                                    <span>ou</span>
                                </div>

                                <div className="login-now">
                                    <p>
                                        Vous n'avez pas de compte ?{' '}
                                        <Link to="/register">S'inscrire</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Login;
