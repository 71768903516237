/* eslint-disable import/no-extraneous-dependencies */
import {
    ActionType,
    createStandardAction,
    createAsyncAction,
    isActionOf,
} from 'typesafe-actions';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import { RootAction, RootState, Services } from 'Types';
import { of } from 'rxjs';
import { Epic } from 'redux-observable';
import { IDocument } from 'Models';

import {
    ADD_DOCUMENT_REQUEST,
    ADD_DOCUMENT_SUCCESS,
    ADD_DOCUMENT_FAILURE,
    GET_DOCUMENT_REQUEST,
    GET_DOCUMENT_SUCCESS,
    GET_DOCUMENT_FAILURE,
    REMOVE_DOCUMENT_REQUEST,
    REMOVE_DOCUMENT_SUCCESS,
    REMOVE_DOCUMENT_FAILURE,
} from '../actionTypes';

interface IPayload {
    doc: FormData;
}

const getDocumentAsync = createAsyncAction(
    GET_DOCUMENT_REQUEST,
    GET_DOCUMENT_SUCCESS,
    GET_DOCUMENT_FAILURE,
)<any, IDocument[], any>();

const deleteDocumentAsync = createAsyncAction(
    REMOVE_DOCUMENT_REQUEST,
    REMOVE_DOCUMENT_SUCCESS,
    REMOVE_DOCUMENT_FAILURE,
)<string, any, any>();

const addDocumentAsync = createAsyncAction(
    ADD_DOCUMENT_REQUEST,
    ADD_DOCUMENT_SUCCESS,
    ADD_DOCUMENT_FAILURE,
)<IPayload, IDocument, any>();

export type DocumentsAction =
    | ActionType<typeof getDocumentAsync>
    | ActionType<typeof addDocumentAsync>
    | ActionType<typeof deleteDocumentAsync>;

const preparePayload = (docId: string) => {
    return {
        docId,
    };
};

const preparePayloadAddDocument = (doc: IPayload) => {
    return {
        doc,
    };
};

const mapGetDocument = (action: RootAction, { apiRequest }: Services) => {
    return apiRequest<IDocument[]>({
        path: '/documents',
        method: 'get',
        body: '',
    }).pipe(
        mergeMap((response: IDocument[]) => {
            if (response) {
                return of(getDocumentAsync.success(response));
            }
            return of(getDocumentAsync.failure(response));
        }),
        catchError((error) => {
            return of(getDocumentAsync.failure(error));
        }),
    );
};

const mapDeleteDocuments = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayload(action.payload);
    return apiRequest<any>({
        path: `${'/documents/'}${payload.docId}`,
        method: 'delete',
        body: '',
    }).pipe(
        mergeMap((response: any) => {
            if (response === null) {
                return of(deleteDocumentAsync.success(response));
            }
            return of(deleteDocumentAsync.failure(response));
        }),
        catchError((error) => {
            return of(deleteDocumentAsync.failure(error));
        }),
    );
};

const mapAddDocuments = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadAddDocument(action.payload);
    const params = {
        document: {
            'document[name]': 'Ordonnance Dr. Ernser',
            'document[document_type]': '57833-6',
        },
    };
    const str1 = `${'document[name]='}${'Ordonnance Dr. Schroeder DDS'}`;
    const str2 = `${'document[document_type]='}${'57833-6'}`;
    /*console.log('names data: ', document);
    const result = `${'document[name]='}${'Ordonnance Dr. Schroeder DDS'},
    ${'document[document_type]='}${'57833-6'}, ${'document[name]='}${'Ordonnance Dr. Schroeder DDS'}`;*/
    /*const form = new FormData();
    form.append(`${'document[name]='}`, `${'Ordonnance Dr. Schroeder DDS'}`);
    form.append(`${'document[document_type]='}`, `${'document[name]='}`);*/

    const obj = JSON.parse(
        '{"document[name]=Ordonnance Dr":"Ordonnance Dr. Bergnaum", "document[document_type]=":"57833-6"}',
    );

    return apiRequest<IDocument>({
        path: '/documents',
        method: 'post',
        body: '{"document[name]=Ordonnance Dr":"Ordonnance Dr. Bergnaum", "document[document_type]=":"57833-6"}',
    }).pipe(
        mergeMap((response: IDocument) => {
            if (response) {
                return of(addDocumentAsync.success(response));
            }
            return of(addDocumentAsync.failure(response));
        }),
        catchError((error) => {
            return of(addDocumentAsync.failure(error));
        }),
    );
};

const getDocumentEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(getDocumentAsync.request)),
        mergeMap((action) => mapGetDocument(action, dependency)),
    );

const removeDocumentEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(deleteDocumentAsync.request)),
        mergeMap((action) => mapDeleteDocuments(action, dependency)),
    );

const addDocumentEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(addDocumentAsync.request)),
        mergeMap((action) => mapAddDocuments(action, dependency)),
    );

export {
    getDocumentEpic,
    getDocumentAsync,
    deleteDocumentAsync,
    removeDocumentEpic,
    addDocumentAsync,
    addDocumentEpic,
};
