import { combineEpics } from 'redux-observable';

import {
    registerEpic,
    fetchLoginEpic,
    resetPasswordEpic,
    logoutEpic,
} from '../_actions/auth/auth.actions';

import { getSpecialityAsyncEpic } from '../_actions/speciality/specialities.actions';
import {
    resourcesEpic,
    resourcesDetailEpic,
    autocompletionResourcesEpic,
    resourcesBySearchEpic,
} from '../_actions/resources/resources.actions';

import { interventionsEpic } from '../_actions/interventions/interventions.actions';
import { availabilitiesEpic } from '../_actions/availabilities/availabilities.actions';
import {
    userProfilEpic,
    updateProfilEpic,
    resetUserPasswordEpic,
} from '../_actions/profil/profil.actions';

import {
    getFavoriteEpic,
    addFavoriteEpic,
    deleteFavoriteEpic,
} from '../_actions/favourite/favourite.actions';

import {
    getAppointementEpic,
    getAppointementDetailEpic,
    createAppointementEpic,
    updateAppointementEpic,
    cancelAppointementEpic,
} from '../_actions/apointement/appointement.actions';

import {
    relativeEpic,
    deleteRelativeEpic,
    addRelativeEpic,
    updateRelativeEpic,
} from '../_actions/relatives/relatives.actions';

import {
    getDocumentEpic,
    removeDocumentEpic,
    addDocumentEpic,
} from '../_actions/document/dcument.actions';

export default combineEpics(
    registerEpic,
    fetchLoginEpic,
    resetPasswordEpic,
    getSpecialityAsyncEpic,
    resourcesEpic,
    resourcesDetailEpic,
    interventionsEpic,
    availabilitiesEpic,
    userProfilEpic,
    getAppointementEpic,
    getAppointementDetailEpic,
    getFavoriteEpic,
    logoutEpic,
    createAppointementEpic,
    relativeEpic,
    addFavoriteEpic,
    deleteFavoriteEpic,
    deleteRelativeEpic,
    addRelativeEpic,
    updateRelativeEpic,
    updateAppointementEpic,
    cancelAppointementEpic,
    updateProfilEpic,
    resetUserPasswordEpic,
    getDocumentEpic,
    removeDocumentEpic,
    addDocumentEpic,
    autocompletionResourcesEpic,
    resourcesBySearchEpic,
);
