/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC } from 'react';
import { IAppointementData, IAppointement } from 'Models';
import resources from '../../utils/resources';

import AppointementTableCell from '../AppointementTableCell';

type Props = {
    appointement: IAppointement | [];
};

const AppointementTable: FC<Props> = ({ appointement }) => {
    return (
        <div className="doctors-list">
            <div className="table-outer">
                {_.isEmpty(_.get(appointement, 'data')) ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <img src={resources['ic-empty']} alt="" />
                    </div>
                ) : (
                    <table className="doctors-table">
                        <thead className="table-header">
                            <tr>
                                <th>Nom du docteur</th>
                                <th>Date</th>
                                <th>Statut</th>
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {typeof _.get(appointement, 'data') !==
                                'undefined' &&
                                _.get(appointement, 'data').length > 0 &&
                                _.get(appointement, 'data').map(
                                    (item: IAppointementData, index: any) => {
                                        return (
                                            <AppointementTableCell
                                                appointement={item}
                                            />
                                        );
                                    },
                                )}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default AppointementTable;
