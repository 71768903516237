/* eslint-disable react/button-has-type */
/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC } from 'react';
import { IResources } from 'Models';

import ListDoctorItem from '../../components/ListDoctorItem';
import GridDoctorItem from '../../components/GridDoctorItem';
import MapView from '../../components/Map';
import BackgroundElement from '../../components/BackgroundElement';
import Pagination from '../../components/Pagination';
import resources from '../../utils/resources';

type Props = {
    data: IResources;
    currentPage: number;
    handleSelectedPage(pageNumbber: number): void;
    handleAddToFavorite(id: string, favorited: boolean): void;
};

const Doctors: FC<Props> = ({
    data,
    handleAddToFavorite,
    currentPage,
    handleSelectedPage,
}) => {
    const page = Math.ceil(_.get(data, 'meta.count') / 20);
    return (
        <>
            <BackgroundElement
                title="Liste des médecins"
                linkTitle="Toutes les spécialités"
                subTitle="Liste des médecins"
                linkPath="/all-speciality"
            />

            {!_.isEmpty(data.data) ? (
                <section className="clinic-section doctors-page-section">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="col-lg-8 col-md-12 col-sm-12 content-side">
                                <div className="item-shorting clearfix">
                                    <div className="left-column pull-left">
                                        <h3>{`${_.get(data, 'meta.items')}${
                                            _.get(data, 'meta.count') > 1
                                                ? '  Praticiens trouvés. '
                                                : '  Praticien trouvé. '
                                        }`}</h3>
                                    </div>
                                    <div className="right-column pull-right clearfix">
                                        <div className="menu-box">
                                            <button className="list-view on">
                                                <i className="icon-List" />
                                            </button>
                                            <button className="grid-view">
                                                <i className="icon-Grid" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="wrapper list">
                                    {!!data.data &&
                                        data.data?.map((doctor, index) => {
                                            return (
                                                <ListDoctorItem
                                                    key={doctor.id.toString()}
                                                    id={doctor.id}
                                                    name={
                                                        doctor.attributes.name
                                                    }
                                                    speciality={
                                                        doctor.attributes
                                                            .speciality
                                                    }
                                                    imgUrl={
                                                        doctor.attributes.doctor
                                                            .photo.medium_url
                                                    }
                                                    handleAddToFavorite={
                                                        handleAddToFavorite
                                                    }
                                                    favorited={
                                                        doctor.attributes
                                                            .favorited!
                                                    }
                                                    city={
                                                        doctor.attributes.city
                                                    }
                                                    biography={
                                                        doctor.attributes.doctor
                                                            .biography!
                                                    }
                                                />
                                            );
                                        })}
                                    <div className="clinic-grid-content">
                                        <div className="row clearfix">
                                            {!!data.data &&
                                                data.data?.map(
                                                    (doctor, index) => {
                                                        return (
                                                            <GridDoctorItem
                                                                key={doctor.id.toString()}
                                                                id={doctor.id}
                                                                name={
                                                                    doctor
                                                                        .attributes
                                                                        .name
                                                                }
                                                                speciality={
                                                                    doctor
                                                                        .attributes
                                                                        .speciality
                                                                }
                                                                imgUrl={
                                                                    doctor
                                                                        .attributes
                                                                        .doctor
                                                                        .photo
                                                                        .large_url
                                                                }
                                                                favorited={
                                                                    doctor
                                                                        .attributes
                                                                        .favorited!
                                                                }
                                                                handleAddToFavorite={
                                                                    handleAddToFavorite
                                                                }
                                                            />
                                                        );
                                                    },
                                                )}
                                        </div>
                                    </div>
                                </div>

                                {typeof _.get(data, 'meta.count') !==
                                    'undefined' && (
                                    <Pagination
                                        count={_.get(data, 'meta.count')}
                                        currentPage={currentPage}
                                        handleSelectedPage={handleSelectedPage}
                                        page={page}
                                    />
                                )}
                            </div>
                            {/*<MapView />*/}
                        </div>
                    </div>
                </section>
            ) : (
                <div className="empty-doctor-list">
                    <h3>
                        {
                            "Désolé, nous n'avons malheureusement pas trouvé de praticiens."
                        }
                    </h3>
                    <img
                        src={resources['ic-empty-doctor-list']}
                        alt={resources['ic-empty-doctor-list']}
                    />
                </div>
            )}
        </>
    );
};

export default Doctors;
