/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import React, { FC } from 'react';
import { IUserData, IAppointement } from 'Models';
import BackgroundElement from '../BackgroundElement';
import ProfileInfoSidbar from '../ProfileInfoSidbar';
import AppointementTable from '../AppointementTable';

type Props = {
    userData: IUserData;
    appointement: IAppointement | [];
    isChecked: string;
    handeleCheck(name: string): void;
    handleLogout(): void;
};

const PatientDashboard: FC<Props> = ({
    userData,
    appointement,
    isChecked,
    handeleCheck,
    handleLogout,
}) => {
    return (
        <>
            <BackgroundElement
                title="Tableau de bord du patient"
                linkTitle="Acceuil"
                subTitle="Tableau de bord du patient"
            />

            <section className="patient-dashboard bg-color-3">
                <ProfileInfoSidbar
                    userData={userData}
                    handleLogout={handleLogout}
                />
                <div className="right-panel">
                    <div className="content-container">
                        <div className="outer-container">
                            <div className="doctors-appointment">
                                <div className="title-box">
                                    <h3>Mes rendez-vous</h3>
                                    <div className="btn-box">
                                        <ul className="select-box clearfix">
                                            <li>
                                                <div className="single-checkbox">
                                                    <input
                                                        type="radio"
                                                        name="check-box"
                                                        id="upcoming"
                                                        value="upcoming"
                                                        onClick={() =>
                                                            handeleCheck(
                                                                'upcoming',
                                                            )
                                                        }
                                                        checked={
                                                            isChecked ===
                                                            'upcoming'
                                                        }
                                                        defaultChecked={false}
                                                    />
                                                    <label htmlFor="upcoming">
                                                        <span></span>A venir
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="single-checkbox">
                                                    <input
                                                        type="radio"
                                                        name="check-box"
                                                        id="outdated"
                                                        value="outdated"
                                                        onClick={() =>
                                                            handeleCheck(
                                                                'outdated',
                                                            )
                                                        }
                                                        checked={
                                                            isChecked ===
                                                            'outdated'
                                                        }
                                                        defaultChecked={false}
                                                    />
                                                    <label htmlFor="outdated">
                                                        <span></span>Précédent
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="single-checkbox">
                                                    <input
                                                        type="radio"
                                                        name="check-box"
                                                        id="canceled"
                                                        value="canceled"
                                                        onClick={() =>
                                                            handeleCheck(
                                                                'canceled',
                                                            )
                                                        }
                                                        checked={
                                                            isChecked ===
                                                            'canceled'
                                                        }
                                                        defaultChecked={false}
                                                    />
                                                    <label htmlFor="canceled">
                                                        <span></span>Annuler
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <AppointementTable
                                    appointement={appointement}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PatientDashboard;
