/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC, Fragment } from 'react';
import MaterialUIButton from '@material-ui/core/Button';
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { ISpeciality, IAutocompletionResource } from 'Models';

import resources from '../../utils/resources';
import Form from '../Form';
import {
    mergeArray,
    formatLabel,
    consvertPacePredictions,
} from '../../utils/helpers';

type Props = {
    data: ISpeciality[];
    setSelected: any;
    selected: any;
    selectedAdresse: any;
    setSelectedAdresse: any;
    placePredictions: any;
    autocompletionLoading: boolean;
    isPlacePredictionsLoading: boolean;
    autocompletion: IAutocompletionResource;
    handleOnclick(): void;
    handleSearch(query: string): void;
    handleCheckCurrentLocation(): void;
    handleOnSelected(select: any): void;
    handlesOnSelectedAddress(adr: any): void;
    handleSearchAdresse(query: string): void;
};

const filterBy = () => true;

const Banner: FC<Props> = ({
    data,
    setSelected,
    selected,
    selectedAdresse,
    handleOnclick,
    handleSearch,
    autocompletionLoading,
    autocompletion,
    setSelectedAdresse,
    isPlacePredictionsLoading,
    placePredictions,
    handleCheckCurrentLocation,
    handleOnSelected,
    handleSearchAdresse,
    handlesOnSelectedAddress,
}) => {
    const renderRessourceMenu = (results: any, menuProps: any): JSX.Element => {
        let index: number = 0;
        const type = _.groupBy(results, 'type');
        const items = Object.keys(type)
            .sort()
            .map((region) => (
                <Fragment key={region}>
                    {index !== 0 && <Menu.Divider />}
                    <Menu.Header>{formatLabel(region)}</Menu.Header>
                    {type[region].map((i) => {
                        const item = (
                            <MenuItem key={index} option={i} position={index}>
                                <div>
                                    {typeof i.photo_url === 'undefined' ? (
                                        i.name
                                    ) : (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <img
                                                alt={i.name}
                                                src={i.photo_url}
                                                style={{
                                                    height: '24px',
                                                    marginRight: '10px',
                                                    width: '24px',
                                                }}
                                            />
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <div>{i.name}</div>
                                                <span>
                                                    <strong>
                                                        {i.speciality}
                                                    </strong>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </MenuItem>
                        );
                        index += 1;
                        return item;
                    })}
                </Fragment>
            ));
        return <Menu {...menuProps}>{items}</Menu>;
    };

    return (
        <section className="banner-section bg-color-1">
            <div
                className="pattern-layer"
                style={{
                    backgroundImage: `url(${resources['ic-shap-2']})`,
                }}
            ></div>
            <div className="auto-container">
                <div className="row align-items-center clearfix">
                    <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div className="content-box">
                            <h1>Rechercher un praticien</h1>

                            <div className="form-inner">
                                <Form>
                                    <div className="form-group">
                                        <AsyncTypeahead
                                            id="basic-example"
                                            onChange={(_selected) => {
                                                handlesOnSelectedAddress(
                                                    _selected,
                                                );
                                                setSelectedAdresse(_selected);
                                            }}
                                            isLoading={
                                                isPlacePredictionsLoading
                                            }
                                            options={consvertPacePredictions(
                                                placePredictions,
                                            )}
                                            filterBy={filterBy}
                                            placeholder="Où ?"
                                            selected={selectedAdresse}
                                            minLength={3}
                                            onSearch={handleSearchAdresse}
                                            labelKey={(option) =>
                                                `${_.get(option, 'name')}`
                                            }
                                            renderMenuItemChildren={(
                                                option,
                                                props,
                                            ) => (
                                                <>
                                                    <span>
                                                        {_.get(option, 'name')}
                                                    </span>
                                                </>
                                            )}
                                        />
                                        <MaterialUIButton
                                            onClick={handleCheckCurrentLocation}
                                        >
                                            <i className="fal fa-location"></i>
                                        </MaterialUIButton>
                                    </div>
                                </Form>
                                <Form>
                                    <div className="form-group">
                                        <AsyncTypeahead
                                            isLoading={autocompletionLoading}
                                            id="async-example"
                                            onChange={(_selected) => {
                                                handleOnSelected(_selected);
                                                setSelected(_selected);
                                            }}
                                            filterBy={filterBy}
                                            onSearch={handleSearch}
                                            options={mergeArray(autocompletion)}
                                            placeholder="Médecin, établissement, spécialité..."
                                            minLength={3}
                                            positionFixed={Boolean(true)}
                                            renderMenu={renderRessourceMenu}
                                            labelKey={(option) =>
                                                `${_.get(option, 'name')}`
                                            }
                                        />
                                        <MaterialUIButton
                                            onClick={handleOnclick}
                                        >
                                            <i className="icon-Arrow-Right" />
                                        </MaterialUIButton>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                        <div className="image-box js-tilt">
                            <figure
                                className="image clearfix wow fadeInRight animated animated"
                                data-wow-delay="00ms"
                                data-wow-duration="1500ms"
                            >
                                <img src={resources['ic-banner']} alt="" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
