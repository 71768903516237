/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'Types';
import { Redirect } from 'react-router-dom';
import withLoader from '../../shared/hoc/withLoader';
import Header from '../../components/Header';
import useForm from '../../shared/hoc/useForm';
import { isValidEmail } from '../../utils/helpers';
import Login from '../../containers/Login';
import {
    fetchLoginAsync,
    resetUserFeedback,
} from '../../_actions/auth/auth.actions';
import WordingConstant from '../../utils/wording.json';
import Snackbar from '../../components/Snackbar';
import AuthService from '../../services/auth-service';
import useScript from '../../utils/hooks/useScript';
import Footer from '../../components/Footer';
import useOutsideClick from '../../utils/hooks/useOutsideClick';

const initialFValues = {
    id: 0,
    email: '',
    password: '',
};

const Wording = WordingConstant.Register;
const WrappedLogin = withLoader()(Login);

const LoginScreen: FC<unknown> = () => {
    const dispatch = useDispatch();
    const isSignIn = AuthService.isAuthenticated();
    const divRef = useRef<HTMLDivElement>(null);

    useScript('/static/js/jquery.nice-select.min.js');
    useScript('/static/js/script.js');
    useScript('/static/js/scrollbar.js');

    const { error, loading } = useSelector(
        (state: RootState) => state.authReducer,
    );

    const { start } = useSelector(
        (state: RootState) => state.appointementReducer,
    );

    const validate = (fieldValues = values) => {
        const temp: any = { ...errors };

        if ('email' in fieldValues) {
            temp.email = isValidEmail(fieldValues.email)
                ? ''
                : Wording.Errors.email;
        }
        if ('password' in fieldValues) {
            temp.password = fieldValues.password ? '' : Wording.Errors.password;
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
    };

    const handleOnClick = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validate()) {
            dispatch(
                fetchLoginAsync.request({
                    email: values.email,
                    password: values.password,
                }),
            );
        }
    };

    const handleSnackClose = () => {
        dispatch(resetUserFeedback());
    };

    const { values, errors, setErrors, handleInputChange } = useForm(
        initialFValues,
        true,
        validate,
    );

    if (isSignIn && start) {
        return <Redirect to={{ pathname: '/appointement' }} />;
    }
    if (isSignIn && _.isEqual(start, false)) {
        return <Redirect to={{ pathname: '/patient-dashboard' }} />;
    }

    return (
        <div ref={divRef}>
            <Header />
            <WrappedLogin
                loading={loading}
                fields={values}
                errors={errors}
                handleInputChange={handleInputChange}
                handleOnClick={handleOnClick}
            />

            {typeof _.get(error, 'response.success') !== 'undefined' &&
                _.isEqual(_.get(error, 'response.success'), false) && (
                    <Snackbar
                        message={_.get(error, 'response.errors')[0]}
                        isError={_.isEqual(
                            _.get(error, 'response.success'),
                            false,
                        )}
                        onClose={handleSnackClose}
                    />
                )}
            <Footer />
        </div>
    );
};

export default LoginScreen;
