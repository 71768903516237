import { IUserData, IResourcesDetails } from 'Models';

export const initialDetail: IResourcesDetails = {
    data: {
        id: '',
        type: '',
        attributes: {
            name: '',
            created_at: new Date(),
            speciality: '',
            video_consultation: false,
            doctor: {
                email: '',
                first_name: '',
                last_name: '',
                photo: {
                    small_url: '',
                    medium_url: '',
                    large_url: '',
                },
                city: '',
                video_consultation: false,
            },
            city: '',
            country_code: '',
            favorited: false,
        },
        meta: {
            interventions_url: '',
        },
    },
    meta: {
        interventions_url: '',
    },
};

export const tee = {};

export const initialUserProfilData: IUserData = {
    id: '',
    type: '',
    attributes: {
        email: '',
        card: {
            first_name: '',
            last_name: '',
            phone: '',
            birthdate: '',
            address: '',
            zip_code: '',
            city: '',
            country_code: '',
            dmp_id: '',
            default: false,
            gender: '',
            full_name: '',
            birth_country_code: '',
            birth_city: '',
        },
        created_at: new Date(),
    },
};
