import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { IInterventionData } from 'Models';

import * as interventionAction from '../../_actions/interventions/interventions.actions';
import * as authActions from '../../_actions/auth/auth.actions';

export type ResourcesState = Readonly<{
    loading: boolean;
    interventions: IInterventionData[];
    error: string;
}>;

export const initialState: ResourcesState = {
    loading: false,
    interventions: [],
    error: '',
};

type Actions = interventionAction.InterventionAction | authActions.AuthAction;

const interventionsReducer: Reducer<ResourcesState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(interventionAction.getInterventionAsync.request): {
            return {
                ...state,
                loading: true,
                error: '',
            };
        }

        case getType(interventionAction.getInterventionAsync.success): {
            return {
                ...state,
                loading: false,
                interventions: action.payload.data,
            };
        }

        case getType(interventionAction.getInterventionAsync.failure): {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }

        case getType(authActions.logoutAsync.success): {
            return {
                ...state,
                loading: false,
                interventions: [],
                error: '',
            };
        }

        default:
            return { ...state };
    }
};
export default interventionsReducer;
