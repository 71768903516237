/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC } from 'react';
import {
    IData,
    IInterventionData,
    IRootAvailability,
    IAvailability,
} from 'Models';
import { InputEvent } from 'Types';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from 'react-modern-calendar-datepicker';
import { Link } from 'react-router-dom';
import Input from '../../components/Input';
import BackgroundElement from '../../components/BackgroundElement';
import DoctorTabsBox from '../../components/DoctorTabsBox';
import DoctorCard from '../../components/DoctorCard';
import TimeItem from '../../components/TimeItem';
import AppointementHeader from '../../components/AppointementHeader';
import TypeConsultation from '../../components/TypeConsultation';
import ReasonConsultation from '../../components/ReasonConsultation';
import SelectDate from '../../components/SelectDate';

type DProps = {
    year: number;
    month: number;
    day: number;
};

type Props = {
    showDatepicker: boolean;
    selectedDay: any;
    isUpdated: boolean;
    startUpdated: boolean;
    resourcesDetail: IData;
    selectedReason: string;
    isTimeSelected: boolean;
    interventions: IInterventionData[];
    isVideoConsultation: boolean;
    isCabinetConsultation: boolean;
    availabilities: IRootAvailability;
    handleChecked(e: InputEvent): void;
    onValueChange(e: InputEvent): void;
    handleDateChange(e: DProps): void;
    handleShowDatePicker(): void;
    onHandleSelectedTime(availability: IAvailability): void;
};

const DoctorsDetails: FC<Props> = ({
    resourcesDetail,
    handleChecked,
    interventions,
    selectedReason,
    onValueChange,
    isVideoConsultation,
    isCabinetConsultation,
    selectedDay,
    showDatepicker,
    handleDateChange,
    handleShowDatePicker,
    availabilities,
    isUpdated,
    startUpdated,
    isTimeSelected,
    onHandleSelectedTime,
}) => {
    /*const url = `${window.location.pathname}`;
    const specialityId = url.split('/').pop();*/

    return (
        <>
            <BackgroundElement
                title="Détails du médecin"
                linkTitle="Liste des médecins"
                subTitle="Détails du médecin"
                linkPath={''}
            />
            <section className="doctor-details bg-color-3">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-8 col-md-12 col-sm-12 content-side">
                            <div className="clinic-details-content doctor-details-content">
                                <DoctorCard
                                    profilImg={
                                        resourcesDetail.attributes.doctor.photo
                                            .large_url
                                    }
                                    name={resourcesDetail.attributes.name}
                                    speciality={
                                        resourcesDetail.attributes.speciality
                                    }
                                    city={resourcesDetail.attributes.city}
                                    videoConsultation={
                                        resourcesDetail.attributes
                                            .video_consultation
                                    }
                                    biography={
                                        resourcesDetail.attributes.doctor
                                            .biography!
                                    }
                                />
                                <DoctorTabsBox
                                    resourcesDetail={resourcesDetail}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                            <div className="doctors-sidebar">
                                <div className="form-widget">
                                    <AppointementHeader
                                        startUpdated={startUpdated}
                                    />
                                    <TypeConsultation
                                        startUpdated={startUpdated}
                                        isVideoConsultation={
                                            isVideoConsultation
                                        }
                                        isCabinetConsultation={
                                            isCabinetConsultation
                                        }
                                        handleChecked={handleChecked}
                                        resourcesDetail={resourcesDetail}
                                    />
                                    <ReasonConsultation
                                        selectedReason={selectedReason}
                                        interventions={interventions}
                                        isCabinetConsultation={
                                            isCabinetConsultation
                                        }
                                        isVideoConsultation={
                                            isVideoConsultation
                                        }
                                        onValueChange={onValueChange}
                                    />
                                    <SelectDate
                                        isCabinetConsultation={
                                            isCabinetConsultation
                                        }
                                        isVideoConsultation={
                                            isVideoConsultation
                                        }
                                        selectedDay={selectedDay}
                                        selectedReason={selectedReason}
                                        showDatepicker={showDatepicker}
                                        handleDateChange={handleDateChange}
                                        handleShowDatePicker={
                                            handleShowDatePicker
                                        }
                                        availabilities={availabilities}
                                        isTimeSelected={isTimeSelected}
                                        onHandleSelectedTime={
                                            onHandleSelectedTime
                                        }
                                    />
                                    {/*** Update Appointement à revoir après pour refacto */}
                                    {_.isEqual(startUpdated, true) && (
                                        <div className="form-inner">
                                            <div className="appointment-time">
                                                <h3>Sélectionnez une date </h3>
                                                <div className="form-group">
                                                    <Input
                                                        type="text"
                                                        name="date"
                                                        placeholder={`${selectedDay.day}/${selectedDay.month}/${selectedDay.year}`}
                                                        value={`${selectedDay.day}/${selectedDay.month}/${selectedDay.year}`}
                                                        id="datepicker"
                                                        onClick={
                                                            handleShowDatePicker
                                                        }
                                                        onChange={() => {}}
                                                        className=""
                                                        disabled={false}
                                                        autocomplete=""
                                                        required={false}
                                                        checked={false}
                                                        label=""
                                                        htmlFor=""
                                                        error=""
                                                    />

                                                    {!!showDatepicker && (
                                                        <Calendar
                                                            value={selectedDay}
                                                            onChange={
                                                                handleDateChange
                                                            }
                                                            shouldHighlightWeekends
                                                            colorPrimary="#9c88ff"
                                                            calendarClassName="custom-calendar"
                                                            calendarTodayClassName="custom-today-day"
                                                        />
                                                    )}
                                                    <i className="fas fa-calendar-alt"></i>
                                                </div>

                                                <div
                                                    className="wrapper"
                                                    role="button"
                                                    tabIndex={0}
                                                >
                                                    {!_.isEmpty(
                                                        availabilities.data,
                                                    ) &&
                                                        availabilities.data[0].availabilities.map(
                                                            (availabilitie) => {
                                                                return (
                                                                    <TimeItem
                                                                        key={availabilitie.id.toString()}
                                                                        id={availabilitie.id.toString()}
                                                                        startsAt={
                                                                            availabilitie.starts_at
                                                                        }
                                                                        handleClick={() =>
                                                                            onHandleSelectedTime(
                                                                                availabilitie,
                                                                            )
                                                                        }
                                                                    />
                                                                );
                                                            },
                                                        )}
                                                </div>
                                                {_.isEmpty(
                                                    availabilities.data,
                                                ) && (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        Pas de disponibilité
                                                    </div>
                                                )}
                                            </div>

                                            <div className="choose-service">
                                                {!_.isEmpty(
                                                    availabilities.data,
                                                ) &&
                                                    _.isEqual(
                                                        isTimeSelected,
                                                        true,
                                                    ) && (
                                                        <div className="btn-box">
                                                            <Link to="/appointement">
                                                                <span className="theme-btn-one">
                                                                    Modifier le
                                                                    rendez-vous
                                                                    <i className="icon-Arrow-Right"></i>
                                                                </span>
                                                            </Link>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default DoctorsDetails;
