export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

export const FETCH_LOGIN_REQUEST = 'FETCH_LOGIN_REQUEST';
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
export const FETCH_LOGIN_FAILURE = 'FETCH_LOGIN_FAILURE';

export const RESET_USER_FEEDBACK = 'RESET_USER_FEEDBACK';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const GET_SPECIALITY_REQUEST = 'GET_SPECIALITY_REQUEST';
export const GET_SPECIALITY_SUCCESS = 'GET_SPECIALITY_SUCCESS';
export const GET_SPECIALITY_FAILURE = 'GET_SPECIALITY_FAILURE';

export const GET_RESOURCES_BY_SPECIALITY_ID_REQUEST =
    'GET_RESOURCES_BY_SPECIALITY_ID_REQUEST';
export const GET_RESOURCES_BY_SPECIALITY_ID_SUCCESS =
    'GET_RESOURCES_BY_SPECIALITY_ID_SUCCESS';
export const GET_RESOURCES_BY_SPECIALITY_ID_FAILURE =
    'GET_RESOURCES_BY_SPECIALITY_ID_FAILURE';

export const GET_DOCTOR_DETAILS_REQUEST = 'GET_DOCTOR_DETAILS_REQUEST';
export const GET_DOCTOR_DETAILS_SUCCESS = 'GET_DOCTOR_DETAILS_SUCCESS';
export const GET_DOCTOR_DETAILS_FAILURE = 'GET_DOCTOR_DETAILS_FAILURE';

export const GET_INTERVENTIONS_REQUEST = 'GET_INTERVENTIONS_REQUEST';
export const GET_INTERVENTIONS_SUCCESS = 'GET_INTERVENTIONS_SUCCESS';
export const GET_INTERVENTIONS_FAILURE = 'GET_INTERVENTIONS_FAILURE';

export const GET_AVAILABILITIES_REQUEST = 'GET_AVAILABILITIES_REQUEST';
export const GET_AVAILABILITIES_SUCCESS = 'GET_AVAILABILITIES_SUCCESS';
export const GET_AVAILABILITIES_FAILURE = 'GET_AVAILABILITIES_FAILURE';

export const SET_DOCTOR_SUMMAY_REQUEST = 'SET_DOCTOR_SUMMAY_REQUEST';
export const RESET_DOCTOR_SUMMAY = 'RESET_DOCTOR_SUMMAY';

export const SET_INTERVENTION_SUMMAY_REQUEST =
    'SET_INTERVENTION_SUMMAY_REQUEST';
export const SET_AVAILABILITIES_SUMMAY_REQUEST =
    'SET_AVAILABILITIES_SUMMAY_REQUEST';

export const GET_USER_PROFIL_REQUEST = 'GET_USER_PROFIL_REQUEST';
export const GET_USER_PROFIL_SUCCESS = 'GET_USER_PROFIL_SUCCESS';
export const GET_USER_PROFIL_FAILURE = 'GET_USER_PROFIL_FAILURE';

export const UPDATE_USER_PROFIL_REQUEST = 'UPDATE_USER_PROFIL_REQUEST';
export const UPDATE_USER_PROFIL_SUCCESS = 'UPDATE_USER_PROFIL_SUCCESS';
export const UPDATE_USER_PROFIL_FAILURE = 'UPDATE_USER_PROFIL_FAILURE';

export const START_APPOINTEMENT_REQUEST = 'START_APPOINTEMENT_REQUEST';

export const GET_APPOINTEMENT_REQUEST = 'GET_APPOINTEMENT_REQUEST';
export const GET_APPOINTEMENT_SUCCESS = 'GET_APPOINTEMENT_SUCCESS';
export const GET_APPOINTEMENT_FAILURE = 'GET_APPOINTEMENT_FAILURE';

export const CREATE_APPOINTEMENT_REQUEST = 'CREATE_APPOINTEMENT_REQUEST';
export const CREATE_APPOINTEMENT_SUCCESS = 'CREATE_APPOINTEMENT_SUCCESS';
export const CREATE_APPOINTEMENT_FAILURE = 'CREATE_APPOINTEMENT_FAILURE';
export const RESET_APPOINTEMENT_FEEDBACK = 'RESET_APPOINTEMENT_FEEDBACK';

export const GET_APPOINTEMENT_DETAILS_REQUEST =
    'GET_APPOINTEMENT_DETAILS_REQUEST';
export const GET_APPOINTEMENT_DETAILS_SUCCESS =
    'GET_APPOINTEMENT_DETAILS_SUCCESS';
export const GET_APPOINTEMENT_DETAILS_FAILURE =
    'GET_APPOINTEMENT_DETAILS_FAILURE';

export const UPDATE_APPOINTEMENT_REQUEST = 'UPDATE_APPOINTEMENT_REQUEST';
export const UPDATE_APPOINTEMENT_SUCCESS = 'UPDATE_APPOINTEMENT_SUCCESS';
export const UPDATE_APPOINTEMENT_FAILURE = 'UPDATE_APPOINTEMENT_FAILURE';

export const CANCEL_APPOINTEMENT_REQUEST = 'CANCEL_APPOINTEMENT_REQUEST';
export const CANCEL_APPOINTEMENT_SUCCESS = 'CANCEL_APPOINTEMENT_SUCCESS';
export const CANCEL_APPOINTEMENT_FAILURE = 'CANCEL_APPOINTEMENT_FAILURE';

export const START_UPDATED_APPOINTEMENT_REQUEST =
    'START_UPDATED_APPOINTEMENT_REQUEST';

export const GET_FAVORITE_REQUEST = 'GET_FAVORITE_REQUEST';
export const GET_FAVORITE_SUCCESS = 'GET_FAVORITE_SUCCESS';
export const GET_FAVORITE_FAILURE = 'GET_FAVORITE_FAILURE';

export const ADD_FAVORITE_REQUEST = 'ADD_FAVORITE_REQUEST';
export const ADD_FAVORITE_SUCCESS = 'ADD_FAVORITE_SUCCESS';
export const ADD_FAVORITE_FAILURE = 'ADD_FAVORITE_FAILURE';

export const DELETE_FAVORITE_REQUEST = 'DELETE_FAVORITE_REQUEST';
export const DELETE_FAVORITE_SUCCESS = 'DELETE_FAVORITE_SUCCESS';
export const DELETE_FAVORITE_FAILURE = 'DELETE_FAVORITE_FAILURE';
export const REMOVE_ADD_FAVORITE_FEEDBACK = 'REMOVE_ADD_FAVORITE_FEEDBACK';

export const GET_RELATIVE_REQUEST = 'GET_RELATIVE_REQUEST';
export const GET_RELATIVE_SUCCESS = 'GET_RELATIVE_SUCCESS';
export const GET_RELATIVE_FAILURE = 'GET_RELATIVE_FAILURE';

export const DELETE_RELATIVE_REQUEST = 'DELETE_RELATIVE_REQUEST';
export const DELETE_RELATIVE_SUCCESS = 'DELETE_RELATIVE_SUCCESS';
export const DELETE_RELATIVE_FAILURE = 'DELETE_RELATIVE_FAILURE';

export const ADD_RELATIVE_REQUEST = 'ADD_RELATIVE_REQUEST';
export const ADD_RELATIVE_SUCCESS = 'ADD_RELATIVE_SUCCESS';
export const ADD_RELATIVE_FAILURE = 'ADD_RELATIVE_FAILURE';

export const UPDATE_RELATIVE_REQUEST = 'UPDATE_RELATIVE_REQUEST';
export const UPDATE_RELATIVE_SUCCESS = 'UPDATE_RELATIVE_SUCCESS';
export const UPDATE_RELATIVE_FAILURE = 'UPDATE_RELATIVE_FAILURE';

export const START_UPDATE_RELATIVE_REQUEST = 'START_UPDATE_RELATIVE_REQUEST';

export const ADD_DOCUMENT_REQUEST = 'ADD_DOCUMENT_REQUEST';
export const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS';
export const ADD_DOCUMENT_FAILURE = 'ADD_DOCUMENT_FAILURE';

export const GET_DOCUMENT_REQUEST = 'GET_DOCUMENT_REQUEST';
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';
export const GET_DOCUMENT_FAILURE = 'GET_DOCUMENT_FAILURE';

export const REMOVE_DOCUMENT_REQUEST = 'REMOVE_DOCUMENT_REQUEST';
export const REMOVE_DOCUMENT_SUCCESS = 'REMOVE_DOCUMENT_SUCCESS';
export const REMOVE_DOCUMENT_FAILURE = 'REMOVE_DOCUMENT_FAILURE';

export const AUTOCOMPLETION_DOCUMENT_REQUEST =
    'AUTOCOMPLETION_DOCUMENT_REQUEST';
export const AUTOCOMPLETION_DOCUMENT_SUCCESS =
    'AUTOCOMPLETION_DOCUMENT_SUCCESS';
export const AUTOCOMPLETION_DOCUMENT_FAILURE =
    'AUTOCOMPLETION_DOCUMENT_FAILURE';

export const GET_RESOURCE_BY_SEARCH_REQUEST = 'GET_RESOURCE_BY_SEARCH_REQUEST';
export const GET_RESOURCE_BY_SEARCH_SUCCESS = 'GET_RESOURCE_BY_SEARCH_SUCCESS';
export const GET_RESOURCE_BY_SEARCH_FAILURE = 'GET_RESOURCE_BY_SEARCH_FAILURE';

export const SET_SEARCH_PARAMETER_REQUEST = 'SET_SEARCH_PARAMETER_REQUEST';
