import React, { FC } from 'react';

import resources from '../../../utils/resources';
import useScript from '../../../utils/hooks/useScript';

const Process: FC<unknown> = () => {
    /*useScript('/static/js/jquery.js');
    useScript('/static/js/jquery-ui.js');
    useScript('https://code.jquery.com/jquery-3.6.0.slim.min.js');
    useScript('/static/js/bootstrap.min.js');
    useScript('/static/js/wow.js');
    useScript('/static/js/popper.min.js');
    useScript('/static/js/owl.js');
    useScript('/static/js/validation.js');
    useScript('/static/js/jquery.fancybox.js');
    useScript('/static/js/appear.js');
    useScript('/static/js/scrollbar.js');
    useScript('/static/js/tilt.jquery.js');
    useScript('/static/js/jquery.paroller.min.js');
    useScript('/static/js/product-filter.js');
    useScript('/static/js/valideFrom.js');
    useScript('/static/js/gmaps.js');
    useScript('/static/js/jquery.nice-select.min.js');
    useScript('/static/js/map-helper.js');
    useScript('/static/js/script.js');*/
    return (
        <section className="process-style-two bg-color-3 centred">
            <div className="pattern-layer">
                <div
                    className="pattern-1"
                    style={{
                        backgroundImage: `url(${resources['ic-shap-39']})`,
                    }}
                />
                <div
                    className="pattern-2"
                    style={{
                        backgroundImage: `url(${resources['ic-shap-40']})`,
                    }}
                />
                <div
                    className="pattern-3"
                    style={{
                        backgroundImage: `url(${resources['ic-shap-41']})`,
                    }}
                />
                <div
                    className="pattern-4"
                    style={{
                        backgroundImage: `url(${resources['ic-shap-42']})`,
                    }}
                />
            </div>
            <div className="auto-container">
                <div className="sec-title centred">
                    <p>Processus</p>
                    <h2>Processus de rendez-vous</h2>
                </div>
                <div className="inner-content">
                    <div
                        className="arrow"
                        style={{
                            backgroundImage: `url(${resources['ic-arrow-1']})`,
                        }}
                    />
                    <div className="row clearfix">
                        <div className="col-lg-4 col-md-6 col-sm-12 processing-block">
                            <div className="processing-block-two">
                                <div className="inner-box">
                                    <figure className="icon-box">
                                        <img
                                            src={resources['ic-icon-9']}
                                            alt=""
                                        />
                                    </figure>
                                    <h3>
                                        Rechercher le meilleur professionnel en
                                        ligne
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 processing-block">
                            <div className="processing-block-two">
                                <div className="inner-box">
                                    <figure className="icon-box">
                                        <img
                                            src={resources['ic-icon-10']}
                                            alt=""
                                        />
                                    </figure>
                                    <h3>Voir le profil du professionnel</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 processing-block">
                            <div className="processing-block-two">
                                <div className="inner-box">
                                    <figure className="icon-box">
                                        <img
                                            src={resources['ic-icon-11']}
                                            alt=""
                                        />
                                    </figure>
                                    <h3>
                                        Obtenez un rendez-vous instantané chez
                                        le médecin
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Process;
