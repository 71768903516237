import React, { FC } from 'react';
import resources from '../../utils/resources';
import useScript from '../../utils/hooks/useScript';
import BackgroundElement from '../BackgroundElement';
import Footer from '../Footer';

const About: FC<unknown> = () => {
    useScript('/static/js/jquery.js');
    useScript('/static/js/jquery-ui.js');
    useScript('https://code.jquery.com/jquery-3.6.0.slim.min.js');
    useScript('/static/js/bootstrap.min.js');
    useScript('/static/js/wow.js');
    useScript('/static/js/popper.min.js');
    useScript('/static/js/owl.js');
    useScript('/static/js/validation.js');
    useScript('/static/js/jquery.fancybox.js');
    useScript('/static/js/appear.js');
    useScript('/static/js/scrollbar.js');
    useScript('/static/js/tilt.jquery.js');
    useScript('/static/js/jquery.paroller.min.js');
    useScript('/static/js/product-filter.js');
    useScript('/static/js/valideFrom.js');
    useScript('/static/js/gmaps.js');
    useScript('/static/js/jquery.nice-select.min.js');
    useScript('/static/js/map-helper.js');
    useScript('/static/js/script.js');
    return (
        <>
            <BackgroundElement
                title="À propos de"
                linkTitle="Acceuil"
                subTitle="À propos de"
                linkPath="/"
            />
            <section className="about-style-two">
                <div className="auto-container">
                    <div className="row align-items-center clearfix">
                        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div className="content_block_1">
                                <div className="content-box mr-50">
                                    <div className="sec-title">
                                        <p>À PROPOS DE LOCTORE</p>
                                        <h2>
                                            {
                                                "Loctoré l' application de E-Santé"
                                            }
                                        </h2>
                                    </div>
                                    <div className="text">
                                        <p>
                                            {
                                                'Loctoré est une application e-santé avec comme aura les termes de soins, de vie et de santé. Ce projet a été pensé afin de rendre accessible l’accès aux soins à tous et de pallier aux déserts médicaux en Afrique. Il est initié par 4 partenaires qui ont décidé de créer une Start-up afin d’accompagner les professionnels de santé dans leur travail quotidien et les patients grâce au digital.'
                                            }
                                        </p>
                                    </div>
                                    <ul className="list-style-one clearfix">
                                        <li>Gestion des consultations</li>
                                        <li>Communication en temps réel</li>
                                        <li>Téléconsultation</li>
                                        <li>Dossier Médical Partagé</li>
                                        <li>Téléexpertise</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                            <div className="image_block_3">
                                <div className="image-box">
                                    <div className="pattern">
                                        <div
                                            className="pattern-1"
                                            style={{
                                                backgroundImage: `url(${resources['ic-shap-49']})`,
                                            }}
                                        />
                                        <div
                                            className="pattern-2"
                                            style={{
                                                backgroundImage: `url(${resources['ic-shap-50']})`,
                                            }}
                                        />
                                        <div className="pattern-3" />
                                    </div>
                                    <figure className="image image-1 paroller">
                                        <img
                                            src={resources['ic-about-4']}
                                            alt=""
                                        />
                                    </figure>
                                    <figure className="image image-2 paroller-2">
                                        <img
                                            src={resources['ic-about-3']}
                                            alt=""
                                        />
                                    </figure>
                                    <div className="image-content">
                                        <figure className="icon-box">
                                            <img
                                                src={resources['ic-icon-8']}
                                                alt=""
                                            />
                                        </figure>
                                        <span>Rendez-vous avec</span>
                                        <h4>Spécialiste</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default About;
