/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC } from 'react';
import { IUserData, IFavorite, IFavoriteData } from 'Models';

import BackgroundElement from '../../components/BackgroundElement';
import ProfileInfoSidbar from '../../components/ProfileInfoSidbar';
import FavouriteDoctorsItem from '../../components/FavouriteDoctorsItem';

type Props = {
    userData: IUserData;
    favorite: IFavorite | [];
    handleLogout(): void;
    handleRemoveFavorite(id: string): void;
};
const FavouriteDoctors: FC<Props> = ({
    userData,
    favorite,
    handleLogout,
    handleRemoveFavorite,
}) => {
    return (
        <>
            <BackgroundElement
                title="Mes favoris"
                linkTitle="Tableau de bord du patient"
                subTitle="Mes favoris"
                linkPath="/patient-dashboard"
            />

            <section className="doctors-dashboard bg-color-3">
                <ProfileInfoSidbar
                    userData={userData}
                    handleLogout={handleLogout}
                />

                <div className="right-panel">
                    <div className="content-container">
                        <div className="outer-container">
                            <div className="favourite-doctors">
                                <div className="title-box">
                                    <h3>Liste de favoris</h3>
                                </div>
                                {typeof _.get(favorite, 'data') ===
                                    'undefined' ||
                                _.isEqual(_.get(favorite, 'data').length, 0) ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                        {
                                            "Vous n'avez pas encore de médecin dans la liste de vos favoris !"
                                        }
                                    </div>
                                ) : (
                                    <div className="doctors-list">
                                        <div className="row clearfix">
                                            {_.get(favorite, 'data').map(
                                                (
                                                    item: IFavoriteData,
                                                    _index: any,
                                                ) => {
                                                    return (
                                                        <FavouriteDoctorsItem
                                                            favorite={item}
                                                            handleRemoveFavorite={
                                                                handleRemoveFavorite
                                                            }
                                                        />
                                                    );
                                                },
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FavouriteDoctors;
