/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC } from 'react';
import { IFavoriteData } from 'Models';
import { Link } from 'react-router-dom';

type Props = {
    favorite: IFavoriteData;
    handleRemoveFavorite(id: string): void;
};
const FavouriteDoctorsItem: FC<Props> = ({
    favorite,
    handleRemoveFavorite,
}) => {
    return (
        <div className="col-xl-3 col-lg-6 col-md-12 doctors-block">
            <div className="team-block-three">
                <div className="inner-box">
                    <figure className="image-box">
                        <img
                            src={favorite.attributes.doctor.photo.medium_url}
                            alt=""
                        />
                        <a href="#/" role="button">
                            {_.isEqual(favorite.attributes.favorited, true) ? (
                                <i
                                    onClick={() =>
                                        handleRemoveFavorite(favorite.id)
                                    }
                                    className="fa fa-heart"
                                    aria-hidden="true"></i>
                            ) : (
                                <i className="far fa-heart"></i>
                            )}
                        </a>
                    </figure>
                    <div className="lower-content">
                        <ul className="name-box clearfix">
                            <li className="name">
                                <h3>
                                    <Link
                                        to={`/doctors-details/${favorite.id}`}>
                                        <span>{favorite.attributes.name}</span>
                                    </Link>
                                </h3>
                            </li>
                            <li>
                                <i className="icon-Trust-1"></i>
                            </li>
                            <li>
                                <i className="icon-Trust-2"></i>
                            </li>
                        </ul>
                        <span className="designation">
                            {favorite.attributes.speciality}
                        </span>

                        <div className="lower-box clearfix">
                            <span className="text">
                                <Link to={`/doctors-details/${favorite.id}`}>
                                    <span> Reserve maintenant</span>
                                </Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FavouriteDoctorsItem;
