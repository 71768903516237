import React, { FC } from 'react';

import { converteDateToTime } from '../../utils/helpers';

type Props = {
    id: string;
    startsAt: Date;
    handleClick: any;
};
const TimeItem: FC<Props> = ({ id, startsAt, handleClick }) => {
    return (
        <div
            key={id.toString()}
            tabIndex={0}
            role="button"
            onClick={handleClick}
            onKeyDown={handleClick}>
            {converteDateToTime(startsAt)}
        </div>
    );
};

export default TimeItem;
