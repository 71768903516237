import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
    RouteProps,
    RouteComponentProps,
} from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './store';
import RegisterScreen from './screens/Register';
import LoginScreen from './screens/Login';
import ForgotPassowrdScreen from './screens/ForgotPassowrd';
import AllSpecilityScreen from './screens/AllSpeciality';
import PatientDashboardScreen from './screens/PatientDashboard';
import PatientProfileScreen from './screens/PatientProfile';
import DoctorsScreen from './screens/Doctors';
import DoctorsDetailsScreen from './screens/DoctorsDetails';
import SummaryAppointementScreen from './screens/SummaryAppointement';
import AppointmentDetailsScreen from './screens/AppointementDetails';
import FavouriteDoctorsScreen from './screens/FavouriteDoctors';
import AuthService from './services/auth-service';
import RelativesScreen from './screens/Relatives';
import RelativeFormScreen from './screens/RelativeForm';
import ResetPasswordScreen from './screens/ResetPassword';
import DocumentsScreen from './screens/Document';
import DocumentFormScreen from './screens/DocumentForm';
import ContactScreen from './screens/Contact';
import AboutComponent from './components/About';
import SeachDoctorScreen from './screens/Search';
import HowItWorksComponent from './components/HowItWorks';
import { version } from '../package.json';

import './index.css';
import Main from './screens/Main';
import reportWebVitals from './reportWebVitals';

interface IPrivateRouteProps extends RouteProps {
    component:
        | React.ComponentType<RouteComponentProps<any>>
        | React.ComponentType<any>;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({
    component: Component,
    ...rest
}) => {
    const isSignIn = AuthService.isAuthenticated();
    return (
        <Route
            {...rest}
            render={(props) =>
                isSignIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Switch>
                <Route exact path="/login" component={LoginScreen} />
                <Route exact path="/register" component={RegisterScreen} />
                <Route exact path="/doctors/:id" component={DoctorsScreen} />
                <Route
                    exact
                    path="/all-speciality"
                    component={AllSpecilityScreen}
                />
                <Route
                    exact
                    path="/forgot-passowrd"
                    component={ForgotPassowrdScreen}
                />
                <Route
                    exact
                    path="/doctors-details/:id"
                    component={DoctorsDetailsScreen}
                />
                <Route
                    exact
                    path="/appointement"
                    component={SummaryAppointementScreen}
                />
                <PrivateRoute
                    exact
                    path="/patient-dashboard"
                    component={PatientDashboardScreen}
                />

                <Route
                    exact
                    path="/patient-profile"
                    component={PatientProfileScreen}
                />
                <Route
                    exact
                    path="/appointement-details/:id"
                    component={AppointmentDetailsScreen}
                />

                <Route
                    exact
                    path="/favourite-doctors"
                    component={FavouriteDoctorsScreen}
                />
                <Route
                    exact
                    path="/add-relatives"
                    component={RelativeFormScreen}
                />
                <Route
                    exact
                    path="/reset-password"
                    component={ResetPasswordScreen}
                />
                <Route exact path="/relatives" component={RelativesScreen} />
                <Route exact path="/documents" component={DocumentsScreen} />
                <Route
                    exact
                    path="/add-documents"
                    component={DocumentFormScreen}
                />
                <Route exact path="/contact" component={ContactScreen} />

                <Route exact path="/about" component={AboutComponent} />

                <Route exact path="/search" component={SeachDoctorScreen} />

                <Route
                    exact
                    path="/how-it-works"
                    component={HowItWorksComponent}
                />

                <Route path="/" component={Main} />
            </Switch>
        </Router>
    </Provider>,
    document.getElementById('root'),
);
console.log('Version : ', version);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
/*if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
    const siteWidth = 380;

    const initialScale = window.screen.availWidth / 980;

    (document as any)
        .querySelector('meta[name="viewport"]')
        .setAttribute(
            'content',
            `width=${siteWidth}, initial-scale=${initialScale}`,
        );
}*/
