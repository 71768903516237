import { combineReducers } from 'redux';

import authReducer from '../_reducers/auth/auth.reducer';
import specialityReducer from '../_reducers/speciality/speciality.reducer';
import resourcesReducer from '../_reducers/resources/resources.reducer';
import interventionsReducer from '../_reducers/interventions/interventions.reducer';
import availabilitiesReducer from '../_reducers/availabilities/availabilities.reducer';
import summaryReducer from '../_reducers/summary/summary.reducer';
import userProfilReducer from '../_reducers/profil/profil.reducer';
import appointementReducer from '../_reducers/appointement/appointement.reducer';
import favoriteReducer from '../_reducers/favorite/favorite.reducer';
import relativesReducer from '../_reducers/relatives/relatives.reducer';
import documentsReducer from '../_reducers/documents/documents.reducer';

const rootReducer = combineReducers({
    authReducer,
    specialityReducer,
    resourcesReducer,
    interventionsReducer,
    availabilitiesReducer,
    summaryReducer,
    userProfilReducer,
    appointementReducer,
    favoriteReducer,
    relativesReducer,
    documentsReducer,
});

export default rootReducer;
