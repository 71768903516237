import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { RootAction, RootState, Services } from 'Types';
import rootEpic from './root-epic';
import rootReducer from './root-reducer';
import services from '../services';

const getUserId = () => {
    try {
        return sessionStorage.getItem('currentClient');
    } catch (err) {
        return null;
    }
};

const loadState = () => {
    try {
        const userId = getUserId();
        let serializedState;
        if (userId) {
            serializedState = sessionStorage.getItem(userId);
        } else {
            serializedState = localStorage.getItem('reduxState');
        }
        if (!serializedState) {
            return {};
        }
        return JSON.parse(serializedState);
    } catch (err) {
        console.log('Unable to find state');
        return {};
    }
};

const saveState = (state: any) => {
    try {
        const userId = getUserId();
        const serializedState = JSON.stringify(state);
        if (userId) {
            sessionStorage.setItem(userId, serializedState);
        } else {
            localStorage.setItem('reduxState', serializedState);
        }
    } catch (err) {
        console.log('Unable to set state');
    }
};

const epicMiddleware = createEpicMiddleware<
    RootAction,
    RootAction,
    RootState,
    Services
>({
    dependencies: services,
});

const composeEnhancers =
    (process.env.NODE_ENV !== 'production' &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

const persistedState = loadState();

const middlewares = [epicMiddleware];

const reducer = (state: any, action: RootAction) => {
    if (action.type === 'REDUX_RESET_STATE') {
        delete state.authReducer;
        delete state.specialityReducer;
    }

    return rootReducer(state, action);
};

const store = createStore(
    reducer,
    persistedState,
    // initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
);

store.subscribe(() => {
    saveState(store.getState());
});

epicMiddleware.run(rootEpic);

export default store;
