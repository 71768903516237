/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { FC } from 'react';
import { IData, IAvailability } from 'Models';
import { InterventionSummary } from '../../_actions/summary/summary.actions';
import { converteDateToTime, converteToFormatLL } from '../../utils/helpers';

type Props = {
    doctor: IData;
    intervention: InterventionSummary;
    availability: IAvailability;
};

const SummaryCard: FC<Props> = ({ doctor, intervention, availability }) => {
    return (
        <div className="col-lg-4 col-md-12 col-sm-12 right-column">
            <div className="booking-information">
                <div className="title-box">
                    <h3>Résumé</h3>
                </div>
                <div className="inner-box">
                    <div className="single-box">
                        <ul className="clearfix">
                            <li>
                                Date
                                <span>
                                    {converteToFormatLL(availability.starts_at)}
                                </span>
                            </li>
                            <li>
                                Heure
                                <span>
                                    {converteDateToTime(availability.starts_at)}
                                </span>
                            </li>
                            <li>
                                Médecin
                                <span>{doctor.attributes.name}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="single-box">
                        <ul className="clearfix">
                            <li>
                                {intervention.name}
                                <span>{`${intervention.price} FCFA`}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SummaryCard;
