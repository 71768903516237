/* eslint-disable import/no-extraneous-dependencies */
import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import {
    createAsyncAction,
    isActionOf,
    ActionType,
    createStandardAction,
} from 'typesafe-actions';
import { RootAction, RootState, Services } from 'Types';
import { IFavorite } from 'Models';

import {
    GET_FAVORITE_REQUEST,
    GET_FAVORITE_SUCCESS,
    GET_FAVORITE_FAILURE,
    ADD_FAVORITE_REQUEST,
    ADD_FAVORITE_SUCCESS,
    ADD_FAVORITE_FAILURE,
    DELETE_FAVORITE_REQUEST,
    DELETE_FAVORITE_SUCCESS,
    DELETE_FAVORITE_FAILURE,
    REMOVE_ADD_FAVORITE_FEEDBACK,
} from '../actionTypes';

const removeAddFavoritFeedback = createStandardAction(
    REMOVE_ADD_FAVORITE_FEEDBACK,
)<boolean>();

const getFavoriteAsync = createAsyncAction(
    GET_FAVORITE_REQUEST,
    GET_FAVORITE_SUCCESS,
    GET_FAVORITE_FAILURE,
)<any, IFavorite, any>();

const addFavoriteAsync = createAsyncAction(
    ADD_FAVORITE_REQUEST,
    ADD_FAVORITE_SUCCESS,
    ADD_FAVORITE_FAILURE,
)<string, any, any>();

const deleteFavoriteAsync = createAsyncAction(
    DELETE_FAVORITE_REQUEST,
    DELETE_FAVORITE_SUCCESS,
    DELETE_FAVORITE_FAILURE,
)<string, any, any>();

export type FavoriteAction =
    | ActionType<typeof removeAddFavoritFeedback>
    | ActionType<typeof getFavoriteAsync>
    | ActionType<typeof addFavoriteAsync>
    | ActionType<typeof deleteFavoriteAsync>;

const preparePayload = (resourcesId: string) => {
    return {
        resourcesId,
    };
};
const mapGetFavorite = (action: RootAction, { apiRequest }: Services) => {
    return apiRequest<IFavorite>({
        path: '/resources/favorited',
        method: 'get',
        body: '',
    }).pipe(
        mergeMap((response: IFavorite) => {
            if (response) {
                return of(getFavoriteAsync.success(response));
            }
            return of(getFavoriteAsync.failure(response));
        }),
        catchError((error) => {
            return of(getFavoriteAsync.failure(error));
        }),
    );
};

const mapDeleteFavorite = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayload(action.payload);
    return apiRequest<any>({
        path: `${'/resources/'}${payload.resourcesId}/unfavorite`,
        method: 'delete',
        body: '',
    }).pipe(
        mergeMap((response: any) => {
            if (response) {
                return of(deleteFavoriteAsync.success(response));
            }
            return of(deleteFavoriteAsync.failure(response));
        }),
        catchError((error) => {
            return of(deleteFavoriteAsync.failure(error));
        }),
    );
};

const mapAddFavorite = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayload(action.payload);
    return apiRequest<any>({
        path: `${'/resources/'}${payload.resourcesId}/favorite`,
        method: 'post',
        body: '',
    }).pipe(
        mergeMap((response: any) => {
            if (response) {
                return of(addFavoriteAsync.success(response));
            }
            return of(addFavoriteAsync.failure(response));
        }),
        catchError((error) => {
            return of(addFavoriteAsync.failure(error));
        }),
    );
};

const getFavoriteEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(getFavoriteAsync.request)),
        mergeMap((action) => mapGetFavorite(action, dependency)),
    );

const addFavoriteEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(addFavoriteAsync.request)),
        mergeMap((action) => mapAddFavorite(action, dependency)),
    );

const deleteFavoriteEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(deleteFavoriteAsync.request)),
        mergeMap((action) => mapDeleteFavorite(action, dependency)),
    );

export {
    getFavoriteEpic,
    mapGetFavorite,
    getFavoriteAsync,
    addFavoriteEpic,
    addFavoriteAsync,
    deleteFavoriteEpic,
    deleteFavoriteAsync,
    removeAddFavoritFeedback,
};
