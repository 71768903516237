import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { IRelative, IRelativeData } from 'Models';

import * as relativeAction from '../../_actions/relatives/relatives.actions';
import * as authActions from '../../_actions/auth/auth.actions';

export type RelativeState = Readonly<{
    loading: boolean;
    relativeData: IRelative | [];
    error: string;
    hasBeenAdded: boolean;
    hasBeenDeleted: boolean;
    hasBeenUpdated: boolean;
    relative?: IRelativeData;
}>;

export const initialState: RelativeState = {
    loading: false,
    relativeData: [],
    error: '',
    hasBeenAdded: false,
    hasBeenDeleted: false,
    hasBeenUpdated: false,
    relative: undefined,
};

type Actions = relativeAction.RelativeAction | authActions.AuthAction;

const relativesReducer: Reducer<RelativeState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(relativeAction.updateRelativeAsync.request):
        case getType(relativeAction.deleteRelativeAsync.request):
        case getType(relativeAction.addRelativeAsync.request):
        case getType(relativeAction.getRelativeAsync.request): {
            return {
                ...state,
                loading: true,
                error: '',
                hasBeenAdded: false,
                hasBeenDeleted: false,
                hasBeenUpdated: false,
                relative: undefined,
            };
        }

        case getType(relativeAction.getRelativeAsync.success): {
            return {
                ...state,
                loading: false,
                relativeData: action.payload,
                hasBeenAdded: false,
                hasBeenDeleted: false,
                hasBeenUpdated: false,
                relative: undefined,
            };
        }

        case getType(relativeAction.addRelativeAsync.success): {
            return {
                ...state,
                loading: false,
                hasBeenAdded: true,
                hasBeenDeleted: false,
                hasBeenUpdated: false,
                relative: undefined,
            };
        }

        case getType(relativeAction.deleteRelativeAsync.success): {
            return {
                ...state,
                loading: false,
                hasBeenAdded: false,
                hasBeenDeleted: true,
                hasBeenUpdated: false,
                relative: undefined,
            };
        }

        case getType(relativeAction.updateRelativeAsync.success): {
            return {
                ...state,
                loading: false,
                hasBeenAdded: false,
                hasBeenDeleted: false,
                hasBeenUpdated: true,
                relative: undefined,
            };
        }

        case getType(relativeAction.startedUpdate): {
            return {
                ...state,
                loading: false,
                relative: action.payload,
            };
        }

        case getType(relativeAction.getRelativeAsync.failure): {
            return {
                ...state,
                loading: false,
                error: action.payload,
                relativeData: [],
                hasBeenAdded: false,
                hasBeenDeleted: false,
                hasBeenUpdated: false,
                relative: undefined,
            };
        }

        case getType(relativeAction.updateRelativeAsync.failure):
        case getType(relativeAction.deleteRelativeAsync.failure):
        case getType(relativeAction.addRelativeAsync.failure): {
            return {
                ...state,
                loading: false,
                error: action.payload,
                hasBeenAdded: false,
                hasBeenDeleted: false,
                hasBeenUpdated: false,
                relative: undefined,
            };
        }
        case getType(authActions.logoutAsync.success): {
            return {
                ...state,
                loading: false,
                relativeData: [],
                error: '',
                hasBeenAdded: false,
                hasBeenDeleted: false,
                hasBeenUpdated: false,
                relative: undefined,
            };
        }

        default:
            return { ...state };
    }
};
export default relativesReducer;
