import React, { FC, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

type Props = {
    pdf: any;
};
const AllPages: FC<Props> = ({ pdf }) => {
    const [numPages, setNumPages] = useState<number>(7);

    const onDocumentLoadSuccess = () => {
        setNumPages(7);
    };

    return (
        <Document
            file={pdf}
            options={{ workerSrc: '/pdf.worker.js' }}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.error}
        >
            {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
        </Document>
    );
};

export default AllPages;
