/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import { throwError, Observable, of, from } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, mergeMap } from 'rxjs/operators';
import isArray from 'lodash/isArray';

import config from '../utils/config';

const apiRequest = <T>({ path, method, body }: any): Observable<T> => {
    const url = config.URL;
    const settings = {
        method,
        url: `${url}${path}`,
        headers:
            _.isEqual(method, 'post') && _.isEqual(path, '/documents')
                ? { 'Content-Type': 'multipart/form-data' }
                : { 'Content-Type': 'application/json' },
        responseType: 'json',
        body,
    } as any;

    const headers = localStorage.getItem('header');

    if (
        (path === '/me' ||
            path === '/appointments/upcoming' ||
            path ||
            path === '/resources/favorited') &&
        headers != null
    ) {
        const objHeaders = JSON.parse(headers);
        settings.headers['access-token'] = objHeaders[config.ACCESS_TOKEN];
        settings.headers.client = objHeaders[config.CLIENT];
        settings.headers.expiry = objHeaders[config.EXPIRY];
        settings.headers['token-type'] = objHeaders[config.TOKEN_TYPE];
        settings.headers.uid = objHeaders[config.UID];
    }

    console.log(`FO Data: POST ${path} ====> `, body);

    const promise = ajax(settings).pipe(
        catchError(({ status, response }) => {
            const error: { response: any; code: string; errors: any[] } = {
                response,
                code: String(status),
                errors: [],
            };
            if (status >= 400 && status < 500) {
                error.errors = isArray(response) ? response : [response];
            } else if (status >= 500) {
                error.errors = isArray(response) ? response : [response];
            }
            console.log(`Error from API ${path} ====> `, response);
            return throwError(error);
        }),
        mergeMap((response) => {
            if (_.isEqual(response.status, 200)) {
                if (
                    path === '/auth/sign_in' &&
                    typeof response.xhr !== 'undefined'
                ) {
                    const header = {
                        'access-token':
                            response.xhr.getResponseHeader('access-token'),
                        client: response.xhr.getResponseHeader('client'),
                        expiry: response.xhr.getResponseHeader('expiry'),
                        'token-type':
                            response.xhr.getResponseHeader('token-type'),
                        uid: response.xhr.getResponseHeader('uid'),
                    };
                    localStorage.setItem('header', JSON.stringify(header));
                }
                console.log(`BO Response: POST ${path} ====> `, response);
                return of(response.response);
            }
            return of([]);
        }),
    );
    return from(promise);
};

export default apiRequest;
