import React, { FC } from 'react';
import { InputEvent } from 'Types';
import { IUserData } from 'Models';
import { Link } from 'react-router-dom';

import ProfileInfoSidbar from '../../components/ProfileInfoSidbar';
import Input from '../../components/Input';
import Form from '../../components/Form';
import Button from '../../components/Button';
import BackgroundElement from '../../components/BackgroundElement';

type Props = {
    fields: any;
    errors: any;
    userData: IUserData;
    handleLogout(): void;
    handleOnClick(e: React.FormEvent<HTMLFormElement>): void;
    onChange(e: InputEvent): void;
};

const ResetPassword: FC<Props> = ({
    fields,
    errors,
    onChange,
    userData,
    handleOnClick,
    handleLogout,
}) => {
    return (
        <>
            <BackgroundElement
                title="Changer le mot de passe"
                linkTitle="Tableau de bord du patient"
                subTitle="Changer le mot de passe"
                linkPath="/patient-dashboard"
            />
            <section className="doctors-dashboard bg-color-3">
                <ProfileInfoSidbar
                    userData={userData}
                    handleLogout={handleLogout}
                />

                <div className="right-panel">
                    <div className="content-container">
                        <div className="outer-container">
                            <div className="add-listing change-password">
                                <div className="single-box">
                                    <div className="title-box">
                                        <h3>Changer le mot de passe</h3>
                                        <span>
                                            Après la validation de votre nouveau
                                            mot de passe, vous allez être
                                            deconnecté(e) de votre compte !
                                        </span>
                                    </div>
                                    <div className="inner-box">
                                        <Form autoComplete="off" noValidate>
                                            <div className="row clearfix">
                                                <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                                    <Input
                                                        type="password"
                                                        name="oldPassword"
                                                        label="Ancien mot de passe"
                                                        placeholder=""
                                                        required
                                                        htmlFor="validationCustom01"
                                                        onChange={onChange}
                                                        value={
                                                            fields.oldPassword
                                                        }
                                                        error={
                                                            errors.oldPassword
                                                        }
                                                        className=""
                                                        disabled={false}
                                                        autocomplete=""
                                                        onClick={() => {}}
                                                        checked={false}
                                                        id=""
                                                    />
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12 form-group"></div>
                                                <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                                    <Input
                                                        type="password"
                                                        name="newPassword"
                                                        label="Nouveau mot de passe"
                                                        placeholder=""
                                                        required
                                                        htmlFor="validationCustom01"
                                                        onChange={onChange}
                                                        value={
                                                            fields.newPassword
                                                        }
                                                        error={
                                                            errors.newPassword
                                                        }
                                                        className=""
                                                        disabled={false}
                                                        autocomplete=""
                                                        onClick={() => {}}
                                                        checked={false}
                                                        id=""
                                                    />
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12 form-group"></div>
                                                <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                                    <Input
                                                        type="password"
                                                        name="confNewPassword"
                                                        label="Confirmez le mot de
                                                        passe"
                                                        placeholder=""
                                                        required
                                                        htmlFor="validationCustom01"
                                                        onChange={onChange}
                                                        value={
                                                            fields.confNewPassword
                                                        }
                                                        error={
                                                            errors.confNewPassword
                                                        }
                                                        className=""
                                                        disabled={false}
                                                        autocomplete=""
                                                        onClick={() => {}}
                                                        checked={false}
                                                        id=""
                                                    />
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12 form-group"></div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                                <div className="btn-box">
                                    <Button
                                        className="theme-btn-one"
                                        onClick={handleOnClick}
                                    >
                                        Modifier
                                        <i className="icon-Arrow-Right" />
                                    </Button>

                                    <Link
                                        to="/patient-dashboard"
                                        className="cancel-btn"
                                        onClick={() => {}}
                                    >
                                        Annuler
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ResetPassword;
