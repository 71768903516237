/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';

import resources from '../../utils/resources';

type Props = {
    id: string;
    src: string;
    label: string;
    handleOnClick(id: string): void;
};

const SpecialityItem: FC<Props> = ({ label, src, id, handleOnClick }) => {
    return (
        <div className="col-lg-3 col-md-6 col-sm-12 category-block">
            <div
                className="category-block-one wow fadeInUp animated animated"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
            >
                <div className="inner-box">
                    <div className="pattern">
                        <div
                            className="pattern-1"
                            style={{
                                backgroundImage: `url(${resources['ic-shap-45']})`,
                            }}
                        />
                        <div
                            className="pattern-2"
                            style={{
                                backgroundImage: `url(${resources['ic-shap-46']})`,
                            }}
                        />
                    </div>
                    <figure className="icon-box">
                        <img src={`url(${src})`} alt="" />
                    </figure>
                    <h3>
                        <span>{label}</span>
                    </h3>
                    <div className="link">
                        <a href="">
                            <i className="icon-Arrow-Right" />
                        </a>
                    </div>
                    <div className="btn-box">
                        <span
                            className="theme-btn-one"
                            role="button"
                            onClick={() => handleOnClick(id)}
                        >
                            Afficher la liste
                            <i className="icon-Arrow-Right" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpecialityItem;
