import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { IResources, IResourcesDetails, IAutocompletionResource } from 'Models';
import * as resourcesAction from '../../_actions/resources/resources.actions';
import { initialDetail } from '../../utils/initialState';
import * as authActions from '../../_actions/auth/auth.actions';

export type ResourcesState = Readonly<{
    loading: boolean;
    data: IResources;
    error: string;
    detail: IResourcesDetails;
    autocompletionLoading: boolean;
    autocompletion: IAutocompletionResource;
    searchData: IResources;
    searchParameter: {
        term: string;
        lat: string;
        lng: string;
        city: string;
    };
}>;

export const initialState: ResourcesState = {
    loading: false,
    autocompletionLoading: false,
    data: {
        data: [],
        links: undefined,
        meta: undefined,
    },
    error: '',
    detail: {
        ...initialDetail,
    },
    autocompletion: {
        specialities: [],
        calendars: [],
        groups: [],
    },
    searchData: {
        data: [],
        links: undefined,
        meta: undefined,
    },
    searchParameter: {
        term: '',
        lat: '',
        lng: '',
        city: '',
    },
};

type Actions = resourcesAction.ResourcesAction | authActions.AuthAction;

const resourcesReducer: Reducer<ResourcesState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(resourcesAction.getResourcesBySearchAsync.request):
        case getType(resourcesAction.getResourcesDetailAsync.request):
        case getType(resourcesAction.getResourcesBySpecialityIdAsync.request): {
            return {
                ...state,
                loading: true,
                error: '',
            };
        }

        case getType(resourcesAction.getAutocompletionResourcesAsync.request): {
            return {
                ...state,
                autocompletionLoading: true,
                error: '',
            };
        }

        case getType(resourcesAction.getResourcesBySpecialityIdAsync.success): {
            return {
                ...state,
                loading: false,
                data: {
                    data: action.payload.data,
                    links: action.payload.links,
                    meta: action.payload.meta,
                },
            };
        }

        case getType(resourcesAction.getResourcesBySearchAsync.success): {
            return {
                ...state,
                loading: false,
                searchData: {
                    data: action.payload.data,
                    links: action.payload.links,
                    meta: action.payload.meta,
                },
            };
        }

        case getType(resourcesAction.getResourcesDetailAsync.success): {
            return {
                ...state,
                loading: false,
                detail: {
                    data: action.payload.data,
                    meta: action.payload.meta,
                },
            };
        }

        case getType(resourcesAction.getAutocompletionResourcesAsync.success): {
            return {
                ...state,
                autocompletionLoading: false,
                autocompletion: action.payload,
            };
        }

        case getType(resourcesAction.getResourcesBySearchAsync.failure):
        case getType(resourcesAction.getResourcesDetailAsync.failure):
        case getType(resourcesAction.getResourcesBySpecialityIdAsync.failure): {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }

        case getType(resourcesAction.getAutocompletionResourcesAsync.failure): {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }

        case getType(resourcesAction.setSearchParameter): {
            return {
                ...state,
                loading: false,
                searchParameter: action.payload,
            };
        }

        case getType(authActions.logoutAsync.success): {
            return {
                ...state,
                loading: false,
                data: {
                    data: [],
                    links: undefined,
                    meta: undefined,
                },
                error: '',
                detail: {
                    ...initialDetail,
                },
            };
        }

        default:
            return { ...state };
    }
};
export default resourcesReducer;
