import React, { useState } from 'react';
import MUSnackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

interface IProps {
    isError?: boolean;
    message: string;
    onClose?: () => void;
}

const Snackbar = ({ isError, message, onClose }: IProps) => {
    const [visible, setVisible] = useState(true);
    const severity = isError ? 'error' : 'success';

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
        setVisible(false);
    };

    return (
        <MUSnackbar
            open={visible}
            autoHideDuration={6000}
            onClose={handleClose}>
            <MuiAlert
                elevation={6}
                variant="filled"
                severity={severity}
                onClose={handleClose}>
                {message}
            </MuiAlert>
        </MUSnackbar>
    );
};

Snackbar.defaultProps = { isError: false, onClose: () => {} };

export default Snackbar;
