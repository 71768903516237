/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import {
    createAsyncAction,
    isActionOf,
    ActionType,
    createStandardAction,
} from 'typesafe-actions';
import { RootAction, RootState, Services } from 'Types';

import { IResources, IResourcesDetails, IAutocompletionResource } from 'Models';

import {
    GET_RESOURCES_BY_SPECIALITY_ID_REQUEST,
    GET_RESOURCES_BY_SPECIALITY_ID_SUCCESS,
    GET_RESOURCES_BY_SPECIALITY_ID_FAILURE,
    GET_DOCTOR_DETAILS_REQUEST,
    GET_DOCTOR_DETAILS_SUCCESS,
    GET_DOCTOR_DETAILS_FAILURE,
    AUTOCOMPLETION_DOCUMENT_REQUEST,
    AUTOCOMPLETION_DOCUMENT_SUCCESS,
    AUTOCOMPLETION_DOCUMENT_FAILURE,
    GET_RESOURCE_BY_SEARCH_REQUEST,
    GET_RESOURCE_BY_SEARCH_SUCCESS,
    GET_RESOURCE_BY_SEARCH_FAILURE,
    SET_SEARCH_PARAMETER_REQUEST,
} from '../actionTypes';

interface IPayload {
    id: string;
}

interface IPayloadResourceBySearch {
    term: string;
    lat: string;
    lng: string;
    city: string;
    page: number;
}
interface IPayloadSearch {
    term: string;
    lat: string;
    lng: string;
    city: string;
}
interface IPayload {
    id: string;
}

interface IPayloadAutocomplettion {
    term: string;
}

const getResourcesBySpecialityIdAsync = createAsyncAction(
    GET_RESOURCES_BY_SPECIALITY_ID_REQUEST,
    GET_RESOURCES_BY_SPECIALITY_ID_SUCCESS,
    GET_RESOURCES_BY_SPECIALITY_ID_FAILURE,
)<IPayload, IResources, any>();

const getResourcesDetailAsync = createAsyncAction(
    GET_DOCTOR_DETAILS_REQUEST,
    GET_DOCTOR_DETAILS_SUCCESS,
    GET_DOCTOR_DETAILS_FAILURE,
)<IPayload, IResourcesDetails, any>();

const getAutocompletionResourcesAsync = createAsyncAction(
    AUTOCOMPLETION_DOCUMENT_REQUEST,
    AUTOCOMPLETION_DOCUMENT_SUCCESS,
    AUTOCOMPLETION_DOCUMENT_FAILURE,
)<IPayloadAutocomplettion, IAutocompletionResource, any>();

const getResourcesBySearchAsync = createAsyncAction(
    GET_RESOURCE_BY_SEARCH_REQUEST,
    GET_RESOURCE_BY_SEARCH_SUCCESS,
    GET_RESOURCE_BY_SEARCH_FAILURE,
)<IPayloadResourceBySearch, IResources, any>();

const setSearchParameter = createStandardAction(
    SET_SEARCH_PARAMETER_REQUEST,
)<IPayloadSearch>();

export type ResourcesAction =
    | ActionType<typeof getAutocompletionResourcesAsync>
    | ActionType<typeof getResourcesDetailAsync>
    | ActionType<typeof getResourcesBySpecialityIdAsync>
    | ActionType<typeof setSearchParameter>
    | ActionType<typeof getResourcesBySearchAsync>;

const preparePayloadResources = ({ id }: IPayload) => {
    return {
        id,
    };
};

const preparePayloadResourcesDetails = ({ id }: IPayload) => {
    return {
        id,
    };
};

const preparePayloadAutocompletion = ({ term }: IPayloadAutocomplettion) => {
    return {
        term,
    };
};

const preparePayloadResourcesBySearch = ({
    city,
    lat,
    lng,
    term,
    page,
}: IPayloadResourceBySearch) => {
    return {
        city,
        lat,
        lng,
        term,
        page,
    };
};

const mapPostResources = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadResources(action.payload);
    return apiRequest<IResources>({
        path: `${'/specialities/'}${payload.id}/resources`,
        method: 'get',
        body: '',
    }).pipe(
        mergeMap((response: IResources) => {
            if (response) {
                return of(getResourcesBySpecialityIdAsync.success(response));
            }
            return of(getResourcesBySpecialityIdAsync.failure(response));
        }),
        catchError((error) => {
            return of(getResourcesBySpecialityIdAsync.failure(error));
        }),
    );
};

const mapPostResourcesBySearch = (
    action: RootAction,
    { apiRequest }: Services,
) => {
    const payload = preparePayloadResourcesBySearch(action.payload);
    let prePath = '';
    if (
        typeof payload.term === 'undefined' &&
        _.isEmpty(payload.lng) &&
        _.isEmpty(payload.lat) &&
        typeof payload.city === 'undefined'
    ) {
        prePath = `${'/resources?term='}${'Infirmier&page='}${payload.page}`;
    } else if (
        typeof payload.term === 'undefined' &&
        _.isEmpty(String(payload.lng)) &&
        _.isEmpty(String(payload.lat)) &&
        typeof payload.city !== 'undefined' &&
        !_.isEqual('AUTOUR DE MOI', payload.city)
    ) {
        prePath = `${'/resources?term='}${'Infirmier'}&location[address]=${
            payload.city
        }&page=${payload.page}`;
    } else if (
        typeof payload.term === 'undefined' &&
        !_.isEmpty(String(payload.lng)) &&
        !_.isEmpty(String(payload.lat)) &&
        !_.isEmpty(payload.city)
    ) {
        prePath = `${'/resources?term='}${'Infirmier'}&location[lat]=${
            payload.lat
        }&location[lng]=${payload.lng}&page=${payload.page}`;
    } else if (
        typeof payload.term !== 'undefined' &&
        _.isEmpty(String(payload.lng)) &&
        _.isEmpty(String(payload.lat)) &&
        _.isEmpty(payload.city)
    ) {
        prePath = `${'/resources?term='}${payload.term}&page=${payload.page}`;
    } else if (
        typeof payload.term !== 'undefined' &&
        _.isEmpty(String(payload.lng)) &&
        _.isEmpty(String(payload.lat)) &&
        !_.isEmpty(payload.city)
    ) {
        prePath = `${'/resources?term='}${payload.term}&location[address]=${
            payload.city
        }&page=${payload.page}`;
    } else if (
        !_.isEmpty(payload.term) &&
        !_.isEmpty(String(payload.lng)) &&
        !_.isEmpty(String(payload.lat)) &&
        !_.isEmpty(payload.city) &&
        _.isEqual('AUTOUR DE MOI', payload.city)
    ) {
        prePath = `${'/resources?term='}${payload.term}&location[lat]=${
            payload.lat
        }&location[lng]=${payload.lng}&page=${payload.page}`;
    }

    return apiRequest<IResources>({
        path: prePath,
        method: 'get',
        body: '',
    }).pipe(
        mergeMap((response: IResources) => {
            if (response) {
                return of(getResourcesBySearchAsync.success(response));
            }
            return of(getResourcesBySearchAsync.failure(response));
        }),
        catchError((error) => {
            return of(getResourcesBySearchAsync.failure(error));
        }),
    );
};

const mapPostResourcesDetails = (
    action: RootAction,
    { apiRequest }: Services,
) => {
    const payload = preparePayloadResourcesDetails(action.payload);
    return apiRequest<IResourcesDetails>({
        path: `${'/resources/'}${payload.id}`,
        method: 'get',
        body: '',
    }).pipe(
        mergeMap((response: IResourcesDetails) => {
            if (response) {
                return of(getResourcesDetailAsync.success(response));
            }
            return of(getResourcesDetailAsync.failure(response));
        }),
        catchError((error) => {
            return of(getResourcesDetailAsync.failure(error));
        }),
    );
};

const mapAutocompletionPostResources = (
    action: RootAction,
    { apiRequest }: Services,
) => {
    const payload = preparePayloadAutocompletion(action.payload);
    return apiRequest<IAutocompletionResource>({
        path: `${'/search?term='}${payload.term}`,
        method: 'get',
        body: '',
    }).pipe(
        mergeMap((response: IAutocompletionResource) => {
            if (response) {
                return of(getAutocompletionResourcesAsync.success(response));
            }
            return of(getAutocompletionResourcesAsync.failure(response));
        }),
        catchError((error) => {
            return of(getAutocompletionResourcesAsync.failure(error));
        }),
    );
};

const resourcesEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(getResourcesBySpecialityIdAsync.request)),
        mergeMap((action) => mapPostResources(action, dependency)),
    );

const resourcesDetailEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(getResourcesDetailAsync.request)),
        mergeMap((action) => mapPostResourcesDetails(action, dependency)),
    );

const autocompletionResourcesEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(getAutocompletionResourcesAsync.request)),
        mergeMap((action) =>
            mapAutocompletionPostResources(action, dependency),
        ),
    );

const resourcesBySearchEpic: Epic<RootAction, RootAction, RootState, Services> =
    (action$, state$, dependency) =>
        action$.pipe(
            filter(isActionOf(getResourcesBySearchAsync.request)),
            mergeMap((action) => mapPostResourcesBySearch(action, dependency)),
        );

export {
    getResourcesBySpecialityIdAsync,
    getResourcesDetailAsync,
    resourcesEpic,
    resourcesDetailEpic,
    getAutocompletionResourcesAsync,
    autocompletionResourcesEpic,
    resourcesBySearchEpic,
    getResourcesBySearchAsync,
    setSearchParameter,
};
