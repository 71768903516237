/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import { ICreateAppointement } from 'Models';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.confirmedAppointment;

type Props = {
    createAppointement: ICreateAppointement;
    hancleCloseDialog(): void;
};

const AppointementSuccessModal: FC<Props> = ({
    hancleCloseDialog,
    createAppointement,
}) => {
    return (
        <>
            <section className="submit-review bg-logout">
                <div className="auto-container">
                    <div className="review-box">
                        <div className="content-box">
                            <div className="title-inner">
                                <h3>{Wording.title}</h3>
                                <p>{Wording.message_1}</p>
                                <p>{Wording.message_2}</p>
                            </div>
                            <div className="content-inner">
                                <div className="clinic-block-one">
                                    <div className="inner-box">
                                        <figure className="image-box">
                                            <img
                                                src={
                                                    createAppointement.data
                                                        .attributes.resource
                                                        .photo.medium_url
                                                }
                                                alt=""
                                            />
                                        </figure>
                                        <div className="content-box">
                                            <ul className="name-box clearfix">
                                                <li className="name">
                                                    <h2>
                                                        {
                                                            createAppointement
                                                                .data.attributes
                                                                .resource.name
                                                        }
                                                    </h2>
                                                </li>
                                                <li>
                                                    <i className="icon-Trust-1" />
                                                </li>
                                                <li>
                                                    <i className="icon-Trust-2" />
                                                </li>
                                            </ul>
                                            <span className="designation">
                                                {
                                                    createAppointement.data
                                                        .attributes.resource
                                                        .speciality
                                                }
                                            </span>
                                            {!_.isEmpty(
                                                createAppointement.included,
                                            ) && (
                                                <span className="designation">
                                                    {
                                                        createAppointement
                                                            .included[0]
                                                            .attributes.name
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-inner">
                                    <div>
                                        <div className="row clearfix">
                                            <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn">
                                                <Link to="/patient-dashboard">
                                                    <Button
                                                        onClick={
                                                            hancleCloseDialog
                                                        }
                                                        className="theme-btn-one"
                                                    >
                                                        Fermer
                                                        <i className="icon-Arrow-Right"></i>
                                                    </Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AppointementSuccessModal;
