import React, { FC, useEffect } from 'react';
import { RootState } from 'Types';
import { useDispatch, useSelector } from 'react-redux';

import FavouriteDoctors from '../../containers/FavouriteDoctors';
import { getUserProfilAsync } from '../../_actions/profil/profil.actions';
import {
    getFavoriteAsync,
    deleteFavoriteAsync,
} from '../../_actions/favourite/favourite.actions';
import withLoader from '../../shared/hoc/withLoader';

const WrappedFavouriteDoctors = withLoader()(FavouriteDoctors);

const FavouriteDoctorsScreen: FC<unknown> = () => {
    const dispatch = useDispatch();

    const { data } = useSelector((state: RootState) => state.userProfilReducer);

    const { loading, favorite } = useSelector(
        (state: RootState) => state.favoriteReducer,
    );

    useEffect(() => {
        dispatch(getUserProfilAsync.request({}));
        dispatch(getFavoriteAsync.request({}));
    }, [dispatch]);

    const handleLogout = () => {};

    const handleRemoveFavorite = (id: string) => {
        dispatch(deleteFavoriteAsync.request(id));
        dispatch(getFavoriteAsync.request({}));
    };
    return (
        <WrappedFavouriteDoctors
            loading={loading}
            userData={data}
            favorite={favorite}
            handleRemoveFavorite={handleRemoveFavorite}
            handleLogout={handleLogout}
        />
    );
};

export default FavouriteDoctorsScreen;
