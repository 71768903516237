/* eslint-disable react/button-has-type */
/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC } from 'react';

type Props = {
    count: number;
    page: number;
    currentPage: number;
    handleSelectedPage(item: number): void;
};

const Pagination: FC<Props> = ({
    count,
    page,
    handleSelectedPage,
    currentPage,
}) => {
    return (
        <div className="pagination-wrapper">
            <ul className="pagination">
                {[...Array(Math.ceil(count / 20))].map((x, i) => {
                    const item = i + 1;
                    return (
                        <li key={String(x)}>
                            <a
                                key={String(x)}
                                href="#/"
                                onClick={() => {
                                    handleSelectedPage(item);
                                }}
                                className={
                                    _.isEqual(currentPage, item)
                                        ? 'current'
                                        : ''
                                }
                            >
                                {item}
                            </a>
                        </li>
                    );
                })}
                {page > 2 && (
                    <li>
                        <a href="clinic-1.html">
                            <i className="icon-Arrow-Right" />
                        </a>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default Pagination;
