import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        padding: theme.spacing(2, 4, 3),
        // backgroundColor: 'red',
        outline: 'none',
    },

    title: {
        fontSize: '20px',
        color: 'black',
    },
}));

export default useStyles;
