/* eslint-disable react/no-unescaped-entities */
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { InputEvent } from 'Types';

import resources from '../../utils/resources';
import Input from '../../components/Input';
import Form from '../../components/Form';
import Button from '../../components/Button';
import BackgroundElement from '../../components/BackgroundElement';

type Props = {
    fields: any;
    errors: any;
    checked: boolean;
    allowedDmpCreation: boolean;
    onChange(e: InputEvent): void;
    handleCheckedTerms(e: InputEvent): void;
    handleAllowedDmpCreation(e: InputEvent): void;
    handleOnClick(e: React.FormEvent<HTMLFormElement>): void;
};

const Register: FC<Props> = ({
    fields,
    errors,
    checked,
    onChange,
    handleOnClick,
    handleCheckedTerms,
    handleAllowedDmpCreation,
    allowedDmpCreation,
}) => {
    return (
        <>
            <BackgroundElement
                title="S'inscrire"
                linkTitle="Se connecter"
                subTitle="S'inscrire"
                linkPath="/login"
            />

            <section className="registration-section bg-color-3">
                <div className="pattern">
                    <div
                        className="pattern-1"
                        style={{
                            backgroundImage: `url(${resources['ic-shap-85']})`,
                        }}
                    />
                    <div
                        className="pattern-2"
                        style={{
                            backgroundImage: `url(${resources['ic-shap-86']})`,
                        }}
                    />
                </div>
                <div className="auto-container">
                    <div className="inner-box">
                        <div className="content-box">
                            <div className="title-box">
                                <h3>S'inscrire</h3>
                            </div>
                            <div className="inner">
                                <Form
                                    className="registration-form needs-validation"
                                    autoComplete="off"
                                    noValidate
                                >
                                    <div className="row clearfix">
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <Input
                                                type="text"
                                                name="firstname"
                                                label="Prénom"
                                                placeholder="Entrez votre Prénom"
                                                required
                                                htmlFor="validationCustom01"
                                                onChange={onChange}
                                                value={fields.firstname}
                                                error={errors.firstname}
                                                className=""
                                                disabled={false}
                                                autocomplete=""
                                                onClick={() => {}}
                                                checked={false}
                                                id=""
                                            />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <Input
                                                type="text"
                                                name="lastname"
                                                label="Nom"
                                                placeholder="Entrez votre nom"
                                                required
                                                htmlFor="validationCustom02"
                                                onChange={onChange}
                                                value={fields.lastname}
                                                error={errors.lastname}
                                                className=""
                                                disabled={false}
                                                autocomplete=""
                                                onClick={() => {}}
                                                checked={false}
                                                id=""
                                            />
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <Input
                                                type="email"
                                                name="email"
                                                label="Email"
                                                placeholder="Entrer votre Email"
                                                required
                                                htmlFor="validationCustom03"
                                                onChange={onChange}
                                                value={fields.email}
                                                error={errors.email}
                                                className=""
                                                disabled={false}
                                                autocomplete=""
                                                onClick={() => {}}
                                                checked={false}
                                                id=""
                                            />
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <Input
                                                type="password"
                                                name="password"
                                                label="Mot de passe"
                                                placeholder="Votre mot de passe"
                                                required
                                                htmlFor="validationCustom04"
                                                onChange={onChange}
                                                value={fields.password}
                                                error={errors.password}
                                                className=""
                                                disabled={false}
                                                autocomplete=""
                                                onClick={() => {}}
                                                checked={false}
                                                id=""
                                            />
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <Input
                                                type="text"
                                                name="phone"
                                                label="Téléphone"
                                                placeholder="Votre numéro de téléphone"
                                                required
                                                htmlFor="validationCustom04"
                                                onChange={onChange}
                                                value={fields.phone}
                                                error={errors.phone}
                                                className=""
                                                disabled={false}
                                                autocomplete=""
                                                onClick={() => {}}
                                                checked={false}
                                                id=""
                                            />
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <div className="custom-check-box">
                                                <div className="custom-controls-stacked">
                                                    <Input
                                                        type="checkbox"
                                                        checked={
                                                            allowedDmpCreation
                                                        }
                                                        onChange={
                                                            handleAllowedDmpCreation
                                                        }
                                                        required
                                                        error={''}
                                                        value={
                                                            allowedDmpCreation
                                                        }
                                                        className="material-control-input"
                                                        disabled={false}
                                                        autocomplete=""
                                                        onClick={() => {}}
                                                        id=""
                                                        placeholder=""
                                                        label=""
                                                        name=""
                                                        htmlFor=""
                                                    />
                                                    <span className="material-control-indicator" />
                                                    <span className="description">
                                                        {
                                                            'Créer mon espace de santé'
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <div className="custom-check-box">
                                                <div className="custom-controls-stacked">
                                                    <Input
                                                        type="checkbox"
                                                        checked={checked}
                                                        onChange={
                                                            handleCheckedTerms
                                                        }
                                                        required
                                                        error={errors.checkbox}
                                                        value={fields.checkbox}
                                                        className="material-control-input"
                                                        disabled={false}
                                                        autocomplete=""
                                                        onClick={() => {}}
                                                        id=""
                                                        placeholder=""
                                                        label=""
                                                        name=""
                                                        htmlFor=""
                                                    />
                                                    <span className="material-control-indicator" />
                                                    <span className="description">
                                                        J'accepte{' '}
                                                        <a
                                                            href="https://loctore.com/cgu"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            les termes
                                                        </a>{' '}
                                                        et{' '}
                                                        <a
                                                            href="https://loctore.com/cgu"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            conditions
                                                        </a>{' '}
                                                        de politique générale
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn">
                                            <Button
                                                className="theme-btn-one"
                                                onClick={handleOnClick}
                                            >
                                                S'inscrire maintenant
                                                <i className="icon-Arrow-Right" />
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                                <div className="text">
                                    <span>ou</span>
                                </div>

                                <div className="login-now">
                                    <p>
                                        Vous avez déjà un compte ?{' '}
                                        <Link to="/login">Se connecter</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Register;
