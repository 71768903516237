import React, { FC } from 'react';
import resources from '../../utils/resources';
import useScript from '../../utils/hooks/useScript';
import BackgroundElement from '../BackgroundElement';
import Footer from '../Footer';
import bdLoctore from '../../bd.pdf';
import AllPagesPDFViewer from './AllPages';

const HowItWorks: FC<unknown> = () => {
    useScript('/static/js/jquery.js');
    useScript('/static/js/jquery-ui.js');
    useScript('https://code.jquery.com/jquery-3.6.0.slim.min.js');
    useScript('/static/js/bootstrap.min.js');
    useScript('/static/js/wow.js');
    useScript('/static/js/popper.min.js');
    useScript('/static/js/owl.js');
    useScript('/static/js/validation.js');
    useScript('/static/js/jquery.fancybox.js');
    useScript('/static/js/appear.js');
    useScript('/static/js/scrollbar.js');
    useScript('/static/js/tilt.jquery.js');
    useScript('/static/js/jquery.paroller.min.js');
    useScript('/static/js/product-filter.js');
    useScript('/static/js/valideFrom.js');
    useScript('/static/js/gmaps.js');
    useScript('/static/js/jquery.nice-select.min.js');
    useScript('/static/js/map-helper.js');
    useScript('/static/js/script.js');
    return (
        <>
            <BackgroundElement
                title="Comment ça marche"
                linkTitle="Acceuil"
                subTitle="Comment ça marche"
                linkPath="/"
            />
            <section className="information-section sec-pad centred bg-color-3">
                <div className="pattern-layer">
                    <div
                        className="pattern-1"
                        style={{
                            backgroundImage: `url(${resources['ic-shap-88']})`,
                        }}
                    ></div>
                    <div
                        className="pattern-2"
                        style={{
                            backgroundImage: `url(${resources['ic-shap-89']})`,
                        }}
                    ></div>
                </div>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <AllPagesPDFViewer pdf={bdLoctore} />
                </div>
            </section>
            <Footer />
        </>
    );
};

export default HowItWorks;
