/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC, useState, useEffect } from 'react';
import { RootState } from 'Types';
import { useDispatch, useSelector } from 'react-redux';

import SummaryAppointement from '../../components/SummaryAppointement';
import {
    startAppointement,
    resetAppointementFeedBack,
    createAppointementAsync,
    updateAppointementAsync,
} from '../../_actions/apointement/appointement.actions';
import AuthService from '../../services/auth-service';
import AppointementSuccessModal from '../../components/AppointementSuccessModal';
import AppointementErrorModal from '../../components/AppointementErrorModal';

import WithModal from '../../utils/hooks/WithModal';
import withLoader from '../../shared/hoc/withLoader';

const WrappedModal = WithModal()(AppointementSuccessModal);
const WrappedErrorModal = WithModal()(AppointementErrorModal);
const WrappedSummaryAppointement = withLoader()(SummaryAppointement);

const SummaryAppointementScreen: FC<unknown> = () => {
    const dispatch = useDispatch();
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

    const { doctor, intervention, availability } = useSelector(
        (state: RootState) => state.summaryReducer,
    );
    const {
        isCreated,
        createAppointement,
        loading,
        isUpdated,
        startUpdated,
        appointementDetails,
        error,
    } = useSelector((state: RootState) => state.appointementReducer);

    const isSignIn = AuthService.isAuthenticated();

    useEffect(() => {
        if (_.isEqual(_.get(error, 'response.error'), true)) {
            setShowErrorModal(true);
        }
    }, [error]);

    const handleStartAppoitement = () => {
        dispatch(startAppointement(true));
    };

    const handleCancelStartAppoitement = () => {
        dispatch(startAppointement(false));
    };

    /*const handleConfirmAppointment = () => {
        dispatch(startAppointement(false));
    };*/

    const hancleCreateAppointement = () => {
        if (startUpdated) {
            dispatch(
                updateAppointementAsync.request({
                    appointement: { appointment: { slot_id: availability.id } },
                    appointementId: appointementDetails.data.id,
                }),
            );
        } else {
            dispatch(
                createAppointementAsync.request({
                    appointment: { slot_id: availability.id },
                }),
            );
        }
    };

    const hancleCloseDialog = () => {
        dispatch(resetAppointementFeedBack({}));
    };

    const hancleCloseErroDialog = () => {
        setShowErrorModal(false);
        dispatch(resetAppointementFeedBack({}));
    };

    return (
        <>
            <WrappedSummaryAppointement
                loading={loading}
                doctor={doctor}
                isSignIn={isSignIn}
                intervention={intervention}
                availability={availability}
                isUpdated={isUpdated}
                startUpdated={startUpdated}
                handleCancelStartAppoitement={handleCancelStartAppoitement}
                hancleCreateAppointement={hancleCreateAppointement}
                handleStartAppoitement={handleStartAppoitement}
            />

            {(!!isCreated || !!isUpdated) &&
                typeof createAppointement !== 'undefined' && (
                    <WrappedModal
                        open={Boolean(true)}
                        createAppointement={createAppointement}
                        hancleCloseDialog={hancleCloseDialog}
                    />
                )}
            {typeof _.get(error, 'response.error') !== 'undefined' &&
                _.isEqual(_.get(error, 'response.error'), true) && (
                    <WrappedErrorModal
                        open={Boolean(showErrorModal)}
                        hancleCloseErroDialog={hancleCloseErroDialog}
                    />
                )}
        </>
    );
};
export default SummaryAppointementScreen;
