import React, { FC, ReactNode } from 'react';

interface IProps<T> {
    renderItem: (item: T) => React.ReactNode;
    keyExtractor: (item: T) => string;
    data: T[];
}

const GenericList = <T extends unknown>({
    data,
    renderItem,
    keyExtractor,
}: IProps<T>) => {
    return <>{data.map((item) => renderItem(item))}</>;
};

export default GenericList;
