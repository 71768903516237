import { ActionType, createStandardAction } from 'typesafe-actions';
import { IData, IAvailability } from 'Models';

import {
    SET_DOCTOR_SUMMAY_REQUEST,
    SET_INTERVENTION_SUMMAY_REQUEST,
    SET_AVAILABILITIES_SUMMAY_REQUEST,
    RESET_DOCTOR_SUMMAY,
} from '../actionTypes';

export type InterventionSummary = {
    id: string;
    name: string;
    price: string;
};

const setDoctorSummary = createStandardAction(
    SET_DOCTOR_SUMMAY_REQUEST,
)<IData>();

const setInterventionSummary = createStandardAction(
    SET_INTERVENTION_SUMMAY_REQUEST,
)<InterventionSummary>();

const setAvailabilitiesSummary = createStandardAction(
    SET_AVAILABILITIES_SUMMAY_REQUEST,
)<IAvailability>();

const resetDoctorSummary = createStandardAction(RESET_DOCTOR_SUMMAY)<IData>();

export type SummaryAction =
    | ActionType<typeof setDoctorSummary>
    | ActionType<typeof setAvailabilitiesSummary>
    | ActionType<typeof resetDoctorSummary>
    | ActionType<typeof setInterventionSummary>;

export {
    setDoctorSummary,
    setInterventionSummary,
    setAvailabilitiesSummary,
    resetDoctorSummary,
};
