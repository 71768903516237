/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC } from 'react';
import { InputEvent } from 'Types';
import { IData } from 'Models';
import Input from '../Input';

type Props = {
    resourcesDetail: IData;
    startUpdated: boolean;
    isCabinetConsultation: boolean;
    isVideoConsultation: boolean;
    handleChecked(e: InputEvent): void;
};

const TypeConsultation: FC<Props> = ({
    startUpdated,
    resourcesDetail,
    isCabinetConsultation,
    isVideoConsultation,
    handleChecked,
}) => {
    const isInOffice = _.isEqual(startUpdated, false);
    const onVideo =
        _.isEqual(startUpdated, false) &&
        !!resourcesDetail.attributes.video_consultation;
    return (
        <div className="choose-service">
            {isInOffice && (
                <>
                    <h3>Type de consultation</h3>
                    <div className="custom-check-radio">
                        <div className="custom-controls-stacked">
                            <div className="single-checkbox">
                                <Input
                                    className=""
                                    disabled={false}
                                    autocomplete=""
                                    onClick={() => {}}
                                    placeholder=""
                                    required={false}
                                    checked={isCabinetConsultation}
                                    label=""
                                    value=""
                                    htmlFor=""
                                    error=""
                                    type="radio"
                                    name="check-box"
                                    id="offices"
                                    onChange={handleChecked}
                                />

                                <label htmlFor="check1" className="description">
                                    En cabinet
                                </label>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {onVideo && (
                <div className="custom-check-radio">
                    <div className="custom-controls-stacked">
                        <div className="single-checkbox">
                            <Input
                                placeholder=""
                                required={false}
                                label=""
                                value=""
                                htmlFor=""
                                error=""
                                checked={isVideoConsultation}
                                type="radio"
                                name="check-box"
                                id="video_consultations"
                                onChange={handleChecked}
                                className=""
                                disabled={false}
                                autocomplete=""
                                onClick={() => {}}
                            />

                            <label htmlFor="check1" className="description">
                                En vidéo
                            </label>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TypeConsultation;
