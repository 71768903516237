/*jsx-a11y/click-events-have-key-events*/
import React, { FC } from 'react';
import { IUserData } from 'Models';
import { InputEvent } from 'Types';

import Button from '../Button';
import Form from '../Form';
import BackgroundElement from '../BackgroundElement';
import ProfileInfoSidbar from '../ProfileInfoSidbar';
import Input from '../Input';
import country from '../../utils/country.json';

type Props = {
    userData: IUserData;
    fields: any;
    errors: any;
    isChecked: string;
    handleInputChange(e: InputEvent): void;
    handleOnClick(e: React.FormEvent<HTMLFormElement>): void;
    handleCheck(naem: string): void;
    handleLogout(): void;
};

const PatientProfile: FC<Props> = ({
    userData,
    fields,
    errors,
    isChecked,
    handleOnClick,
    handleInputChange,
    handleCheck,
    handleLogout,
}) => {
    return (
        <>
            <BackgroundElement
                title="Mes informations personnelles"
                linkTitle="Tableau de bord du patient"
                subTitle="Mon Profile"
                linkPath="/patient-dashboard"
            />

            <section className="patient-dashboard bg-color-3">
                <ProfileInfoSidbar
                    userData={userData}
                    handleLogout={handleLogout}
                />

                <div className="right-panel">
                    <div className="content-container">
                        <div className="outer-container">
                            <div className="add-listing my-profile">
                                <Form>
                                    <div className="single-box">
                                        <div className="title-box">
                                            <h3>
                                                Mes informations personnelles
                                            </h3>
                                        </div>
                                        <div className="inner-box">
                                            <form>
                                                <div className="row clearfix">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                                        <div className="custom-check-box">
                                                            <div className="custom-controls-stacked">
                                                                <label className="custom-control material-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="material-control-input"
                                                                        id="male"
                                                                        value="male"
                                                                        onClick={() =>
                                                                            handleCheck(
                                                                                'male',
                                                                            )
                                                                        }
                                                                        checked={
                                                                            isChecked ===
                                                                            'male'
                                                                        }
                                                                    />
                                                                    <span className="material-control-indicator"></span>
                                                                    <span className="description">
                                                                        Homme
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="custom-check-box">
                                                            <div className="custom-controls-stacked">
                                                                <label className="custom-control material-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="material-control-input"
                                                                        id="female"
                                                                        value="female"
                                                                        onClick={() =>
                                                                            handleCheck(
                                                                                'female',
                                                                            )
                                                                        }
                                                                        checked={
                                                                            isChecked ===
                                                                            'female'
                                                                        }
                                                                    />
                                                                    <span className="material-control-indicator"></span>
                                                                    <span className="description">
                                                                        Femme
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                        <Input
                                                            id="firstname"
                                                            type="text"
                                                            name="firstname"
                                                            label="Prénom"
                                                            placeholder="Entrer votre Prénom"
                                                            required
                                                            htmlFor="validationCustom03"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            value={
                                                                fields.firstname
                                                            }
                                                            error={
                                                                errors.firstname
                                                            }
                                                            className=""
                                                            disabled={false}
                                                            autocomplete=""
                                                            onClick={() => {}}
                                                            checked={false}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                        <Input
                                                            id="lastname"
                                                            type="text"
                                                            name="lastname"
                                                            label="Nom"
                                                            placeholder="Entrer votre Nom"
                                                            required
                                                            htmlFor="validationCustom04"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            value={
                                                                fields.lastname
                                                            }
                                                            error={
                                                                errors.lastname
                                                            }
                                                            className=""
                                                            disabled={false}
                                                            autocomplete=""
                                                            onClick={() => {}}
                                                            checked={false}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                        <Input
                                                            id="email"
                                                            type="email"
                                                            name="email"
                                                            label="Email"
                                                            placeholder="Entrer votre Email"
                                                            required
                                                            htmlFor="validationCustom05"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            value={fields.email}
                                                            error={errors.email}
                                                            className=""
                                                            disabled={false}
                                                            autocomplete=""
                                                            onClick={() => {}}
                                                            checked={false}
                                                        />
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                        <Input
                                                            id="birthdate"
                                                            type="text"
                                                            name="birthdate"
                                                            label="Date de naissance"
                                                            placeholder="Entrer votre  Date de naissance"
                                                            required
                                                            htmlFor="validationCustom06"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            value={
                                                                fields.birthdate
                                                            }
                                                            error={
                                                                errors.birthdate
                                                            }
                                                            className=""
                                                            disabled={false}
                                                            autocomplete=""
                                                            onClick={() => {}}
                                                            checked={false}
                                                        />
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                        <Input
                                                            id="phone"
                                                            type="text"
                                                            name="phone"
                                                            label="Numéro de téléphone"
                                                            placeholder="Entrer votre  numéro de téléphone"
                                                            required
                                                            htmlFor="validationCustom08"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            value={fields.phone}
                                                            error={errors.phone}
                                                            className=""
                                                            disabled={false}
                                                            autocomplete=""
                                                            onClick={() => {}}
                                                            checked={false}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                        <Input
                                                            id="address"
                                                            type="text"
                                                            name="address"
                                                            label="Adresse"
                                                            placeholder="Entrer votre adresse"
                                                            required
                                                            htmlFor="validationCustom09"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            value={
                                                                fields.address
                                                            }
                                                            error={
                                                                errors.address
                                                            }
                                                            className=""
                                                            disabled={false}
                                                            autocomplete=""
                                                            onClick={() => {}}
                                                            checked={false}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                        <Input
                                                            id="zipcode"
                                                            type="text"
                                                            name="zipcode"
                                                            label="Code postal"
                                                            placeholder="Entrer votre code postal"
                                                            required
                                                            htmlFor="validationCustom10"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            value={
                                                                fields.zipcode
                                                            }
                                                            error={
                                                                errors.zipcode
                                                            }
                                                            className=""
                                                            disabled={false}
                                                            autocomplete=""
                                                            onClick={() => {}}
                                                            checked={false}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                        <Input
                                                            id="birthcity"
                                                            type="text"
                                                            name="birthcity"
                                                            label="Ville de naissance"
                                                            placeholder="Entrer votre ville de naissance"
                                                            required
                                                            htmlFor="validationCustom11"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            value={
                                                                fields.birthcity
                                                            }
                                                            error={
                                                                errors.birthcity
                                                            }
                                                            className=""
                                                            disabled={false}
                                                            autocomplete=""
                                                            onClick={() => {}}
                                                            checked={false}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                                        <label>
                                                            Pays de naissance
                                                        </label>
                                                        <select
                                                            id="country"
                                                            className="wide"
                                                        >
                                                            {country.map(
                                                                (item) => {
                                                                    return (
                                                                        <option
                                                                            value={item.alpha2.toLocaleUpperCase()}
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </option>
                                                                    );
                                                                },
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="btn-box">
                                        <Button
                                            title=""
                                            onClick={handleOnClick}
                                            className="theme-btn-one"
                                        >
                                            Sauvegarder
                                            <i className="icon-Arrow-Right"></i>
                                        </Button>
                                        <Button
                                            title=""
                                            onClick={() => {}}
                                            className="cancel-btn"
                                        >
                                            Annuler
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PatientProfile;
