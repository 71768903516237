/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.confirmedAppointment;

type Props = {
    hancleCloseErroDialog(): void;
};

const AppointementErrorModal: FC<Props> = ({ hancleCloseErroDialog }) => {
    return (
        <>
            <section className="submit-review bg-logout">
                <div className="auto-container">
                    <div className="review-box">
                        <div className="content-box">
                            <div className="title-inner">
                                <h3>{"Oups une erreur s'est produite."}</h3>
                                <p>
                                    {
                                        "Une erreur inattendue s'est produite lors de la prise de rendez-vous. Veuillez réessayer ultérieurement. Merci pour votre compréhension"
                                    }
                                </p>
                            </div>
                            <div className="content-inner">
                                <div className="form-inner">
                                    <div>
                                        <div className="row clearfix">
                                            <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn">
                                                <Link to="/patient-dashboard">
                                                    <Button
                                                        onClick={
                                                            hancleCloseErroDialog
                                                        }
                                                        className="theme-btn-one"
                                                    >
                                                        Fermer
                                                        <i className="icon-Arrow-Right"></i>
                                                    </Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AppointementErrorModal;
