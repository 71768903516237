import FooterLogo from '../assets/images/footer-logo.png';
import Logo3 from '../assets/images/logo-3.png';
import SmallLogo from '../assets/images/small-logo.png';
import AnimIcon1 from '../assets/images/icons/anim-icon-1.png';
import AnimIcon2 from '../assets/images/icons/anim-icon-2.png';
import AnimIcon3 from '../assets/images/icons/anim-icon-3.png';
import AnimIcon4 from '../assets/images/icons/anim-icon-4.png';
import AnimIcon5 from '../assets/images/icons/anim-icon-5.png';
import AnimIcon6 from '../assets/images/icons/anim-icon-6.png';
import AnimIcon7 from '../assets/images/icons/anim-icon-7.png';
import AnimIcon8 from '../assets/images/icons/anim-icon-8.png';
import Shap67 from '../assets/images/shape/shape-67.png';
import Shap47 from '../assets/images/shape/shape-47.png';
import Shap48 from '../assets/images/shape/shape-48.png';
import Shap45 from '../assets/images/shape/shape-45.png';
import Shap46 from '../assets/images/shape/shape-46.png';
import Icon12 from '../assets/images/icons/icon-12.png';
import Icon13 from '../assets/images/icons/icon-13.png';
import Shap49 from '../assets/images/shape/shape-49.png';
import Shap50 from '../assets/images/shape/shape-50.png';
import Shap70 from '../assets/images/shape/shape-70.png';
import Shap71 from '../assets/images/shape/shape-71.png';
import About4 from '../assets/images/resource/about-4.jpg';
import About3 from '../assets/images/resource/about-3.jpg';
import Icon8 from '../assets/images/icons/icon-8.png';
import Shap39 from '../assets/images/shape/shape-39.png';
import Shap40 from '../assets/images/shape/shape-40.png';
import Shap41 from '../assets/images/shape/shape-41.png';
import Shap42 from '../assets/images/shape/shape-42.png';
import Arrow1 from '../assets/images/icons/arrow-1.png';
import Icon9 from '../assets/images/icons/icon-9.png';
import Icon10 from '../assets/images/icons/icon-10.png';
import Icon11 from '../assets/images/icons/icon-11.png';
import Shap54 from '../assets/images/shape/shape-54.png';
import Faq1 from '../assets/images/resource/faq-1.png';
import New1 from '../assets/images/news/news-1.jpg';
import Admin1 from '../assets/images/news/admin-1.png';
import New2 from '../assets/images/news/news-2.jpg';
import Admin2 from '../assets/images/news/admin-2.png';
import New3 from '../assets/images/news/news-3.jpg';
import Admin3 from '../assets/images/news/admin-3.png';
import Shap85 from '../assets/images/shape/shape-85.png';
import Shap86 from '../assets/images/shape/shape-86.png';
import Preloader from '../assets/images/icons/preloader.svg';
import LoctoreLogo from '../assets/images/loctore.svg';
import Shap24 from '../assets/images/shape/shape-24.png';
import Shap25 from '../assets/images/shape/shape-25.png';
import MapMarker from '../assets/images/icons/map-marker.png';
import Team11 from '../assets/images/team/team-11.jpg';
import Profil1 from '../assets/images/resource/profile-1.png';

import Shap79 from '../assets/images/shape/shape-79.png';
import Shap80 from '../assets/images/shape/shape-80.png';
import Shap83 from '../assets/images/shape/shape-83.png';
import Shap84 from '../assets/images/shape/shape-84.png';
import EmptyRdv from '../assets/images/empty_rdv.png';
import Shap88 from '../assets/images/shape/shape-88.png';
import Shap89 from '../assets/images/shape/shape-89.png';
import Shap87 from '../assets/images/shape/shape-87.png';
import Shap90 from '../assets/images/shape/shape-90.png';
import Icon21 from '../assets/images/icons/icon-21.png';
import Icon22 from '../assets/images/icons/icon-22.png';
import Icon20 from '../assets/images/icons/icon-20.png';
import Shap2 from '../assets/images/shape/shape-2.png';

import Banner from '../assets/images/banner/home_img.png';
import EmptyDoctorList from '../assets/images/empty_doctor.svg';
import UserAvatar from '../assets/images/avatar_resize.png';
import empty from '../assets/images/55-1.svg';
import picture from '../assets/images/67.svg';

// import BdLoctore from '../assets/pdf/bd-loctore';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'ic-footer-logo': FooterLogo,
    'ic-logo-3': Logo3,
    'ic-small-logo': SmallLogo,
    'ic-anim-icon-1': AnimIcon1,
    'ic-anim-icon-2': AnimIcon2,
    'ic-anim-icon-3': AnimIcon3,
    'ic-anim-icon-4': AnimIcon4,
    'ic-anim-icon-5': AnimIcon5,
    'ic-anim-icon-6': AnimIcon6,
    'ic-anim-icon-7': AnimIcon7,
    'ic-anim-icon-8': AnimIcon8,
    'ic-shap-67': Shap67,
    'ic-shap-47': Shap47,
    'ic-shap-48': Shap48,
    'ic-shap-45': Shap45,
    'ic-shap-46': Shap46,
    'ic-icon-12': Icon12,
    'ic-icon-13': Icon13,
    'ic-shap-49': Shap49,
    'ic-shap-50': Shap50,
    'ic-shap-70': Shap70,
    'ic-shap-71': Shap71,
    'ic-about-4': About4,
    'ic-about-3': About3,
    'ic-icon-8': Icon8,
    'ic-shap-39': Shap39,
    'ic-shap-40': Shap40,
    'ic-shap-41': Shap41,
    'ic-shap-42': Shap42,
    'ic-arrow-1': Arrow1,
    'ic-icon-9': Icon9,
    'ic-icon-10': Icon10,
    'ic-icon-11': Icon11,
    'ic-shap-54': Shap54,
    'ic-faq-1': Faq1,
    'ic-new-1': New1,
    'ic-admin-1': Admin1,
    'ic-new-2': New2,
    'ic-admin-2': Admin2,
    'ic-new-3': New3,
    'ic-admin-3': Admin3,
    'ic-shap-85': Shap85,
    'ic-shap-86': Shap86,
    'ic-preloader': Preloader,
    'ic-shap-24': Shap24,
    'ic-shap-25': Shap25,
    'ic-map-marker': MapMarker,
    'ic-team-11': Team11,
    'ic-profil-1': Profil1,
    'ic-shap-79': Shap79,
    'ic-shap-80': Shap80,
    'ic-shap-83': Shap83,
    'ic-shap-84': Shap84,
    'ic-loctore': LoctoreLogo,
    'ic-empty-rdv': EmptyRdv,
    'ic-shap-87': Shap87,
    'ic-shap-88': Shap88,
    'ic-shap-89': Shap89,
    'ic-shap-90': Shap90,
    'ic-icon-21': Icon21,
    'ic-icon-22': Icon22,
    'ic-icon-20': Icon20,
    'ic-banner': Banner,
    'ic-shap-2': Shap2,
    'ic-empty-doctor-list': EmptyDoctorList,
    'ic-user-avatar': UserAvatar,
    'ic-empty': empty,
    'ic-picture': picture,
};
