/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */

import _ from 'lodash';
import React, { FC } from 'react';
import { IUserData } from 'Models';
import { Link } from 'react-router-dom';

import resources from '../../utils/resources';
import { converteDate } from '../../utils/helpers';

type Props = {
    userData: IUserData;
    handleLogout(): void;
};

const ProfileInfoSidbar: FC<Props> = ({ userData, handleLogout }) => {
    return (
        <>
            <div className="left-panel">
                <div className="profile-box patient-profile">
                    <div className="upper-box">
                        <figure className="profile-image">
                            <img src={resources['ic-picture']} alt="" />
                        </figure>
                        <div className="title-box centred">
                            <div className="inner">
                                <h3>{userData.attributes.card.full_name}</h3>
                                <p>
                                    <i className="fas fa-calendar-alt"></i>
                                    {converteDate(
                                        new Date(
                                            userData.attributes.card.birthdate,
                                        ),
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="profile-info">
                        <ul className="list clearfix">
                            <li>
                                <Link to="/patient-dashboard">
                                    <a
                                        className={`${
                                            _.isEqual(
                                                window.location.pathname,
                                                '/patient-dashboard',
                                            )
                                                ? 'current'
                                                : ''
                                        }`}
                                    >
                                        <i className="fas fa-columns"></i>
                                        Dashboard
                                    </a>
                                </Link>
                            </li>

                            <li>
                                <Link to="/favourite-doctors">
                                    <a
                                        className={`${
                                            _.isEqual(
                                                window.location.pathname,
                                                '/favourite-doctors',
                                            )
                                                ? 'current'
                                                : ''
                                        }`}
                                    >
                                        <i className="fas fa-heart"></i>
                                        Mes favoris
                                    </a>
                                </Link>
                            </li>

                            <li>
                                <Link to="/patient-profile">
                                    <a
                                        className={`${
                                            _.isEqual(
                                                window.location.pathname,
                                                '/patient-profile',
                                            )
                                                ? 'current'
                                                : ''
                                        }`}
                                    >
                                        <i className="fas fa-user"></i>Mon
                                        Profile
                                    </a>
                                </Link>
                            </li>
                            <li>
                                <Link to="/reset-password">
                                    <a
                                        className={`${
                                            _.isEqual(
                                                window.location.pathname,
                                                '/reset-password',
                                            )
                                                ? 'current'
                                                : ''
                                        }`}
                                    >
                                        <i className="fas fa-unlock-alt"></i>
                                        Changer le mot de passe
                                    </a>
                                </Link>
                            </li>

                            <li>
                                <a
                                    href="https://mes-patient.loctore.com/auth/sign_in"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fas fa-folder-open" />
                                    Mon dossier médical partagé
                                </a>
                            </li>
                            <li>
                                <Link to="/relatives">
                                    <a
                                        className={`${
                                            _.isEqual(
                                                window.location.pathname,
                                                '/relatives',
                                            )
                                                ? 'current'
                                                : ''
                                        }`}
                                    >
                                        <i className="fas fa-users"></i>Mes
                                        proches
                                    </a>
                                </Link>
                            </li>
                            <li>
                                <Link to="/documents">
                                    <a
                                        className={`${
                                            _.isEqual(
                                                window.location.pathname,
                                                '/documents',
                                            )
                                                ? 'current'
                                                : ''
                                        }`}
                                    >
                                        <i className="fas fa-file-download"></i>
                                        Mes documents
                                    </a>
                                </Link>
                            </li>
                            <li>
                                <a
                                    style={{ cursor: 'pointer' }}
                                    onClick={handleLogout}
                                    role="button"
                                >
                                    <i className="fas fa-sign-out-alt"></i>
                                    Se déconnecter
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfileInfoSidbar;
