import React, { FC } from 'react';
import Button from '../Button';

type Props = {
    handleDelete(): void;
    handleCancel(): void;
};

const DocumentModal: FC<Props> = ({ handleDelete, handleCancel }) => {
    return (
        <>
            <section className="submit-review bg-logout">
                <div className="auto-container">
                    <div className="review-box">
                        <div className="content-box">
                            <div className="title-inner">
                                <h3>Supprimer un document</h3>
                                <p>
                                    Êtes-vous sûr de vouloir supprimer le
                                    document ?
                                </p>
                            </div>
                            <div className="content-inner">
                                <div className="form-inner">
                                    <div>
                                        <div className="row clearfix">
                                            <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                                <Button
                                                    className="theme-btn-one"
                                                    onClick={handleCancel}>
                                                    Annuler
                                                </Button>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                                <Button
                                                    className="theme-btn-one"
                                                    onClick={handleDelete}>
                                                    Valider
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default DocumentModal;
