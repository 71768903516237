class AuthService {
    public static authenticate() {}

    public static logout(): void {
        localStorage.removeItem('header');
        localStorage.removeItem('reduxState');
    }

    public static isAuthenticated(): boolean {
        const header = localStorage.getItem('header');
        return header !== undefined && header !== null;
    }
}

export default AuthService;
