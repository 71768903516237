/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC } from 'react';
import { Calendar } from 'react-modern-calendar-datepicker';
import { IRootAvailability, IAvailability } from 'Models';
import { Link } from 'react-router-dom';

import Input from '../Input';
import TimeItem from '../TimeItem';

type DProps = {
    year: number;
    month: number;
    day: number;
};

type Props = {
    isCabinetConsultation: boolean;
    isVideoConsultation: boolean;
    selectedReason: string;
    showDatepicker: boolean;
    selectedDay: any;
    availabilities: IRootAvailability;
    isTimeSelected: boolean;
    handleDateChange(e: DProps): void;
    handleShowDatePicker(): void;
    onHandleSelectedTime(availability: IAvailability): void;
};
const SelectDate: FC<Props> = ({
    isCabinetConsultation,
    isVideoConsultation,
    selectedDay,
    selectedReason,
    showDatepicker,
    handleDateChange,
    handleShowDatePicker,
    availabilities,
    isTimeSelected,
    onHandleSelectedTime,
}) => {
    const show =
        (!!isCabinetConsultation || !!isVideoConsultation) &&
        !_.isEmpty(selectedReason);

    const noAvailability = _.isEmpty(availabilities.data);

    const showBtn =
        !_.isEmpty(availabilities.data) && _.isEqual(isTimeSelected, true);

    return (
        <>
            {show && (
                <div className="form-inner">
                    <div className="appointment-time">
                        <h3>Sélectionnez une date </h3>
                        <div className="form-group">
                            <Input
                                type="text"
                                name="date"
                                placeholder={`${selectedDay.day}/${selectedDay.month}/${selectedDay.year}`}
                                value={`${selectedDay.day}/${selectedDay.month}/${selectedDay.year}`}
                                id="datepicker"
                                onClick={handleShowDatePicker}
                                onChange={() => {}}
                                className=""
                                disabled={false}
                                autocomplete=""
                                required={false}
                                checked={false}
                                label=""
                                htmlFor=""
                                error=""
                            />

                            {!!showDatepicker && (
                                <Calendar
                                    value={selectedDay}
                                    onChange={handleDateChange}
                                    shouldHighlightWeekends
                                    colorPrimary="#9c88ff"
                                    calendarClassName="custom-calendar"
                                    calendarTodayClassName="custom-today-day"
                                />
                            )}
                            <i className="fas fa-calendar-alt"></i>
                        </div>

                        <div className="wrapper" role="button" tabIndex={0}>
                            {!noAvailability &&
                                availabilities.data[0].availabilities.map(
                                    (availabilitie) => {
                                        return (
                                            <TimeItem
                                                key={availabilitie.id.toString()}
                                                id={availabilitie.id.toString()}
                                                startsAt={
                                                    availabilitie.starts_at
                                                }
                                                handleClick={() =>
                                                    onHandleSelectedTime(
                                                        availabilitie,
                                                    )
                                                }
                                            />
                                        );
                                    },
                                )}
                        </div>
                        {noAvailability && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                Pas de disponibilité
                            </div>
                        )}
                    </div>

                    <div className="choose-service">
                        {showBtn && (
                            <div className="btn-box">
                                <Link to="/appointement" onClick={() => {}}>
                                    <span className="theme-btn-one">
                                        Prendre rendez-vous
                                        <i className="icon-Arrow-Right"></i>
                                    </span>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default SelectDate;
