/* eslint-disable react/require-default-props */
import React from 'react';

const Form = (props: {
    [x: string]: any;
    children: any;
    className?: string;
}) => {
    const { children, className, ...other } = props;
    return (
        <form className={className} autoComplete="off" {...other} noValidate>
            {children}
        </form>
    );
};

export default Form;
