/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { ISpeciality } from 'Models';

import SpecialityItem from '../SpecialityItem';
import BackgroundElement from '../BackgroundElement';
import GenericList from '../GenericList';

type Props = {
    speciality: ISpeciality[];
    handleOnClick(id: string): void;
};

const AllSpecility: FC<Props> = ({ speciality, handleOnClick }) => {
    return (
        <>
            <BackgroundElement
                title="Toutes les spécialités"
                linkTitle="Accueil"
                subTitle="Toutes les spécialités"
                linkPath="/"
            />

            <section className="category-section bg-color-3 centred">
                <div className="auto-container">
                    <div className="row clearfix">
                        <GenericList
                            keyExtractor={({ id }) => id}
                            data={speciality}
                            renderItem={(item) => {
                                return (
                                    <SpecialityItem
                                        key={item.id}
                                        id={item.id}
                                        label={item.attributes.name}
                                        src={''}
                                        handleOnClick={handleOnClick}
                                    />
                                );
                            }}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default AllSpecility;
