/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC } from 'react';
import { IInterventionData } from 'Models';
import { InputEvent } from 'Types';

import Input from '../Input';

type Props = {
    isCabinetConsultation: boolean;
    isVideoConsultation: boolean;
    interventions: IInterventionData[];
    selectedReason: string;
    onValueChange(e: InputEvent): void;
};

const ReasonConsultation: FC<Props> = ({
    interventions,
    isCabinetConsultation,
    isVideoConsultation,
    onValueChange,
    selectedReason,
}) => {
    const show = !!isCabinetConsultation || !!isVideoConsultation;
    return (
        <>
            {show && (
                <div className="choose-service">
                    <h3>Motif de consultation</h3>
                    {!!interventions &&
                        interventions.map((intervention) => {
                            return (
                                <div
                                    className="custom-check-radio"
                                    key={intervention.id.toString()}
                                >
                                    <div className="custom-controls-stacked">
                                        <div className="single-checkbox">
                                            <Input
                                                key={intervention.id.toString()}
                                                type="radio"
                                                name={
                                                    intervention.attributes.name
                                                }
                                                id={intervention.id}
                                                onChange={onValueChange}
                                                checked={_.isEqual(
                                                    selectedReason,
                                                    intervention.id,
                                                )}
                                                value={
                                                    intervention.attributes
                                                        .price
                                                }
                                                className=""
                                                disabled={false}
                                                autocomplete=""
                                                onClick={() => {}}
                                                placeholder=""
                                                required={false}
                                                label=""
                                                htmlFor=""
                                                error=""
                                            />

                                            <label
                                                htmlFor="check1"
                                                className="description"
                                            >
                                                {intervention.attributes.name}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            )}
        </>
    );
};

export default ReasonConsultation;
