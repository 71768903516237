/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { RootState } from 'Types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import withLoader from '../../shared/hoc/withLoader';
import Snackbar from '../../components/Snackbar';
import { getSpecialityAsync } from '../../_actions/speciality/specialities.actions';
import {
    startAppointement,
    startUpdatedAppointement,
} from '../../_actions/apointement/appointement.actions';
import {
    getAutocompletionResourcesAsync,
    getResourcesBySearchAsync,
    setSearchParameter,
} from '../../_actions/resources/resources.actions';

import Banner from '../../components/Banner';
import Category from '../../components/Section/Category';
import Process from '../../components/Section/Process';

import useScript from '../../utils/hooks/useScript';
import useGeolocation from '../../utils/hooks/useGeolocation';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const WrappedBanner = withLoader()(Banner);

const MainScreen: FC<unknown> = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useGeolocation();
    const [selected, setSelected] = useState<any>([]);
    const [selectedAdresse, setSelectedAdresse] = useState<any>([]);
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [currentCoordinates, setCurrentCoordinates] = useState<any>({
        coordinates: { lat: '', lng: '' },
    });
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: 'AIzaSyDcjWR70s26ClmKvcXyYLtKHBnvHgzpk7k',
        options: {
            // types: ['locality', 'political'],
            componentRestrictions: { country: 'ci' },
        },
    });

    useScript('/static/js/script.js');
    useScript('/static/js/scrollbar.js');

    const { loading, data } = useSelector(
        (state: RootState) => state.specialityReducer,
    );

    const { autocompletionLoading, autocompletion } = useSelector(
        (state: RootState) => state.resourcesReducer,
    );

    const handleOnclick = () => {
        dispatch(
            setSearchParameter({
                term: _.get(selected, '[0].name'),
                city: _.get(selectedAdresse, '[0].name'),
                lat: _.get(currentCoordinates, 'coordinates.lat'),
                lng: _.get(currentCoordinates, 'coordinates.lng'),
            }),
        );
        history.push(`/search`);
    };

    /**
     * When user check current location
     */
    const handleCheckCurrentLocation = () => {
        if (
            _.isEqual(_.get(location, 'error.code'), 1) &&
            _.isEqual(
                _.get(location, 'error.message'),
                'User denied Geolocation',
            )
        ) {
            setShowSnackbar(true);
        } else {
            setCurrentCoordinates({
                coordinates: {
                    lat: _.get(location, 'coordinates.lat'),
                    lng: _.get(location, 'coordinates.lng'),
                },
            });
            setSelectedAdresse([
                {
                    id: 0,
                    name: 'AUTOUR DE MOI',
                },
            ]);
        }
    };

    /**
     *  When use selected an address in the search bar
     * @param address
     */

    const handlesOnSelectedAddress = (address: any) => {
        setCurrentCoordinates({
            coordinates: {
                lat: '',
                lng: '',
            },
        });
    };

    /**
     * Autocompletion search
     * @param query
     */
    const handleSearch = (query: string) => {
        dispatch(getAutocompletionResourcesAsync.request({ term: query }));
    };

    /**
     * When search adresse
     * @param query
     */
    const handleSearchAdresse = (query: string) => {
        getPlacePredictions({ input: query });
    };

    /**
     * Close snackbar
     */
    const handleSnackClose = () => {
        setShowSnackbar(false);
    };

    /**
     * Display doctors view when slected user type is equal to resource
     * @param select
     */
    const handleOnSelected = (select: any) => {
        if (_.isEqual(select[0].type, 'resource')) {
            history.push(`/doctors-details/${select[0].id}`);
        }
    };
    const handleOnClick = (id: string) => {
        history.push(`/doctors/${id}`);
    };
    useEffect(() => {
        dispatch(getSpecialityAsync.request({}));
        dispatch(startAppointement(false));
        dispatch(startUpdatedAppointement(false));
    }, [dispatch]);

    return (
        <>
            <Header />
            <WrappedBanner
                data={data}
                loading={loading}
                selected={selected}
                selectedAdresse={selectedAdresse}
                setSelected={setSelected}
                handleOnclick={handleOnclick}
                handleSearch={handleSearch}
                autocompletion={autocompletion}
                setSelectedAdresse={setSelectedAdresse}
                autocompletionLoading={autocompletionLoading}
                handleOnSelected={handleOnSelected}
                placePredictions={placePredictions}
                isPlacePredictionsLoading={false}
                handlesOnSelectedAddress={handlesOnSelectedAddress}
                handleSearchAdresse={handleSearchAdresse}
                handleCheckCurrentLocation={handleCheckCurrentLocation}
            />

            <Category data={data} handleOnClick={handleOnClick} />
            <Process />

            {showSnackbar && (
                <Snackbar
                    message={_.get(location, 'error.message')}
                    isError={Boolean(true)}
                    onClose={handleSnackClose}
                />
            )}
            <Footer />
        </>
    );
};

export default MainScreen;
