/* eslint-disable import/no-extraneous-dependencies */
import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import {
    createAsyncAction,
    isActionOf,
    ActionType,
    createStandardAction,
} from 'typesafe-actions';
import { RootAction, RootState, Services } from 'Types';

import {
    IRelative,
    IAddRelativeRequest,
    IAddRelativeResponse,
    IRelativeData,
} from 'Models';

import {
    GET_RELATIVE_REQUEST,
    GET_RELATIVE_SUCCESS,
    GET_RELATIVE_FAILURE,
    DELETE_RELATIVE_REQUEST,
    DELETE_RELATIVE_SUCCESS,
    DELETE_RELATIVE_FAILURE,
    ADD_RELATIVE_REQUEST,
    ADD_RELATIVE_SUCCESS,
    ADD_RELATIVE_FAILURE,
    UPDATE_RELATIVE_REQUEST,
    UPDATE_RELATIVE_SUCCESS,
    UPDATE_RELATIVE_FAILURE,
    START_UPDATE_RELATIVE_REQUEST,
} from '../actionTypes';

const getRelativeAsync = createAsyncAction(
    GET_RELATIVE_REQUEST,
    GET_RELATIVE_SUCCESS,
    GET_RELATIVE_FAILURE,
)<any, IRelative, any>();

const deleteRelativeAsync = createAsyncAction(
    DELETE_RELATIVE_REQUEST,
    DELETE_RELATIVE_SUCCESS,
    DELETE_RELATIVE_FAILURE,
)<string, any, any>();

const addRelativeAsync = createAsyncAction(
    ADD_RELATIVE_REQUEST,
    ADD_RELATIVE_SUCCESS,
    ADD_RELATIVE_FAILURE,
)<IAddRelativeRequest, IAddRelativeResponse, any>();

const updateRelativeAsync = createAsyncAction(
    UPDATE_RELATIVE_REQUEST,
    UPDATE_RELATIVE_SUCCESS,
    UPDATE_RELATIVE_FAILURE,
)<IPayload, IAddRelativeResponse, any>();

const startedUpdate = createStandardAction(START_UPDATE_RELATIVE_REQUEST)<
    IRelativeData | any
>();

export type RelativeAction =
    | ActionType<typeof getRelativeAsync>
    | ActionType<typeof addRelativeAsync>
    | ActionType<typeof updateRelativeAsync>
    | ActionType<typeof startedUpdate>
    | ActionType<typeof deleteRelativeAsync>;

interface IPayload {
    card: IAddRelativeRequest;
    relativeId: string;
}

const preparePayload = (relativeId: string) => {
    return {
        relativeId,
    };
};

const prepareAddRelativePayload = (card: IAddRelativeRequest) => {
    return {
        card,
    };
};

const prepareUpdateRelativePayload = ({ card, relativeId }: IPayload) => {
    return {
        card,
        relativeId,
    };
};

const mapGetRelative = (action: RootAction, { apiRequest }: Services) => {
    return apiRequest<IRelative>({
        path: '/cards',
        method: 'get',
        body: '',
    }).pipe(
        mergeMap((response: IRelative) => {
            if (response) {
                return of(getRelativeAsync.success(response));
            }
            return of(getRelativeAsync.failure(response));
        }),
        catchError((error) => {
            return of(getRelativeAsync.failure(error));
        }),
    );
};

const mapDeleteRelative = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayload(action.payload);
    return apiRequest<any>({
        path: `${'/cards/'}${payload.relativeId}`,
        method: 'delete',
        body: '',
    }).pipe(
        mergeMap((response: any) => {
            if (response === null) {
                return of(deleteRelativeAsync.success(response));
            }
            return of(deleteRelativeAsync.failure(response));
        }),
        catchError((error) => {
            return of(deleteRelativeAsync.failure(error));
        }),
    );
};

const mapAddRelative = (action: RootAction, { apiRequest }: Services) => {
    const payload = prepareAddRelativePayload(action.payload);
    return apiRequest<IAddRelativeResponse>({
        path: '/cards',
        method: 'post',
        body: payload.card,
    }).pipe(
        mergeMap((response: IAddRelativeResponse) => {
            if (response) {
                return of(addRelativeAsync.success(response));
            }
            return of(addRelativeAsync.failure(response));
        }),
        catchError((error) => {
            return of(addRelativeAsync.failure(error));
        }),
    );
};

const mapUpdateRelative = (action: RootAction, { apiRequest }: Services) => {
    const payload = prepareUpdateRelativePayload(action.payload);
    return apiRequest<any>({
        path: `${'/cards/'}${payload.relativeId}`,
        method: 'put',
        body: payload.card,
    }).pipe(
        mergeMap((response: any) => {
            if (response) {
                return of(updateRelativeAsync.success(response));
            }
            return of(updateRelativeAsync.failure(response));
        }),
        catchError((error) => {
            return of(updateRelativeAsync.failure(error));
        }),
    );
};

const relativeEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(getRelativeAsync.request)),
        mergeMap((action) => mapGetRelative(action, dependency)),
    );

const deleteRelativeEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(deleteRelativeAsync.request)),
        mergeMap((action) => mapDeleteRelative(action, dependency)),
    );

const addRelativeEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(addRelativeAsync.request)),
        mergeMap((action) => mapAddRelative(action, dependency)),
    );

const updateRelativeEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(updateRelativeAsync.request)),
        mergeMap((action) => mapUpdateRelative(action, dependency)),
    );

export {
    getRelativeAsync,
    relativeEpic,
    deleteRelativeAsync,
    deleteRelativeEpic,
    addRelativeAsync,
    addRelativeEpic,
    updateRelativeAsync,
    updateRelativeEpic,
    startedUpdate,
};
