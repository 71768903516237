/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'Types';

import useForm from '../../shared/hoc/useForm';
import { isValidEmail } from '../../utils/helpers';
import ForgotPassowd from '../../containers/ForgotPassowrd';
import WordingConstant from '../../utils/wording.json';
import {
    resetPasswordAsync,
    resetUserFeedback,
} from '../../_actions/auth/auth.actions';
import withLoader from '../../shared/hoc/withLoader';

import Snackbar from '../../components/Snackbar';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import useScript from '../../utils/hooks/useScript';
import useOutsideClick from '../../utils/hooks/useOutsideClick';

const WrappedForgotPassowd = withLoader()(ForgotPassowd);

const initialFValues = {
    id: 0,
    email: '',
};
const Wording = WordingConstant.Register;

const ForgotPassowrdScreen: FC<unknown> = () => {
    const dispatch = useDispatch();
    const divRef = useRef<HTMLDivElement>(null);

    useScript('/static/js/jquery.nice-select.min.js');
    useScript('/static/js/script.js');
    useScript('/static/js/scrollbar.js');

    const { message, success, error, loading } = useSelector(
        (state: RootState) => state.authReducer,
    );

    const validate = (fieldValues = values) => {
        const temp: any = { ...errors };

        if ('email' in fieldValues) {
            temp.email = isValidEmail(fieldValues.email)
                ? ''
                : Wording.Errors.email;
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
    };

    const handleOnClick = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validate()) {
            dispatch(resetPasswordAsync.request({ email: values.email }));
        }
    };

    const handleSnackClose = () => {
        dispatch(resetUserFeedback());
    };

    const { values, errors, setErrors, handleInputChange } = useForm(
        initialFValues,
        true,
        validate,
    );

    useOutsideClick(divRef, () => {
        if (!_.isEmpty(message) || !_.isEmpty(error)) {
            dispatch(resetUserFeedback());
        }
    });

    return (
        <div ref={divRef}>
            <Header />
            <WrappedForgotPassowd
                loading={loading}
                fields={values}
                errors={errors}
                handleInputChange={handleInputChange}
                handleOnClick={handleOnClick}
            />

            {(!_.isEmpty(message) || !_.isEmpty(error)) && (
                <Snackbar
                    message={success ? message : error}
                    isError={!success}
                    onClose={handleSnackClose}
                />
            )}
            <Footer />
        </div>
    );
};

export default ForgotPassowrdScreen;
