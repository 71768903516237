/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import Button from '../Button';

interface IInjectedProps {
    handleNon(): void;
    handleYes(): void;
}

type Props = IInjectedProps & RouteComponentProps<any>;

const CancelAppointementModal: React.FC<Props> = ({ handleNon, handleYes }) => {
    return (
        <section className="submit-review bg-logout">
            <div className="auto-container">
                <div className="review-box">
                    <div className="content-box">
                        <div className="title-inner">
                            <h3>Annuler le rendez-vous</h3>
                            <p>
                                Êtes-vous sûr de vouloir annuler le rendez-vous
                                ?
                            </p>
                        </div>
                        <div className="content-inner">
                            <div className="form-inner">
                                <div>
                                    <div className="row clearfix">
                                        <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                            <Button
                                                data-testid="no"
                                                className="theme-btn-one"
                                                onClick={handleNon}
                                            >
                                                NON
                                            </Button>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                            <Button
                                                data-testid="yes"
                                                className="theme-btn-one"
                                                onClick={handleYes}
                                            >
                                                OUI
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withRouter(CancelAppointementModal);
