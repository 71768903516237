/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import { IData, IAvailability } from 'Models';
import { Link } from 'react-router-dom';
import { InterventionSummary } from '../../_actions/summary/summary.actions';
import BackgroundElement from '../BackgroundElement';
import SummaryCard from '../SummaryCard';
import Button from '../Button';

type Props = {
    doctor: IData;
    isSignIn: boolean;
    isUpdated: boolean;
    startUpdated: boolean;
    intervention: InterventionSummary;
    availability: IAvailability;
    handleStartAppoitement(): void;
    handleCancelStartAppoitement(): void;
    hancleCreateAppointement(): void;
};

const SummaryAppointement: FC<Props> = ({
    doctor,
    isSignIn,
    intervention,
    availability,
    isUpdated,
    startUpdated,
    handleCancelStartAppoitement,
    handleStartAppoitement,
    hancleCreateAppointement,
}) => {
    return (
        <>
            <BackgroundElement
                title="Prendre rendez-vous"
                linkTitle="Acceuil"
                subTitle="Prendre rendez-vous"
            />

            <section className="appointment-section bg-color-3">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-8 col-md-12 col-sm-12 left-column">
                            <div className="appointment-information">
                                <div className="title-box">
                                    {isSignIn ? (
                                        <h3>
                                            Informations sur le rendez-vous.
                                        </h3>
                                    ) : (
                                        <h3>
                                            {
                                                "Votre rendez-vous n'est pas encore confirmé."
                                            }
                                        </h3>
                                    )}
                                </div>

                                <div className="inner-box">
                                    {isSignIn ? (
                                        <>
                                            <div>
                                                {
                                                    "En confirmant ce rendez-vous, vous vous engagez à l'honorer. Pensez bien à annuler le plus tôt possible en cas d’imprévu."
                                                }
                                            </div>
                                            <div
                                                className="btn-box"
                                                style={{ marginTop: '10px' }}
                                            >
                                                <span
                                                    role="button"
                                                    className="theme-btn-one"
                                                    onClick={
                                                        hancleCreateAppointement
                                                    }
                                                >
                                                    {startUpdated
                                                        ? 'Modifier le rendez-vous'
                                                        : 'Prendre le rendez-vous'}

                                                    <i className="icon-Arrow-Right"></i>
                                                </span>

                                                <Link
                                                    to="/"
                                                    onClick={
                                                        handleCancelStartAppoitement
                                                    }
                                                >
                                                    <span
                                                        className="theme-btn-two"
                                                        style={{
                                                            marginLeft: '10px',
                                                        }}
                                                    >
                                                        {'Annuler'}
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="btn-box">
                                            <Link
                                                to="/register"
                                                onClick={handleStartAppoitement}
                                            >
                                                <span className="theme-btn-one">
                                                    {
                                                        "S'inscrire ou se connecter"
                                                    }
                                                    <i className="icon-Arrow-Right"></i>
                                                </span>
                                            </Link>
                                            <Link
                                                to="/"
                                                onClick={
                                                    handleCancelStartAppoitement
                                                }
                                            >
                                                <span
                                                    className="theme-btn-two"
                                                    style={{
                                                        marginLeft: '10px',
                                                    }}
                                                >
                                                    {'Annuler'}
                                                </span>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <SummaryCard
                            doctor={doctor}
                            intervention={intervention}
                            availability={availability}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default SummaryAppointement;
