/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

import resources from '../../utils/resources';
import AuthService from '../../services/auth-service';
import useScript from '../../utils/hooks/useScript';

const Header: FC<unknown> = () => {
    const isSignIn = AuthService.isAuthenticated();

    // useScript('/static/js/scrollbar.js');
    // useScript('/static/js/script.js');

    return (
        <>
            <>
                <header className="main-header style-two">
                    <div className="header-lower">
                        <div className="auto-container">
                            <div className="outer-box">
                                <div className="logo-box">
                                    <figure className="logo">
                                        <Link to="/">
                                            <span>
                                                <img
                                                    src={
                                                        resources['ic-loctore']
                                                    }
                                                    alt=""
                                                />
                                            </span>
                                        </Link>
                                    </figure>
                                </div>

                                <div className="menu-area">
                                    <div className="mobile-nav-toggler">
                                        <i className="icon-bar" />
                                        <i className="icon-bar" />
                                        <i className="icon-bar" />
                                    </div>
                                    <nav className="main-menu navbar-expand-md navbar-light">
                                        <div
                                            className="collapse navbar-collapse show clearfix"
                                            id="navbarSupportedContent"
                                        >
                                            <ul className="navigation clearfix">
                                                <li
                                                    className={`${
                                                        _.isEqual(
                                                            window.location
                                                                .pathname,
                                                            '/',
                                                        )
                                                            ? 'current dropdown'
                                                            : 'dropdown'
                                                    }`}
                                                >
                                                    <Link to="/">
                                                        <span>Acceuil</span>
                                                    </Link>
                                                </li>

                                                <li
                                                    className={`${
                                                        _.isEqual(
                                                            window.location
                                                                .pathname,
                                                            '/patient-dashboard',
                                                        )
                                                            ? 'current dropdown'
                                                            : ''
                                                    }`}
                                                >
                                                    <Link to="/patient-dashboard">
                                                        <span>Mon compte</span>
                                                    </Link>
                                                </li>

                                                <li
                                                    className={`${
                                                        _.isEqual(
                                                            window.location
                                                                .pathname,
                                                            '/all-speciality',
                                                        )
                                                            ? 'current dropdown'
                                                            : ''
                                                    }`}
                                                >
                                                    <Link to="/all-speciality">
                                                        <span>Spécialités</span>
                                                    </Link>
                                                </li>

                                                <li className="dropdown">
                                                    <a href="index.html">
                                                        Connexion
                                                    </a>
                                                    <ul>
                                                        <li>
                                                            <Link to="/login">
                                                                <span>
                                                                    Connexion
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/register">
                                                                <span>
                                                                    {
                                                                        "S'inscrire"
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>

                                {/*!isSignIn && (
                                    <div className="btn-box">
                                        <Link to="/register">
                                            <span className="theme-btn-one">
                                                <i className="icon-image" />
                                                {"S'inscrire"}
                                            </span>
                                        </Link>
                                    </div>
                                )*/}
                            </div>
                        </div>
                    </div>

                    {/** sticky Header */}
                    <div className="sticky-header">
                        <div className="auto-container">
                            <div className="outer-box">
                                <div className="logo-box">
                                    <figure className="logo">
                                        <Link to="/">
                                            <span>
                                                <img
                                                    src={
                                                        resources['ic-loctore']
                                                    }
                                                    alt=""
                                                />
                                            </span>
                                        </Link>
                                    </figure>
                                </div>
                                <div className="menu-area">
                                    <nav className="main-menu clearfix" />
                                </div>
                                {/*!isSignIn && (
                                    <div className="btn-box">
                                        <Link to="/register">
                                            <span className="theme-btn-one">
                                                <i className="icon-image" />
                                                {" S'inscrire"}
                                            </span>
                                        </Link>
                                    </div>
                                )*/}
                            </div>
                        </div>
                    </div>
                </header>
                <div className="mobile-menu">
                    <div className="menu-backdrop" />
                    <div className="close-btn">
                        <i className="fas fa-times" />
                    </div>

                    <nav className="menu-box">
                        <div className="nav-logo">
                            <a href="index.html">
                                <img src={resources['ic-loctore']} alt="" />
                            </a>
                        </div>
                        <div className="menu-outer" />
                        <div className="contact-info">
                            <h4>Contact Info</h4>
                            <ul>
                                <li>
                                    {' '}
                                    45 rue Eugène Derrien, 94400 Vitry sur Seine
                                    FRANCE
                                </li>
                                <li>
                                    <a href="tel:+33619645438">
                                        +33 6 19 64 54 38
                                    </a>
                                </li>
                                <li>
                                    <a href="support@loctore.com">
                                        contact@loctore.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="social-links">
                            <ul className="clearfix">
                                <li>
                                    <a href="index.html">
                                        <span className="fab fa-twitter" />
                                    </a>
                                </li>
                                <li>
                                    <a href="index.html">
                                        <span className="fab fa-facebook-square" />
                                    </a>
                                </li>

                                <li>
                                    <a href="index.html">
                                        <span className="fab fa-instagram" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </>
        </>
    );
};

export default Header;
