import React, { FC, useEffect, useState } from 'react';
import { RootState } from 'Types';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import AppointmentDetails from '../../containers/AppointmentDetails';
import withLoader from '../../shared/hoc/withLoader';
import {
    getAppointementDetailsAsync,
    cancelAppointementAsync,
    startUpdatedAppointement,
} from '../../_actions/apointement/appointement.actions';
import WithModal from '../../utils/hooks/WithModal';
import CancelAppointementModal from '../../components/AppointementDetailCard/Modal';

const WrappedAppointmentDetails = withLoader()(AppointmentDetails);
const WrappedModal = WithModal()(CancelAppointementModal);

const AppointmentDetailsScreen: FC<unknown> = () => {
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState<boolean>(false);

    const { loading, appointementDetails, isCanceled } = useSelector(
        (state: RootState) => state.appointementReducer,
    );
    const url = `${window.location.pathname}`;
    const appointementId = url.split('/').pop();
    useEffect(() => {
        dispatch(
            getAppointementDetailsAsync.request({
                appointementId: String(appointementId),
            }),
        );
        dispatch(startUpdatedAppointement(false));
    }, []);

    if (isCanceled) return <Redirect to="/patient-dashboard" />;

    const handleCancelAppointement = () => {
        setOpen(true);
    };

    const handleNon = () => {
        setOpen(false);
    };

    const handleYes = () => {
        dispatch(
            cancelAppointementAsync.request({
                appointementId: String(appointementId),
            }),
        );
    };

    const handleUpdateAppointement = () => {
        dispatch(startUpdatedAppointement(true));
    };

    return (
        <>
            <WrappedAppointmentDetails
                loading={loading}
                appointementDetails={appointementDetails}
                handleCancelAppointement={handleCancelAppointement}
                handleUpdateAppointement={handleUpdateAppointement}
            />

            <WrappedModal
                open={isOpen}
                handleNon={handleNon}
                handleYes={handleYes}
            />
        </>
    );
};

export default AppointmentDetailsScreen;
