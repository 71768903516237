/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC } from 'react';

type Props = {
    startUpdated: boolean;
};
const AppointementHeader: FC<Props> = ({ startUpdated }) => {
    return (
        <div className="form-title">
            {_.isEqual(startUpdated, false) ? (
                <h3>Prendre rendez-vous</h3>
            ) : (
                <h3>Modifier le rendez-vous</h3>
            )}
        </div>
    );
};

export default AppointementHeader;
