/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import {
    createAsyncAction,
    isActionOf,
    ActionType,
    createStandardAction,
} from 'typesafe-actions';
import { RootAction, RootState, Services } from 'Types';
import {
    IRegisterResponse,
    IRegisterRequest,
    ILoginRequest,
    ILoginResponse,
    IResetPasswordRequest,
    IResetPasswordResponse,
} from 'Models';

import {
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILURE,
    FETCH_LOGIN_REQUEST,
    FETCH_LOGIN_SUCCESS,
    FETCH_LOGIN_FAILURE,
    RESET_USER_FEEDBACK,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
} from '../actionTypes';

const registerAsync = createAsyncAction(
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILURE,
)<IRegisterRequest, IRegisterResponse, any>();

const fetchLoginAsync = createAsyncAction(
    FETCH_LOGIN_REQUEST,
    FETCH_LOGIN_SUCCESS,
    FETCH_LOGIN_FAILURE,
)<ILoginRequest, ILoginResponse, any>();

const resetPasswordAsync = createAsyncAction(
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
)<IResetPasswordRequest, IResetPasswordResponse, any>();

const logoutAsync = createAsyncAction(
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
)<any, boolean, any>();

const resetUserFeedback = createStandardAction(RESET_USER_FEEDBACK)();

export type AuthAction =
    | ActionType<typeof registerAsync>
    | ActionType<typeof fetchLoginAsync>
    | ActionType<typeof resetUserFeedback>
    | ActionType<typeof resetPasswordAsync>
    | ActionType<typeof logoutAsync>;

const preparePayloadRegister = ({
    email,
    password,
    card_attributes,
}: IRegisterRequest) => {
    return {
        email,
        password,
        card_attributes,
    };
};

const preparePayloadLogin = ({ email, password }: ILoginRequest) => {
    return {
        email,
        password,
    };
};

const preparePayloadResetPassword = ({ email }: IResetPasswordRequest) => {
    return {
        email,
    };
};

const mapPostLogin = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadLogin(action.payload);
    return apiRequest<ILoginResponse>({
        path: '/auth/sign_in',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: ILoginResponse) => {
            if (response) {
                return of(fetchLoginAsync.success({ data: response.data }));
            }
            return of(fetchLoginAsync.failure(response));
        }),
        catchError((error) => {
            return of(fetchLoginAsync.failure(error));
        }),
    );
};

const mapPostRegister = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadRegister(action.payload);
    return apiRequest<IRegisterResponse>({
        path: '/auth',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: IRegisterResponse) => {
            if (response && _.isEqual(response.status, 'success')) {
                localStorage.setItem('userId', response.data.id);
                return of(registerAsync.success(response));
            }
            return of(registerAsync.failure(response));
        }),
        catchError((error) => {
            return of(registerAsync.failure(error));
        }),
    );
};

const mapPostResetPassword = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadResetPassword(action.payload);
    return apiRequest<IResetPasswordResponse>({
        path: '/auth/password',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: IResetPasswordResponse) => {
            if (response && _.isEqual(response.success, true)) {
                return of(resetPasswordAsync.success(response));
            }
            return of(resetPasswordAsync.failure(response));
        }),
        catchError((error) => {
            return of(resetPasswordAsync.failure(error));
        }),
    );
};

const mapLogout = (action: RootAction, { apiRequest }: Services) => {
    return apiRequest<boolean>({
        path: '/auth/sign_out',
        method: 'delete',
        body: '',
    }).pipe(
        mergeMap((response: boolean) => {
            console.log('names success: ', response);
            if (response) {
                return of(logoutAsync.success(response));
            }
            console.log('names failure: ', response);
            return of(logoutAsync.failure(response));
        }),
        catchError((error) => {
            console.log('names failure error: ', error);
            return of(logoutAsync.failure(error));
        }),
    );
};

const registerEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(registerAsync.request)),
        mergeMap((action) => mapPostRegister(action, dependency)),
    );

const fetchLoginEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchLoginAsync.request)),
        mergeMap((action) => mapPostLogin(action, dependency)),
    );

const resetPasswordEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(resetPasswordAsync.request)),
        mergeMap((action) => mapPostResetPassword(action, dependency)),
    );

const logoutEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(logoutAsync.request)),
        mergeMap((action) => mapLogout(action, dependency)),
    );

export {
    registerAsync,
    fetchLoginAsync,
    resetPasswordAsync,
    registerEpic,
    fetchLoginEpic,
    resetUserFeedback,
    resetPasswordEpic,
    logoutEpic,
    logoutAsync,
};
