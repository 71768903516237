/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
/* eslint no-restricted-syntax: ["error", "FunctionExpression", "WithStatement", "BinaryExpression[operator='in']"] */
import _ from 'lodash';
import { ISpeciality, IAutocompletionResource } from 'Models';
import moment from 'moment';
import 'moment/locale/fr';
import langage from './language.json';
import documentType from './documentType.json';

const isValidEmail = (value: string): boolean => {
    if (_.isEmpty(value)) {
        return false;
    }
    const regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    return regex.test(String(value).toLowerCase());
};

const covertToSpecialityItem = (speciality: ISpeciality[]) => {
    const newSpeciality: any = [];
    if (!_.isEmpty(speciality) && typeof speciality !== 'undefined') {
        speciality.map((specialityItem: ISpeciality) => {
            const spec = {
                id: specialityItem.id,
                label: specialityItem.attributes.name,
            };
            newSpeciality.push(spec);
        });
    }
    return newSpeciality;
};

const mergeArray = (res: IAutocompletionResource) => {
    const mergedArray = [...res.calendars, ...res.groups, ...res.specialities];
    const newResource: any = [];
    mergedArray.map((item) => {
        const completion = {
            id: _.get(item, 'data.id'),
            type: _.get(item, 'data.type'),
            name: _.get(item, 'data.attributes.name'),
            photo_url: _.get(item, 'data.attributes.doctor.photo.small_url'),
            speciality: _.get(item, 'data.attributes.speciality'),
        };
        newResource.push(completion);
    });

    return newResource;
};

const consvertPacePredictions = (placePredictions: any[]) => {
    const newPlace: any = [];
    placePredictions.map((item) => {
        const place = {
            id: _.get(item, 'place_id'),
            name: _.get(item, 'description'),
        };
        newPlace.push(place);
    });
    return newPlace;
};

const converteDateToTime = (date: Date) => {
    return moment.utc(new Date(date)).format('HH:mm');
};

const converteDate = (date: Date) => {
    return moment(date).format('DD-MM-YYYY');
};
const converteToFormatLL = (date: Date) => {
    return moment(date).format('ll');
};
const formatStatus = (status: string): string => {
    let str: string = '';
    switch (status) {
        case 'validated':
            str = 'Valider';
            break;
        case 'canceled':
            str = 'Annuler';
            break;
        default:
    }
    return str;
};

const formatPaymentMeans = (payment: string): string => {
    let str: string = '';
    switch (payment) {
        case 'cash':
            str = 'Espèces';
            break;
        case 'mobile_money':
            str = 'Paiement mobile';
            break;
        case 'cb':
            str = 'Paiement par carte';
            break;
        case 'bank_check':
            str = 'Paiement par chèque';
            break;
        default:
    }
    return str;
};

const isAfter = (date: Date): boolean => {
    return moment(date).isAfter(moment(new Date()));
};

const formatLangage = (lang: string) => {
    const stringify = JSON.stringify(langage);
    const jsonParsedArray = JSON.parse(stringify);
    return jsonParsedArray[lang];
};

const formatDocumentType = (docType: string): string => {
    const doc = documentType.filter((item: any) => item.key === docType);
    return doc[0].name;
};

const formatLabel = (label: string): string => {
    let str: string = '';
    switch (label) {
        case 'resource':
            str = 'MEDECIN';
            break;
        case 'group':
            str = 'ETABLISSEMENT';
            break;
        case 'speciality':
            str = 'SPECIALITE';
            break;
        default:
    }
    return str;
};

export {
    isValidEmail,
    covertToSpecialityItem,
    converteDateToTime,
    converteDate,
    converteToFormatLL,
    formatStatus,
    isAfter,
    formatPaymentMeans,
    formatLangage,
    formatDocumentType,
    mergeArray,
    formatLabel,
    consvertPacePredictions,
};
