/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC } from 'react';
import { IData } from 'Models';
import { formatPaymentMeans, formatLangage } from '../../../../utils/helpers';

type Props = {
    resourcesDetail: IData;
};

const OverviewBox: FC<Props> = ({ resourcesDetail }) => {
    const isTrainings =
        typeof resourcesDetail.attributes.doctor.trainings !== 'undefined' &&
        !_.isEmpty(resourcesDetail.attributes.doctor.trainings);

    const isPaymentMeans =
        typeof resourcesDetail.attributes.doctor.payment_means !==
            'undefined' &&
        !_.isEmpty(resourcesDetail.attributes.doctor.payment_means);

    const isLanguageList =
        typeof resourcesDetail.attributes.doctor.language_list !==
            'undefined' &&
        !_.isEmpty(resourcesDetail.attributes.doctor.language_list);

    return (
        <div className="tab active-tab" id="tab-1">
            <div className="inner-box">
                <div className="text">
                    <h3>{resourcesDetail.attributes.name}</h3>
                    <p>{resourcesDetail.attributes.doctor.biography}</p>
                    <h3>Spécialité</h3>
                    <p>{resourcesDetail.attributes.speciality}</p>

                    {!!isTrainings && (
                        <div>
                            <h3>Formation</h3>
                            <ul className="list clearfix">
                                {resourcesDetail.attributes.doctor.trainings!.map(
                                    (item, index) => {
                                        return (
                                            <li key={String(index)}>
                                                {item.year}{' '}
                                                <span>
                                                    - {item.description}
                                                </span>
                                            </li>
                                        );
                                    },
                                )}
                            </ul>
                        </div>
                    )}

                    {!!isPaymentMeans && (
                        <div>
                            <h3>Moyens de paiement</h3>
                            <ul className="list clearfix">
                                {resourcesDetail.attributes.doctor.payment_means!.map(
                                    (item, index) => {
                                        return (
                                            <li key={String(index)}>
                                                {formatPaymentMeans(item)}{' '}
                                            </li>
                                        );
                                    },
                                )}
                            </ul>
                        </div>
                    )}
                    {!!isLanguageList && (
                        <div>
                            <h3>Langues</h3>
                            <ul className="list clearfix">
                                {resourcesDetail.attributes.doctor.language_list!.map(
                                    (item, index) => {
                                        return (
                                            <li key={String(index)}>
                                                {formatLangage(
                                                    item,
                                                ).toUpperCase()}{' '}
                                            </li>
                                        );
                                    },
                                )}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    ); //
};

export default OverviewBox;
