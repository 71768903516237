import React, { FC } from 'react';

import { InputEvent } from 'Types';
import resources from '../../utils/resources';
import Input from '../../components/Input';
import Button from '../../components/Button';
import BackgroundElement from '../../components/BackgroundElement';

type Props = {
    fields: any;
    errors: any;
    handleInputChange(e: InputEvent): void;
    handleOnClick(e: React.FormEvent<HTMLFormElement>): void;
};

const ForgotPassowrd: FC<Props> = ({
    fields,
    errors,
    handleInputChange,
    handleOnClick,
}) => {
    return (
        <>
            <BackgroundElement
                title="Mot de passe oublié"
                linkTitle="Se connecter"
                subTitle="Mot de passe oublié"
                linkPath="/login"
            />

            <section className="registration-section bg-color-3">
                <div className="pattern">
                    <div
                        className="pattern-1"
                        style={{
                            backgroundImage: `url(${resources['ic-shap-85']})`,
                        }}
                    />
                    <div
                        className="pattern-2"
                        style={{
                            backgroundImage: `url(${resources['ic-shap-86']})`,
                        }}
                    />
                </div>
                <div className="auto-container">
                    <div className="inner-box">
                        <div className="content-box">
                            <div className="title-box">
                                <h3>Mot de passe oublié</h3>
                            </div>
                            <div className="inner">
                                <form
                                    className="registration-form"
                                    autoComplete="off"
                                >
                                    <div className="row clearfix">
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <Input
                                                type="email"
                                                name="email"
                                                label="Email"
                                                placeholder="Entrer votre Email"
                                                required
                                                htmlFor="validationCustom03"
                                                onChange={handleInputChange}
                                                value={fields.email}
                                                error={errors.email}
                                                className=""
                                                disabled={false}
                                                autocomplete=""
                                                onClick={() => {}}
                                                checked={false}
                                                id="email"
                                            />
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn">
                                            <Button
                                                className="theme-btn-one"
                                                onClick={handleOnClick}
                                            >
                                                Envoyer
                                                <i className="icon-Arrow-Right" />
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ForgotPassowrd;
