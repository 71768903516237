import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import resources from '../../utils/resources';

type Props = {
    title: string;
    linkTitle: string;
    subTitle: string;
    linkPath?: string;
};

const BackgroundElement: FC<Props> = ({
    title,
    linkTitle,
    subTitle,
    linkPath,
}) => {
    return (
        <section className="page-title-two">
            <div className="title-box centred bg-color-2">
                <div className="pattern-layer">
                    <div
                        className="pattern-1"
                        style={{
                            backgroundImage: `url(${resources['ic-shap-70']})`,
                        }}
                    />
                    <div
                        className="pattern-2"
                        style={{
                            backgroundImage: `url(${resources['ic-shap-71']})`,
                        }}
                    />
                </div>
                <div className="auto-container">
                    <div className="title">
                        <h1>{title}</h1>
                    </div>
                </div>
            </div>
            <div className="lower-content">
                <div className="auto-container">
                    <ul className="bread-crumb clearfix">
                        <li>
                            <Link to={String(linkPath)}>
                                <span>{linkTitle}</span>
                            </Link>
                        </li>
                        <li>{subTitle}</li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

BackgroundElement.defaultProps = {
    linkPath: '',
};

export default BackgroundElement;
