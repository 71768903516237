/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import * as authActions from '../../_actions/auth/auth.actions';

export type AuthState = Readonly<{
    loading: boolean;
    error: '';
    status: string;
    data?: {};
    success: boolean | string;
    message: string;
}>;

export const initialState: AuthState = {
    loading: false,
    error: '',
    status: '',
    data: {},
    success: false,
    message: '',
};

type Actions = authActions.AuthAction;

const authReducer: Reducer<AuthState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(authActions.resetPasswordAsync.request):
        case getType(authActions.registerAsync.request):
        case getType(authActions.fetchLoginAsync.request): {
            return {
                ...state,
                loading: true,
                error: '',
            };
        }

        case getType(authActions.registerAsync.success): {
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                status: action.payload.status,
            };
        }
        case getType(authActions.fetchLoginAsync.success): {
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        }

        case getType(authActions.registerAsync.failure): {
            return {
                ...state,
                loading: false,
                error: _.get(
                    action.payload,
                    'errors[0].errors.full_messages[0]',
                ),
                status: _.get(action.payload, 'errors[0].status'),
            };
        }

        case getType(authActions.resetPasswordAsync.success): {
            return {
                ...state,
                loading: false,
                success: action.payload.success,
                message: action.payload.message,
            };
        }
        case getType(authActions.fetchLoginAsync.failure): {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }

        case getType(authActions.resetPasswordAsync.failure): {
            return {
                ...state,
                loading: false,
                success: _.get(action.payload, 'errors[0].success'),
                error: _.get(action.payload, 'errors[0].errors[0]'),
            };
        }
        case getType(authActions.resetUserFeedback): {
            return {
                ...state,
                loading: false,
                error: '',
                status: undefined,
                success: false,
                message: '',
            };
        }

        case getType(authActions.logoutAsync.success): {
            return {
                ...state,
                loading: false,
                error: '',
                status: undefined,
                success: false,
                message: '',
            };
        }
        default:
            return { ...state };
    }
};
export default authReducer;
