import React, { FC } from 'react';
import { IInterventionData } from 'Models';

type Props = {
    label: string;
    value: any | IInterventionData;
    type: string;
    error: string;
    onChange: any;
    placeholder: string;
    disabled: boolean;
    required: boolean;
    name: string;
    className: string;
    id: string;
    htmlFor: string;
    checked: boolean;
    autocomplete: string;
    onClick: any;
};
/* eslint-disable react/prop-types */
const Input: FC<Props> = ({
    onChange,
    type,
    value,
    placeholder,
    disabled,
    required,
    name,
    label,
    className,
    id,
    htmlFor,
    error,
    checked,
    autocomplete,
    onClick,
}) => {
    return (
        <>
            <label htmlFor={htmlFor}>{label}</label>
            <input
                id={id}
                onChange={onChange}
                type={type}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                required={required}
                name={name}
                className={className}
                checked={checked}
                autoComplete={autocomplete}
                onClick={onClick}
            />
            {error && error !== '' && <p className="input__error">{error}</p>}
        </>
    );
};

export default Input;
/* eslint-enable react/prop-types */
