/* eslint-disable import/no-extraneous-dependencies */
import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import { createAsyncAction, isActionOf, ActionType } from 'typesafe-actions';
import { RootAction, RootState, Services } from 'Types';

import { IRootAvailability } from 'Models';

import {
    GET_AVAILABILITIES_REQUEST,
    GET_AVAILABILITIES_SUCCESS,
    GET_AVAILABILITIES_FAILURE,
} from '../actionTypes';

interface IPayload {
    resourcesId: string;
    interventionId: string;
    startsAt: string;
}
const getAvailabilitiesAsync = createAsyncAction(
    GET_AVAILABILITIES_REQUEST,
    GET_AVAILABILITIES_SUCCESS,
    GET_AVAILABILITIES_FAILURE,
)<IPayload, IRootAvailability, any>();

export type AvailabilitiesAction = ActionType<typeof getAvailabilitiesAsync>;

const preparePayloadAvailabilities = ({
    resourcesId,
    interventionId,
    startsAt,
}: IPayload) => {
    return {
        resourcesId,
        interventionId,
        startsAt,
    };
};

const mapGetAvailabilities = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadAvailabilities(action.payload);
    return apiRequest<IRootAvailability>({
        path: `${'/resources/'}${
            payload.resourcesId
        }/availabilities?intervention_id=${payload.interventionId}&starts_at=${
            payload.startsAt
        }&length=${1}`,
        method: 'get',
        body: '',
    }).pipe(
        mergeMap((response: IRootAvailability) => {
            if (response) {
                return of(getAvailabilitiesAsync.success(response));
            }
            return of(getAvailabilitiesAsync.failure(response));
        }),
        catchError((error) => {
            return of(getAvailabilitiesAsync.failure(error));
        }),
    );
};

const availabilitiesEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(getAvailabilitiesAsync.request)),
        mergeMap((action) => mapGetAvailabilities(action, dependency)),
    );

export { getAvailabilitiesAsync, availabilitiesEpic };
