/* eslint-disable import/no-extraneous-dependencies */
import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import { createAsyncAction, isActionOf, ActionType } from 'typesafe-actions';

import { RootAction, RootState, Services } from 'Types';
import { ISpecialityResponse } from 'Models';
import {
    GET_SPECIALITY_REQUEST,
    GET_SPECIALITY_SUCCESS,
    GET_SPECIALITY_FAILURE,
} from '../actionTypes';

const getSpecialityAsync = createAsyncAction(
    GET_SPECIALITY_REQUEST,
    GET_SPECIALITY_SUCCESS,
    GET_SPECIALITY_FAILURE,
)<any, ISpecialityResponse, any>();

export type SpecialityAction = ActionType<typeof getSpecialityAsync>;

const mapGetSpeciality = (action: RootAction, { apiRequest }: Services) => {
    return apiRequest<ISpecialityResponse>({
        path: '/specialities',
        method: 'get',
        body: '',
    }).pipe(
        mergeMap((response: ISpecialityResponse) => {
            if (response) {
                return of(getSpecialityAsync.success(response));
            }
            return of(getSpecialityAsync.failure(response));
        }),
        catchError((error) => {
            return of(getSpecialityAsync.failure(error));
        }),
    );
};
const getSpecialityAsyncEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(getSpecialityAsync.request)),
        mergeMap((action) => mapGetSpeciality(action, dependency)),
    );

export { getSpecialityAsync, getSpecialityAsyncEpic };
