import React, { FC } from 'react';
import GoogleMapReact, { BootstrapURLKeys, MapOptions } from 'google-map-react';
import BackgroundElement from '../BackgroundElement';
import Footer from '../Footer';
import useScript from '../../utils/hooks/useScript';
import resources from '../../utils/resources';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.Adresse;

const center = { lat: 48.773069, lng: 2.37639 };

const key: BootstrapURLKeys = {
    key: 'AIzaSyDcjWR70s26ClmKvcXyYLtKHBnvHgzpk7k',
    libraries: 'places',
    language: 'FR',
    region: 'FR',
};
const client: BootstrapURLKeys = {
    client: '',
    v: '3.28',
    language: 'FR',
    libraries: 'places',
    region: 'FR',
    id: 'custom-id',
};
const AnyReactComponent = ({ text }: any) => <div>{text}</div>;
const options: MapOptions = {
    zoomControl: false,
    gestureHandling: 'cooperative',
    /*styles: [
        {
            featureType: 'administrative',
            elementType: 'all',
            stylers: [{ saturation: '-100' }],
        },
        {
            featureType: 'administrative.neighborhood',
            stylers: [{ visibility: 'off' }],
        },
        {
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#242f3e' }],
        },
        {
            stylers: [{ color: '#fcfffd' }],
        },
    ],*/
};

const ContactContainer: FC<unknown> = () => {
    useScript('/static/js/jquery.js');
    useScript('/static/js/jquery-ui.js');
    useScript('https://code.jquery.com/jquery-3.6.0.slim.min.js');
    useScript('/static/js/bootstrap.min.js');
    useScript('/static/js/wow.js');
    useScript('/static/js/popper.min.js');
    useScript('/static/js/owl.js');
    useScript('/static/js/validation.js');
    useScript('/static/js/jquery.fancybox.js');
    useScript('/static/js/appear.js');
    useScript('/static/js/scrollbar.js');
    useScript('/static/js/tilt.jquery.js');
    useScript('/static/js/jquery.paroller.min.js');
    useScript('/static/js/product-filter.js');
    useScript('/static/js/valideFrom.js');
    useScript('/static/js/gmaps.js');
    useScript('/static/js/jquery.nice-select.min.js');
    useScript('/static/js/map-helper.js');
    useScript('/static/js/script.js');
    return (
        <>
            <BackgroundElement
                title="Nous contacter"
                linkTitle="Acceuil"
                subTitle="Nous contacter"
                linkPath="/"
            />
            <section className="information-section sec-pad centred bg-color-3">
                <div className="pattern-layer">
                    <div
                        className="pattern-1"
                        style={{
                            backgroundImage: `url(${resources['ic-shap-88']})`,
                        }}
                    ></div>
                    <div
                        className="pattern-2"
                        style={{
                            backgroundImage: `url(${resources['ic-shap-89']})`,
                        }}
                    ></div>
                </div>
                <div className="auto-container">
                    <div className="sec-title centred">
                        <p>Information</p>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-4 col-md-6 col-sm-12 information-column">
                            <div
                                className="single-information-block wow fadeInUp animated animated"
                                data-wow-delay="00ms"
                                data-wow-duration="1500ms"
                            >
                                <div className="inner-box">
                                    <div
                                        className="pattern"
                                        style={{
                                            backgroundImage: `url(${resources['ic-shap-87']})`,
                                        }}
                                    ></div>
                                    <figure className="icon-box">
                                        <img
                                            src={resources['ic-icon-20']}
                                            alt=""
                                        />
                                    </figure>
                                    <h3>Adresse e-mail</h3>
                                    <p>
                                        <a href="mailto:contact@loctore.com ">
                                            {Wording.email}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {
                            <div className="col-lg-4 col-md-6 col-sm-12 information-column">
                                <div
                                    className="single-information-block wow fadeInUp animated animated"
                                    data-wow-delay="300ms"
                                    data-wow-duration="1500ms"
                                >
                                    <div className="inner-box">
                                        <div
                                            className="pattern"
                                            style={{
                                                backgroundImage: `url(${resources['ic-shap-87']})`,
                                            }}
                                        ></div>
                                        <figure className="icon-box">
                                            <img
                                                src={resources['ic-icon-21']}
                                                alt=""
                                            />
                                        </figure>
                                        <h3>Numéro de téléphoner</h3>
                                        <p>
                                            <a href="tel:+336196454388">
                                                {Wording.tel}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-lg-4 col-md-6 col-sm-12 information-column">
                            <div
                                className="single-information-block wow fadeInUp animated animated"
                                data-wow-delay="600ms"
                                data-wow-duration="1500ms"
                            >
                                <div className="inner-box">
                                    <div
                                        className="pattern"
                                        style={{
                                            backgroundImage: `url(${resources['ic-shap-87']})`,
                                        }}
                                    ></div>
                                    <figure className="icon-box">
                                        <img
                                            src={resources['ic-icon-22']}
                                            alt=""
                                        />
                                    </figure>
                                    <h3>Adresse de bureau</h3>
                                    <p>{Wording.city}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="contact-section">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="col-lg-6 col-md-12 col-sm-12 form-column">
                                <div className="form-inner">
                                    <div className="sec-title">
                                        <p>Contact</p>
                                        <h2>Nous contacter</h2>
                                    </div>
                                    <form
                                        method="post"
                                        action="sendemail.php"
                                        id="contact-form"
                                        className="default-form"
                                    >
                                        <div className="row clearfix">
                                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input
                                                    type="text"
                                                    name="username"
                                                    placeholder="Votre nom"
                                                    required
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email"
                                                    required
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    required
                                                    placeholder="Téléphone"
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                                <input
                                                    type="text"
                                                    name="subject"
                                                    required
                                                    placeholder="Sujet"
                                                />
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                                <textarea
                                                    name="message"
                                                    placeholder="Votre message ..."
                                                ></textarea>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn">
                                                <button
                                                    className="theme-btn-one"
                                                    type="submit"
                                                    name="submit-form"
                                                >
                                                    Envoyer le message
                                                    <i className="icon-Arrow-Right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 map-column">
                                <div className="map-inner">
                                    <div
                                        className="pattern"
                                        style={{
                                            backgroundImage: `url(${resources['ic-shap-90']})`,
                                        }}
                                    ></div>
                                    <div
                                        className="google-map"
                                        id="contact-google-map"
                                    >
                                        <GoogleMapReact
                                            center={center}
                                            zoom={11}
                                            bootstrapURLKeys={key}
                                            options={options}
                                            yesIWantToUseGoogleMapApiInternals={Boolean(
                                                true,
                                            )}
                                        >
                                            <AnyReactComponent
                                                lat={48.773069}
                                                lng={2.37639}
                                                text="Loctoré"
                                            />
                                        </GoogleMapReact>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <Footer />
        </>
    );
};

export default ContactContainer;
