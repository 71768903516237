/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

type Props = {
    id: string;
    name: string;
    speciality: string;
    imgUrl: string;
    favorited: boolean;
    handleAddToFavorite(id: string, favorited: boolean): void;
};

const GridDoctorItem: FC<Props> = ({
    id,
    name,
    speciality,
    imgUrl,
    favorited,
    handleAddToFavorite,
}) => {
    return (
        <div className="col-lg-6 col-md-6 col-sm-12 team-block">
            <div className="team-block-three">
                <div className="inner-box">
                    <figure className="image-box">
                        <img src={imgUrl} alt="" />
                        <a
                            href="#/"
                            role="button"
                            onClick={() => handleAddToFavorite(id, favorited)}>
                            {_.isEqual(favorited, true) ? (
                                <i
                                    className="fa fa-heart"
                                    aria-hidden="true"></i>
                            ) : (
                                <i className="far fa-heart"></i>
                            )}
                        </a>
                    </figure>
                    <div className="lower-content">
                        <ul className="name-box clearfix">
                            <li className="name">
                                <h3>
                                    <span>{name}</span>
                                </h3>
                            </li>
                            <li>
                                <i className="icon-Trust-1" />
                            </li>
                            <li>
                                <i className="icon-Trust-2" />
                            </li>
                        </ul>
                        <span className="designation">{speciality}</span>

                        <div className="lower-box clearfix">
                            <Link to={`/doctors-details/${id}`}>
                                <span>Voir</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GridDoctorItem;
