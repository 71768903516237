import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { IUserData } from 'Models';
import * as profilAction from '../../_actions/profil/profil.actions';
import * as authActions from '../../_actions/auth/auth.actions';

import { initialUserProfilData } from '../../utils/initialState';

export type ProfilState = Readonly<{
    loading: boolean;
    data: IUserData;
    error: string;
    isReset: boolean;
}>;

export const initialState: ProfilState = {
    loading: false,
    data: {
        ...initialUserProfilData,
    },
    error: '',
    isReset: false,
};

type Actions = profilAction.ProfilAction | authActions.AuthAction;

const userProfilReducer: Reducer<ProfilState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(profilAction.resetPasswordAsync.request):
        case getType(profilAction.updateUserProfilAsync.request):
        case getType(profilAction.getUserProfilAsync.request): {
            return {
                ...state,
                loading: true,
                error: '',
                isReset: false,
            };
        }

        case getType(profilAction.updateUserProfilAsync.success):
        case getType(profilAction.getUserProfilAsync.success): {
            return {
                ...state,
                loading: false,
                data: {
                    id: action.payload.data.id,
                    type: action.payload.data.type,
                    attributes: action.payload.data.attributes,
                },
                isReset: false,
            };
        }

        case getType(profilAction.resetPasswordAsync.failure):
        case getType(profilAction.updateUserProfilAsync.failure):
        case getType(profilAction.getUserProfilAsync.failure): {
            return {
                ...state,
                loading: false,
                error: action.payload,
                isReset: false,
            };
        }

        case getType(profilAction.resetPasswordAsync.success): {
            return {
                ...state,
                loading: false,
                isReset: true,
            };
        }

        case getType(authActions.logoutAsync.success): {
            return {
                ...state,
                loading: false,
                data: {
                    ...initialUserProfilData,
                },
                error: '',
                isReset: false,
            };
        }

        default:
            return { ...state };
    }
};
export default userProfilReducer;
