/* eslint-disable import/no-extraneous-dependencies */
import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import { createAsyncAction, isActionOf, ActionType } from 'typesafe-actions';
import { RootAction, RootState, Services } from 'Types';

import { IInterventions } from 'Models';

import {
    GET_INTERVENTIONS_REQUEST,
    GET_INTERVENTIONS_SUCCESS,
    GET_INTERVENTIONS_FAILURE,
} from '../actionTypes';

interface IPayload {
    id: string;
    type: string;
}
const getInterventionAsync = createAsyncAction(
    GET_INTERVENTIONS_REQUEST,
    GET_INTERVENTIONS_SUCCESS,
    GET_INTERVENTIONS_FAILURE,
)<IPayload, IInterventions, any>();

export type InterventionAction = ActionType<typeof getInterventionAsync>;

const preparePayloadInterventions = ({ id, type }: IPayload) => {
    return {
        id,
        type,
    };
};

const mapPostInterventions = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadInterventions(action.payload);
    return apiRequest<IInterventions>({
        path: `${'/resources/'}${payload.id}/interventions/${payload.type}`,
        method: 'get',
        body: '',
    }).pipe(
        mergeMap((response: IInterventions) => {
            if (response) {
                return of(getInterventionAsync.success(response));
            }
            return of(getInterventionAsync.failure(response));
        }),
        catchError((error) => {
            return of(getInterventionAsync.failure(error));
        }),
    );
};

const interventionsEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(getInterventionAsync.request)),
        mergeMap((action) => mapPostInterventions(action, dependency)),
    );

export { getInterventionAsync, interventionsEpic };
