/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { RootState, InputChangeEvent } from 'Types';
import { useDispatch, useSelector } from 'react-redux';
import { IAvailability } from 'Models';

import DoctorsDetails from '../../containers/DoctorsDetails';
import { getResourcesDetailAsync } from '../../_actions/resources/resources.actions';
import { getInterventionAsync } from '../../_actions/interventions/interventions.actions';
import { getAvailabilitiesAsync } from '../../_actions/availabilities/availabilities.actions';
import {
    setDoctorSummary,
    setInterventionSummary,
    setAvailabilitiesSummary,
} from '../../_actions/summary/summary.actions';
import withLoader from '../../shared/hoc/withLoader';
import useScript from '../../utils/hooks/useScript';

const today = new Date();
const WrappedDoctorsDetails = withLoader()(DoctorsDetails);

type DProps = {
    year: number;
    month: number;
    day: number;
};

const defaultValue = {
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate(),
};

const DoctorsDetailsScreen: FC<unknown> = () => {
    const dispatch = useDispatch();

    useScript('/static/js/jquery.nice-select.min.js');
    useScript('/static/js/script.js');

    const { data } = useSelector(
        (state: RootState) => state.resourcesReducer.detail,
    );
    const { loading } = useSelector(
        (state: RootState) => state.resourcesReducer,
    );

    const { interventions } = useSelector(
        (state: RootState) => state.interventionsReducer,
    );

    const { availabilities } = useSelector(
        (state: RootState) => state.availabilitiesReducer,
    );

    const { isUpdated, appointementDetails, startUpdated } = useSelector(
        (state: RootState) => state.appointementReducer,
    );

    const [isCabinetConsultation, setIsCabinetConsultation] =
        useState<boolean>(false);
    const [isVideoConsultation, setIsVideoConsultation] =
        useState<boolean>(false);

    const [selectedReason, setSelectedReason] = useState<string>('');
    const [showDatepicker, setShowDatepicker] = useState<boolean>(false);
    const [isTimeSelected, setIsTimeSelected] = useState<boolean>(false);
    const [selectedDay, setSelectedDay] = useState(defaultValue);

    const url = `${window.location.pathname}`;
    const resourceId = url.split('/').pop();

    useEffect(
        () => {
            dispatch(
                getResourcesDetailAsync.request({
                    id: String(resourceId),
                }),
            );
            if (startUpdated) {
                dispatch(
                    getAvailabilitiesAsync.request({
                        resourcesId: data.id,
                        interventionId: appointementDetails.included[0].id,
                        startsAt: `${selectedDay.day}/${selectedDay.month}/${selectedDay.year}`,
                    }),
                );
            }
        },
        [
            /*resourceId,
        dispatch,
        appointementDetails.included,
        data.id,
        isUpdated,
        startUpdated,
        selectedDay.day,
        selectedDay.month,
        selectedDay.year,*/
        ],
    );

    const handleChecked = (e: InputChangeEvent) => {
        if (_.isEqual(e.target.id, 'offices')) {
            setIsCabinetConsultation(e.target.checked);
            setIsVideoConsultation(false);
            setSelectedReason('');
            setSelectedDay(defaultValue);
        } else {
            setIsVideoConsultation(e.target.checked);
            setIsCabinetConsultation(false);
            setSelectedReason('');
            setSelectedDay(defaultValue);
        }
        dispatch(
            getInterventionAsync.request({
                id: String(resourceId),
                type: e.target.id,
            }),
        );
        setIsTimeSelected(false);
        dispatch(setDoctorSummary(data));
    };

    // Reason for consultation
    const onValueChange = (e: InputChangeEvent) => {
        setSelectedReason(e.target.id);
        dispatch(
            setInterventionSummary({
                id: e.target.id,
                name: e.target.name,
                price: e.target.value,
            }),
        );
        dispatch(
            getAvailabilitiesAsync.request({
                resourcesId: data.id,
                interventionId: e.target.id,
                startsAt: `${selectedDay.day}/${selectedDay.month}/${selectedDay.year}`,
            }),
        );
        setIsTimeSelected(false);
    };

    const handleDateChange = (e: DProps) => {
        setSelectedDay(e);
        setShowDatepicker(false);
        if (startUpdated) {
            dispatch(
                getAvailabilitiesAsync.request({
                    resourcesId:
                        appointementDetails.data.attributes.resource.id,
                    interventionId: appointementDetails.included[0].id,
                    startsAt: `${e.day}/${e.month}/${e.year}`,
                }),
            );
        } else {
            dispatch(
                getAvailabilitiesAsync.request({
                    resourcesId: data.id,
                    interventionId: selectedReason,
                    startsAt: `${e.day}/${e.month}/${e.year}`,
                }),
            );
        }
    };

    const handleShowDatePicker = () => {
        setShowDatepicker(true);
    };
    const onHandleSelectedTime = (availability: IAvailability) => {
        if (startUpdated) {
            dispatch(
                setInterventionSummary({
                    id: appointementDetails.included[0].id,
                    name: appointementDetails.included[0].attributes.name,
                    price: appointementDetails.included[0].attributes.price,
                }),
            );
            dispatch(setDoctorSummary(data));
        }
        setIsTimeSelected(true);
        dispatch(setAvailabilitiesSummary(availability));
    };
    return (
        <WrappedDoctorsDetails
            loading={loading}
            selectedDay={selectedDay}
            resourcesDetail={data!}
            isUpdated={isUpdated}
            startUpdated={startUpdated}
            interventions={interventions!}
            availabilities={availabilities}
            selectedReason={selectedReason}
            showDatepicker={showDatepicker}
            isVideoConsultation={isVideoConsultation}
            isCabinetConsultation={isCabinetConsultation}
            onValueChange={onValueChange}
            handleChecked={handleChecked}
            handleDateChange={handleDateChange}
            onHandleSelectedTime={onHandleSelectedTime}
            isTimeSelected={isTimeSelected}
            handleShowDatePicker={handleShowDatePicker}
        />
    );
};

export default DoctorsDetailsScreen;
