/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { FC } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { IAppointementDetails } from 'Models';
import {
    converteDateToTime,
    converteToFormatLL,
    isAfter,
} from '../../utils/helpers';


type Props = {
    appointementDetails: IAppointementDetails;
    handleCancelAppointement(): void;
    handleUpdateAppointement(appointementDetails: IAppointementDetails): void;
};

const AppointementDetailCard: FC<Props> = ({
    appointementDetails,
    handleCancelAppointement,
    handleUpdateAppointement,
}) => {
    const openVisio = () => confirmAlert({
        title: 'Téléconsultation',
        message: `${appointementDetails.data.attributes.resource.name} - ${appointementDetails.included[0].attributes.name}`,
        buttons: [
            {
                label: 'Se connecter à la visio',
                onClick: () => window.open(appointementDetails.data.attributes.video_meet_url, '_blank')?.focus()
            },
            {
                label: 'Non',
                onClick: () => { }
            }
        ]
    });

    return (
        <>
            <style>
                {
                    `
                    .react-confirm-alert-overlay {
                        background-color: rgba(0, 0, 0, 0.7);
                    }
                    .react-confirm-alert-button-group button:first-child {
                        background-color: #39cabb;
                        font-weight: 600;
                        color: #fff;
                    }
                    `
                }
            </style>

            <div className="col-lg-4 col-md-12 col-sm-12 right-column">
                <div className="booking-information">
                    <div className="title-box">
                        <h3>Résume</h3>
                    </div>
                    <div className="inner-box">
                        <div className="single-box">
                            <ul className="clearfix">
                                <li>
                                    Date
                                    <span>
                                        {converteToFormatLL(
                                            new Date(
                                                appointementDetails.data.attributes.starts_at,
                                            ),
                                        )}
                                    </span>
                                </li>
                                <li>
                                    Heure
                                    <span>
                                        {converteDateToTime(
                                            new Date(
                                                appointementDetails.data.attributes.starts_at,
                                            ),
                                        )}
                                    </span>
                                </li>
                                <li>
                                    Intervention
                                    <span>
                                        {
                                            appointementDetails.included[0].attributes.name
                                        }
                                    </span>
                                </li>
                                <li>
                                    Prix
                                    <span>
                                        {Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'CFA', minimumFractionDigits: 0 }).format(Number(appointementDetails.included[0]
                                            .attributes.price))}
                                    </span>
                                </li>
                            </ul>

                            {
                                isAfter(
                                    new Date(appointementDetails.data.attributes.starts_at)
                                ) && (
                                    <>
                                        <Link
                                            to={`/doctors-details/${appointementDetails.data.attributes.resource.id}`}
                                        >
                                            <span
                                                data-testid="accept"
                                                className="accept"
                                                role="button"
                                                onClick={() =>
                                                    handleUpdateAppointement(
                                                        appointementDetails,
                                                    )
                                                }
                                            >
                                                <i className="fas fa-pencil-alt"></i>
                                                Modifier
                                            </span>
                                        </Link>

                                        <span
                                            className="cancel"
                                            role="button"
                                            onClick={handleCancelAppointement}
                                        >
                                            <i className="fas fa-times"></i>Annuler
                                        </span>
                                    </>
                                )
                            }

                            {
                                appointementDetails.data.attributes.video_meet_url && (
                                    <>
                                        <div className="row mt-5">
                                            <div className="text-center">
                                                <span
                                                    className="theme-btn-one"
                                                    role="button"
                                                    onClick={openVisio}
                                                >
                                                    <i className="fas fa-video"></i>&nbsp;&nbsp;&nbsp;Téléconsultation
                                                </span>
                                            </div>
                                        </div>
                                    </>)
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AppointementDetailCard;
