/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import resources from '../../utils/resources';

type Props = {
    id: string;
    name: string;
    speciality: string;
    imgUrl: string;
    favorited: boolean;
    city: string;
    biography: string;
    handleAddToFavorite(id: string, favorited: boolean): void;
};

const ListDoctorItem: FC<Props> = ({
    id,
    name,
    speciality,
    imgUrl,
    favorited,
    city,
    biography,
    handleAddToFavorite,
}) => {
    return (
        <div className="clinic-list-content list-item">
            <div className="clinic-block-one">
                <div className="inner-box">
                    <div className="pattern">
                        <div
                            className="pattern-1"
                            style={{
                                backgroundImage: `url(${resources['ic-shap-24']})`,
                            }}
                        />
                        <div
                            className="pattern-2"
                            style={{
                                backgroundImage: `url(${resources['ic-shap-25']})`,
                            }}
                        />
                    </div>
                    <figure className="image-box">
                        <img src={imgUrl} alt="" />
                    </figure>
                    <div className="content-box">
                        <div className="like-box">
                            <a
                                href="#/"
                                role="button"
                                onClick={() =>
                                    handleAddToFavorite(id, favorited)
                                }
                            >
                                {_.isEqual(favorited, true) ? (
                                    <i
                                        className="fa fa-heart"
                                        aria-hidden="true"
                                    ></i>
                                ) : (
                                    <i className="far fa-heart"></i>
                                )}
                            </a>
                        </div>
                        <ul className="name-box clearfix">
                            <li className="name">
                                <h3>
                                    <span>{name}</span>
                                </h3>
                            </li>
                            <li>
                                <i className="icon-Trust-1" />
                            </li>
                            <li>
                                <i className="icon-Trust-2" />
                            </li>
                        </ul>
                        <span className="designation">{speciality}</span>
                        <div className="text">
                            <p>{biography}</p>
                        </div>

                        <div className="location-box">
                            <p>
                                <i className="fas fa-map-marker-alt" />
                                {city}
                            </p>
                        </div>
                        <div className="btn-box">
                            <Link to={`/doctors-details/${id}`}>
                                <span>Voir</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListDoctorItem;
