/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { FC } from 'react';
import { IUserData, IRelative, IRelativeData } from 'Models';
import { Link } from 'react-router-dom';

import BackgroundElement from '../../components/BackgroundElement';
import ProfileInfoSidbar from '../../components/ProfileInfoSidbar';
import RelativesTable from '../../components/RelativesTable';

type Props = {
    userData: IUserData;
    // relative: IRelativeData;
    relativeData: IRelative | [];
    handleLogout(): void;
    startCreatedRelative(): void;
    handleUpdateRelative(item: IRelativeData): void;
    handleShowDeleteRelativeModal(relativeId: string): void;
};

const Relatives: FC<Props> = ({
    userData,
    // relative,
    handleLogout,
    relativeData,
    startCreatedRelative,
    handleUpdateRelative,
    handleShowDeleteRelativeModal,
}) => {
    return (
        <>
            <BackgroundElement
                title="Mes proches"
                linkTitle="Tableau de bord du patient"
                subTitle="Mes proches"
                linkPath="/patient-dashboard"
            />
            <section className="patient-dashboard bg-color-3">
                <ProfileInfoSidbar
                    userData={userData}
                    handleLogout={handleLogout}
                />

                <div className="right-panel">
                    <div className="content-container">
                        <div className="outer-container">
                            <div className="doctors-appointment my-patients">
                                <div className="title-box clearfix">
                                    <div className="text pull-left">
                                        <h3>Mes proches</h3>
                                    </div>
                                    <div
                                        className="btn-box pull-right"
                                        role="button"
                                        onClick={() => startCreatedRelative()}>
                                        <Link to="/add-relatives">
                                            <div className="theme-btn-one">
                                                Ajouter un proche
                                                <i className="icon-image"></i>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                                <RelativesTable
                                    relativeData={relativeData}
                                    handleShowDeleteRelativeModal={
                                        handleShowDeleteRelativeModal
                                    }
                                    handleUpdateRelative={handleUpdateRelative}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Relatives;
