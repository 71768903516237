import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        left: '0px',
        top: '0px',
        height: '100%',
        width: '100%',
        zIndex: 999999,
        backgroundColor: '#fff',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
    },
}));

export default useStyles;
