/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, InputChangeEvent } from 'Types';
import { CardAttributes } from 'Models';

import Rgister from '../../containers/Register';
import useForm from '../../shared/hoc/useForm';
import { isValidEmail } from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';
import {
    registerAsync,
    resetUserFeedback,
} from '../../_actions/auth/auth.actions';
import withLoader from '../../shared/hoc/withLoader';
import Snackbar from '../../components/Snackbar';
import AuthService from '../../services/auth-service';
import useScript from '../../utils/hooks/useScript';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

const initialFValues = {
    id: 0,
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    checkbox: false,
    phone: '',
};

const Wording = WordingConstant.Register;
const WrappedRgister = withLoader()(Rgister);

const RegisterScreen: FC<unknown> = () => {
    const dispatch = useDispatch();

    useScript('/static/js/jquery.nice-select.min.js');
    useScript('/static/js/script.js');
    useScript('/static/js/scrollbar.js');

    const [checked, isChecked] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const isSignIn = AuthService.isAuthenticated();

    const [allowedDmpCreation, setAllowedDmpCreation] =
        useState<boolean>(false);

    const { loading, status, error } = useSelector(
        (state: RootState) => state.authReducer,
    );

    const validate = (fieldValues = values) => {
        const temp: any = { ...errors };

        if ('firstname' in fieldValues) {
            temp.firstname = fieldValues.firstname
                ? ''
                : Wording.Errors.firstname;
        }
        if ('lastname' in fieldValues) {
            temp.lastname = fieldValues.lastname ? '' : Wording.Errors.lastname;
        }
        if ('email' in fieldValues) {
            temp.email = isValidEmail(fieldValues.email)
                ? ''
                : Wording.Errors.email;
        }
        if ('password' in fieldValues) {
            temp.password =
                fieldValues.password.length >= 6 ? '' : Wording.Errors.password;
        }
        if ('phone' in fieldValues) {
            temp.phone = fieldValues.phone ? '' : Wording.Errors.phone;
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
    };
    const showCGUErrorMessage = () => {
        return (
            <Snackbar
                message={Wording.Errors.checkbox}
                isError={showToast}
                onClose={handleSnackClose}
            />
        );
    };
    const handleOnClick = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validate() && _.isEqual(checked, false)) {
            setShowToast(true);
        } else if (validate()) {
            const cardAttributes: CardAttributes = {
                first_name: values.firstname,
                last_name: values.lastname,
                phone: values.phone,
                allowed_dmp_creation: allowedDmpCreation,
            };
            dispatch(
                registerAsync.request({
                    email: values.email,
                    password: values.password,
                    card_attributes: cardAttributes,
                }),
            );
        }
    };

    const handleSnackClose = () => {
        setShowToast(false);
        dispatch(resetUserFeedback());
    };

    const handleCheckedTerms = (e: InputChangeEvent) => {
        isChecked(e.target.checked);
    };

    const handleAllowedDmpCreation = (e: InputChangeEvent) => {
        setAllowedDmpCreation(e.target.checked);
    };

    const { values, errors, setErrors, handleInputChange } = useForm(
        initialFValues,
        true,
        validate,
    );

    return (
        <>
            <Header />

            <WrappedRgister
                loading={loading}
                fields={values}
                errors={errors}
                checked={checked}
                allowedDmpCreation={allowedDmpCreation}
                onChange={handleInputChange}
                handleOnClick={handleOnClick}
                handleAllowedDmpCreation={handleAllowedDmpCreation}
                handleCheckedTerms={handleCheckedTerms}
            />
            {!_.isEmpty(status) && (
                <Snackbar
                    message={
                        _.isEqual(status, 'error')
                            ? error
                            : 'Un mail vient de vous être envoyé pour finaliser votre inscription'
                    }
                    isError={!!_.isEqual(status, 'error')}
                    onClose={handleSnackClose}
                />
            )}
            {showToast && showCGUErrorMessage()}
            <Footer />
        </>
    );
};

export default RegisterScreen;
