/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { RootState } from 'Types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRelativeData } from 'Models';
import Relatives from '../../containers/Relatives';
import {
    getRelativeAsync,
    deleteRelativeAsync,
    startedUpdate,
} from '../../_actions/relatives/relatives.actions';
import withLoader from '../../shared/hoc/withLoader';
import RelativeModal from '../../components/RelativeModal';
import WithModal from '../../utils/hooks/WithModal';

const WrappedRelatives = withLoader()(Relatives);
const WrappedModal = WithModal()(RelativeModal);

const RelativesScreen: FC<unknown> = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [relativeId, setRelativeId] = useState<string>('');

    const { data } = useSelector((state: RootState) => state.userProfilReducer);

    const { relativeData, loading, hasBeenDeleted } = useSelector(
        (state: RootState) => state.relativesReducer,
    );

    const handleLogout = () => {};

    useEffect(() => {
        dispatch(getRelativeAsync.request({}));
    }, [dispatch]);

    useEffect(() => {
        if (_.isEqual(hasBeenDeleted, true)) {
            dispatch(getRelativeAsync.request({}));
        }
    }, [hasBeenDeleted, dispatch]);

    const handleDelete = () => {
        if (!_.isEmpty(relativeId)) {
            dispatch(deleteRelativeAsync.request(relativeId));
            setShowModal(false);
        }
    };

    const handleCancel = () => {
        setShowModal(false);
        setRelativeId('');
    };

    const handleShowDeleteRelativeModal = (id: string) => {
        setShowModal(true);
        setRelativeId(id);
    };

    const handleUpdateRelative = (item: IRelativeData) => {
        history.push('/add-relatives');
        dispatch(startedUpdate(item));
    };

    const startCreatedRelative = () => {
        dispatch(startedUpdate(undefined));
    };

    return (
        <>
            <WrappedRelatives
                loading={loading}
                userData={data}
                handleLogout={handleLogout}
                relativeData={relativeData}
                // relative={relative!}
                startCreatedRelative={startCreatedRelative}
                handleUpdateRelative={handleUpdateRelative}
                handleShowDeleteRelativeModal={handleShowDeleteRelativeModal}
            />

            <WrappedModal
                open={showModal}
                handleDelete={handleDelete}
                handleCancel={handleCancel}
            />
        </>
    );
};

export default RelativesScreen;
