/* eslint-disable react/button-has-type */
import React, { FC } from 'react';

type Props = {
    title?: string;
    onClick?: any;
    className?: string;
    children: React.ReactNode;
};
const Button: FC<Props> = ({ title, onClick, className, children }) => {
    return (
        <button title={title} onClick={onClick} className={className}>
            {children}
        </button>
    );
};

Button.defaultProps = {
    title: '',
    onClick: () => {},
    className: '',
};

export default Button;
