import React, { FC } from 'react';
import MaterialUIModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import useStyles from './style';

type Props = {
    open: boolean;
};

const Modal: FC<Props> = ({ open, children }) => {
    const classes = useStyles();

    return (
        <div>
            <MaterialUIModal
                className={classes.modal}
                open={open}
                onClose={() => {}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={open}>
                    <div className={classes.paper}>{children}</div>
                </Fade>
            </MaterialUIModal>
        </div>
    );
};

export default Modal;
