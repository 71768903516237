/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'Types';
import { IAddRelativeRequest } from 'Models';
import $ from 'jquery';
import { useHistory } from 'react-router-dom';

import useForm from '../../shared/hoc/useForm';
import WordingConstant from '../../utils/wording.json';
import { isValidEmail } from '../../utils/helpers';
import RelativeForm from '../../containers/RelativesForm';
import {
    addRelativeAsync,
    updateRelativeAsync,
} from '../../_actions/relatives/relatives.actions';
import withLoader from '../../shared/hoc/withLoader';

const Wording = WordingConstant.PatientProfil;

const WrappedRelativeForm = withLoader()(RelativeForm);

const RelativeFormScreen: FC<unknown> = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { data } = useSelector((state: RootState) => state.userProfilReducer);

    const { loading, hasBeenAdded, hasBeenUpdated, relative } = useSelector(
        (state: RootState) => state.relativesReducer,
    );
    const [gender, setGender] = useState(
        typeof relative !== 'undefined' ? relative.attributes.gender : 'male',
    );

    const [fields] = useState({
        id: 0,
        lastname:
            typeof relative !== 'undefined'
                ? relative.attributes.last_name
                : '',
        firstname:
            typeof relative !== 'undefined'
                ? relative.attributes.first_name
                : '',
        email: typeof relative !== 'undefined' ? relative.attributes.email : '',
        birthdate:
            typeof relative !== 'undefined'
                ? relative.attributes.birthdate
                : '',
        phone: typeof relative !== 'undefined' ? relative.attributes.phone : '',
        address:
            typeof relative !== 'undefined' ? relative.attributes.address : '',
        zipcode:
            typeof relative !== 'undefined' ? relative.attributes.zip_code : '',
        birthcity:
            typeof relative !== 'undefined'
                ? relative.attributes.birth_city
                : '',
        birthcountrycode:
            typeof relative !== 'undefined'
                ? relative.attributes.birth_country_code
                : '',
        city: typeof relative !== 'undefined' ? relative.attributes.city : '',
    });

    useEffect(() => {
        if (_.isEqual(hasBeenAdded, true) || _.isEqual(hasBeenUpdated, true)) {
            history.push('/relatives');
        }
        // set relation
        if (typeof relative !== 'undefined') {
            $('#relation').val(`${relative.attributes.relation}`);
            $('#country').val(`${relative.attributes.birth_country_code}`);
        }
    }, [hasBeenAdded, hasBeenUpdated, history, relative]);

    const validate = (fieldValues = values) => {
        const temp: any = { ...errors };
        if ('firstname' in fieldValues) {
            temp.firstname = fieldValues.firstname
                ? ''
                : Wording.Errors.firstname;
        }
        if ('lastname' in fieldValues) {
            temp.lastname = fieldValues.lastname ? '' : Wording.Errors.lastname;
        }
        if ('email' in fieldValues) {
            temp.email = isValidEmail(fieldValues.email)
                ? ''
                : Wording.Errors.email;
        }
        if ('birthdate' in fieldValues) {
            temp.birthdate = fieldValues.birthdate
                ? ''
                : Wording.Errors.birthdate;
        }
        if ('gender' in fieldValues) {
            temp.gender = fieldValues.gender ? '' : Wording.Errors.gender;
        }
        if ('phone' in fieldValues) {
            temp.phone = fieldValues.phone ? '' : Wording.Errors.phone;
        }
        if ('address' in fieldValues) {
            temp.address = fieldValues.address ? '' : Wording.Errors.address;
        }
        if ('zipcode' in fieldValues) {
            temp.zipcode = fieldValues.zipcode ? '' : Wording.Errors.zipcode;
        }
        if ('birthcity' in fieldValues) {
            temp.birthcity = fieldValues.birthcity
                ? ''
                : Wording.Errors.birthcity;
        }

        if ('city' in fieldValues) {
            temp.city = fieldValues.city ? '' : Wording.Errors.city;
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
    };
    const handleOnClick = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const relation = $('#relation').val();
        const birthcountrycode = $('#country').val();
        if (validate()) {
            const relativeRequest: IAddRelativeRequest = {
                card: {
                    gender: String(gender),
                    email: values.email,
                    address: values.address,
                    city: values.city,
                    country_code: '',
                    birth_city: values.birthcity,
                    birth_country_code: String(birthcountrycode),
                    first_name: values.firstname,
                    last_name: values.lastname,
                    phone: values.phone,
                    birthdate: values.birthdate,
                    relation: String(relation),
                    zip_code: values.zipcode,
                },
            };

            if (typeof relative !== 'undefined') {
                dispatch(
                    updateRelativeAsync.request({
                        card: relativeRequest,
                        relativeId: String(relative?.id),
                    }),
                );
            } else {
                dispatch(
                    addRelativeAsync.request({ card: relativeRequest.card }),
                );
            }
        }
    };

    const { values, errors, setErrors, handleInputChange } = useForm(
        fields,
        true,
        validate,
    );

    const handleCheck = (name: string) => {
        setGender(name);
    };

    const handleLogout = () => {};

    return (
        <WrappedRelativeForm
            loading={loading}
            userData={data}
            relative={relative!}
            handleLogout={handleLogout}
            handleInputChange={handleInputChange}
            fields={values}
            errors={errors}
            gender={gender}
            handleOnClick={handleOnClick}
            handleCheck={handleCheck}
        />
    );
};

export default RelativeFormScreen;
