/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'Types';
import $ from 'jquery';
import { useHistory } from 'react-router-dom';
import DocumentForm from '../../containers/DocumentForm';
import useForm from '../../shared/hoc/useForm';
import WordingConstant from '../../utils/wording.json';
import { addDocumentAsync } from '../../_actions/document/dcument.actions';

const Wording = WordingConstant.Document;

const DocumentFormScreen: FC<unknown> = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [fileSelected, setFileSelected] = React.useState<File>();

    const { data } = useSelector((state: RootState) => state.userProfilReducer);

    const [fields] = useState({
        id: 0,
        name: '',
    });

    const validate = (fieldValues = values) => {
        const temp: any = { ...errors };
        if ('name' in fieldValues) {
            temp.name = fieldValues.name ? '' : Wording.Errors.docName;
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
    };

    const handleOnClick = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validate()) {
            if (fileSelected) {
                const docType = $('#documentType').val();
                const formData = new FormData();
                /*const formData = new FormData();
                const jsonBodyData = { name: 'Ordonnance Dr. Ernser' };

                formData.append(
                    'document[name]',
                    new Blob([JSON.stringify(jsonBodyData)], {
                        type: 'application/json',
                    }),
                );
                /*formData.append('document[name]', 'Ordonnance Dr. Ernser');
                formData.append('document[document_type]', String(docType));
                formData.append(
                    'document[file]',
                    fileSelected,
                    fileSelected.name,
                );*/

                // console.log('names : ', formData);

                // formData.append('File', fileSelected);
                // const formData = new FormData();
                // formData.append('file', fileSelected);
                // console.log('names formData: ', formData);
                // console.log('names file: ', fileSelected);

                formData.append('document[name]', 'Ordonnance Dr. Ernser');
                formData.append('document[document_type]', '57833-6');
                // formData.append('document[file]', '');
                dispatch(addDocumentAsync.request({ doc: formData }));
            }
        }
    };

    const { values, errors, setErrors, handleInputChange } = useForm(
        fields,
        true,
        validate,
    );

    const handleLogout = () => {
        console.log('hhhhhh');
    };

    const handleSearchFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files;
        if (!fileList) return;
        // console.log('names : ', fileList);
        setFileSelected(fileList[0]);
    };

    return (
        <DocumentForm
            fields={values}
            userData={data}
            errors={errors}
            handleSearchFile={handleSearchFile}
            handleInputChange={handleInputChange}
            handleLogout={handleLogout}
            handleOnClick={handleOnClick}
        />
    );
};

export default DocumentFormScreen;
