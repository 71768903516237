/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { RootState } from 'Types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PatientDashboard from '../../components/PatientDashboard';
import { getUserProfilAsync } from '../../_actions/profil/profil.actions';
import { logoutAsync } from '../../_actions/auth/auth.actions';
import {
    getAppointementAsync,
    startUpdatedAppointement,
} from '../../_actions/apointement/appointement.actions';
import withLoader from '../../shared/hoc/withLoader';
import LogoutButton from '../../components/LogoutModal/LogoutModal';
import WithModal from '../../utils/hooks/WithModal';
import AuthService from '../../services/auth-service';

const WrappedPatientDashboard = withLoader()(PatientDashboard);
const WrappedModal = WithModal()(LogoutButton);

const PatientDashboardScreen: FC<unknown> = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [isChecked, setIsChecked] = useState('upcoming');
    const [isOpen, setOpen] = useState<boolean>(false);

    const { data } = useSelector((state: RootState) => state.userProfilReducer);

    const { loading, appointement } = useSelector(
        (state: RootState) => state.appointementReducer,
    );

    useEffect(() => {
        dispatch(getAppointementAsync.request({ path: 'upcoming' }));
        dispatch(getUserProfilAsync.request({}));
        dispatch(startUpdatedAppointement(false));
    }, [dispatch]);

    const handeleCheck = (name: string) => {
        setIsChecked(name);
        if (_.isEqual(name, 'upcoming')) {
            dispatch(getAppointementAsync.request({ path: 'upcoming' }));
        } else if (_.isEqual(name, 'outdated')) {
            dispatch(getAppointementAsync.request({ path: 'outdated' }));
        } else if (_.isEqual(name, 'canceled')) {
            dispatch(getAppointementAsync.request({ path: 'canceled' }));
        }
    };

    // show modal
    const handleLogout = () => {
        setOpen(true);
    };

    const handleCancelLogout = () => {
        setOpen(false);
    };

    const logout = () => {
        dispatch(logoutAsync.request({}));
        AuthService.logout();
        history.push('/login');
    };

    return (
        <>
            <WrappedPatientDashboard
                loading={loading}
                userData={data}
                isChecked={isChecked}
                handeleCheck={handeleCheck}
                appointement={appointement}
                handleLogout={handleLogout}
            />
            <WrappedModal
                open={isOpen}
                title="Déconnexion"
                handleLogout={logout}
                handleCancelLogout={handleCancelLogout}
            />
        </>
    );
};

export default PatientDashboardScreen;
