/* eslint-disable import/no-extraneous-dependencies */
import {
    ActionType,
    createStandardAction,
    createAsyncAction,
    isActionOf,
} from 'typesafe-actions';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import { RootAction, RootState, Services } from 'Types';
import { of } from 'rxjs';
import { Epic } from 'redux-observable';
import {
    IAppointementDetails,
    IAppointement,
    ICreateAppointement,
} from 'Models';

import {
    START_APPOINTEMENT_REQUEST,
    GET_APPOINTEMENT_REQUEST,
    GET_APPOINTEMENT_SUCCESS,
    GET_APPOINTEMENT_FAILURE,
    GET_APPOINTEMENT_DETAILS_REQUEST,
    GET_APPOINTEMENT_DETAILS_SUCCESS,
    GET_APPOINTEMENT_DETAILS_FAILURE,
    CREATE_APPOINTEMENT_REQUEST,
    CREATE_APPOINTEMENT_SUCCESS,
    CREATE_APPOINTEMENT_FAILURE,
    RESET_APPOINTEMENT_FEEDBACK,
    UPDATE_APPOINTEMENT_REQUEST,
    UPDATE_APPOINTEMENT_SUCCESS,
    UPDATE_APPOINTEMENT_FAILURE,
    CANCEL_APPOINTEMENT_REQUEST,
    CANCEL_APPOINTEMENT_SUCCESS,
    CANCEL_APPOINTEMENT_FAILURE,
    START_UPDATED_APPOINTEMENT_REQUEST,
} from '../actionTypes';

const startAppointement = createStandardAction(
    START_APPOINTEMENT_REQUEST,
)<boolean>();

const resetAppointementFeedBack = createStandardAction(
    RESET_APPOINTEMENT_FEEDBACK,
)<any>();

const startUpdatedAppointement = createStandardAction(
    START_UPDATED_APPOINTEMENT_REQUEST,
)<boolean>();

interface IPayload {
    path: string;
}

interface IPayloadAppointementDetails {
    appointementId: string;
}

interface IPayloadCreateAppointement {
    appointment: {
        slot_id: string;
    };
}

const getAppointementAsync = createAsyncAction(
    GET_APPOINTEMENT_REQUEST,
    GET_APPOINTEMENT_SUCCESS,
    GET_APPOINTEMENT_FAILURE,
)<IPayload, IAppointement, any>();

const getAppointementDetailsAsync = createAsyncAction(
    GET_APPOINTEMENT_DETAILS_REQUEST,
    GET_APPOINTEMENT_DETAILS_SUCCESS,
    GET_APPOINTEMENT_DETAILS_FAILURE,
)<IPayloadAppointementDetails, IAppointementDetails, any>();

const createAppointementAsync = createAsyncAction(
    CREATE_APPOINTEMENT_REQUEST,
    CREATE_APPOINTEMENT_SUCCESS,
    CREATE_APPOINTEMENT_FAILURE,
)<IPayloadCreateAppointement, ICreateAppointement, any>();

const updateAppointementAsync = createAsyncAction(
    UPDATE_APPOINTEMENT_REQUEST,
    UPDATE_APPOINTEMENT_SUCCESS,
    UPDATE_APPOINTEMENT_FAILURE,
)<IPayloadAppointement, ICreateAppointement, any>();

const cancelAppointementAsync = createAsyncAction(
    CANCEL_APPOINTEMENT_REQUEST,
    CANCEL_APPOINTEMENT_SUCCESS,
    CANCEL_APPOINTEMENT_FAILURE,
)<IPayloadAppointementDetails, IAppointementDetails, any>();

export type AppointementAction =
    | ActionType<typeof startAppointement>
    | ActionType<typeof getAppointementDetailsAsync>
    | ActionType<typeof getAppointementAsync>
    | ActionType<typeof createAppointementAsync>
    | ActionType<typeof updateAppointementAsync>
    | ActionType<typeof cancelAppointementAsync>
    | ActionType<typeof resetAppointementFeedBack>
    | ActionType<typeof startUpdatedAppointement>;

const preparePayloadAppointement = ({ path }: IPayload) => {
    return {
        path,
    };
};

const preparePayloadAppointementDetail = ({
    appointementId,
}: IPayloadAppointementDetails) => {
    return {
        appointementId,
    };
};

const preparePayloadCreateAppointement = ({
    appointment,
}: IPayloadCreateAppointement) => {
    return {
        appointment,
    };
};

interface IResquestAppointeement {
    appointment: {
        slot_id: string;
    };
}
interface IPayloadAppointement {
    appointement: IResquestAppointeement;
    appointementId: string;
}
const prepareUpdateRelativePayload = ({
    appointement,
    appointementId,
}: IPayloadAppointement) => {
    return {
        appointement,
        appointementId,
    };
};

const mapGetAppointement = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadAppointement(action.payload);
    return apiRequest<IAppointement>({
        path: `${'/appointments/'}${payload.path}`,
        method: 'get',
        body: '',
    }).pipe(
        mergeMap((response: IAppointement) => {
            if (response) {
                return of(getAppointementAsync.success(response));
            }
            return of(getAppointementAsync.failure(response));
        }),
        catchError((error) => {
            return of(getAppointementAsync.failure(error));
        }),
    );
};

const mapGetAppointementDetails = (
    action: RootAction,
    { apiRequest }: Services,
) => {
    const payload = preparePayloadAppointementDetail(action.payload);
    return apiRequest<IAppointementDetails>({
        path: `${'/appointments/'}${payload.appointementId}`,
        method: 'get',
        body: '',
    }).pipe(
        mergeMap((response: IAppointementDetails) => {
            if (response) {
                return of(getAppointementDetailsAsync.success(response));
            }
            return of(getAppointementDetailsAsync.failure(response));
        }),
        catchError((error) => {
            return of(getAppointementDetailsAsync.failure(error));
        }),
    );
};

const mapCreateAppointement = (
    action: RootAction,
    { apiRequest }: Services,
) => {
    const payload = preparePayloadCreateAppointement(action.payload);
    return apiRequest<ICreateAppointement>({
        path: '/appointments',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: ICreateAppointement) => {
            if (response) {
                return of(createAppointementAsync.success(response));
            }
            return of(createAppointementAsync.failure(response));
        }),
        catchError((error) => {
            return of(createAppointementAsync.failure(error));
        }),
    );
};

const mapUpdateAppointement = (
    action: RootAction,
    { apiRequest }: Services,
) => {
    const payload = prepareUpdateRelativePayload(action.payload);
    return apiRequest<ICreateAppointement>({
        path: `${'/appointments/'}${payload.appointementId}`,
        method: 'put',
        body: payload.appointement,
    }).pipe(
        mergeMap((response: ICreateAppointement) => {
            if (response) {
                return of(updateAppointementAsync.success(response));
            }
            return of(updateAppointementAsync.failure(response));
        }),
        catchError((error) => {
            return of(updateAppointementAsync.failure(error));
        }),
    );
};

const mapCancelAppointement = (
    action: RootAction,
    { apiRequest }: Services,
) => {
    const payload = preparePayloadAppointementDetail(action.payload);
    return apiRequest<IAppointementDetails>({
        path: `${'/appointments/'}${payload.appointementId}/cancel`,
        method: 'put',
        body: '',
    }).pipe(
        mergeMap((response: IAppointementDetails) => {
            if (response) {
                return of(cancelAppointementAsync.success(response));
            }
            return of(cancelAppointementAsync.failure(response));
        }),
        catchError((error) => {
            return of(cancelAppointementAsync.failure(error));
        }),
    );
};

const getAppointementEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(getAppointementAsync.request)),
        mergeMap((action) => mapGetAppointement(action, dependency)),
    );

const createAppointementEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(createAppointementAsync.request)),
        mergeMap((action) => mapCreateAppointement(action, dependency)),
    );

const getAppointementDetailEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(getAppointementDetailsAsync.request)),
        mergeMap((action) => mapGetAppointementDetails(action, dependency)),
    );

const updateAppointementEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(updateAppointementAsync.request)),
        mergeMap((action) => mapUpdateAppointement(action, dependency)),
    );

const cancelAppointementEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(cancelAppointementAsync.request)),
        mergeMap((action) => mapCancelAppointement(action, dependency)),
    );

export {
    startAppointement,
    mapGetAppointement,
    getAppointementEpic,
    getAppointementAsync,
    getAppointementDetailsAsync,
    getAppointementDetailEpic,
    createAppointementEpic,
    createAppointementAsync,
    resetAppointementFeedBack,
    updateAppointementAsync,
    updateAppointementEpic,
    cancelAppointementAsync,
    cancelAppointementEpic,
    startUpdatedAppointement,
};
