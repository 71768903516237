/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { RootState } from 'Types';
import { useDispatch, useSelector } from 'react-redux';
import SearchDoctor from '../../containers/SearchDoctor';
import AuthService from '../../services/auth-service';
import {
    addFavoriteAsync,
    deleteFavoriteAsync,
} from '../../_actions/favourite/favourite.actions';
import withLoader from '../../shared/hoc/withLoader';
import {
    getResourcesBySearchAsync,
    setSearchParameter,
} from '../../_actions/resources/resources.actions';
import useScript from '../../utils/hooks/useScript';
import Snackbar from '../../components/Snackbar';

const WrappedSearchDoctor = withLoader()(SearchDoctor);

const SeachDoctor: FC<unknown> = () => {
    const dispatch = useDispatch();
    const isSignIn = AuthService.isAuthenticated();
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);

    useScript('/static/js/product-filter.js');
    useScript('/static/js/jquery.nice-select.min.js');
    useScript('/static/js/script.js');

    const [currentPage, setCurrentPage] = useState<number>(1);

    const { loading, searchData, searchParameter } = useSelector(
        (state: RootState) => state.resourcesReducer,
    );

    useEffect(() => {
        dispatch(
            getResourcesBySearchAsync.request({
                term: searchParameter.term,
                lng: searchParameter.lng,
                lat: searchParameter.lat,
                city: searchParameter.city,
                page: currentPage,
            }),
        );
    }, [dispatch]);

    const handleSelectedPage = (pageNumbber: number) => {
        setCurrentPage(pageNumbber);
        dispatch(
            getResourcesBySearchAsync.request({
                term: searchParameter.term,
                lng: searchParameter.lng,
                lat: searchParameter.lat,
                city: searchParameter.city,
                page: currentPage,
            }),
        );
    };

    const handleAddToFavorite = (id: string, favorited: boolean) => {
        if (!isSignIn) {
            setShowSnackbar(true);
        } else if (isSignIn && _.isEqual(favorited, false)) {
            dispatch(addFavoriteAsync.request(id));
        } else if (isSignIn && _.isEqual(favorited, true)) {
            dispatch(deleteFavoriteAsync.request(id));
        }
    };
    const handleSnackClose = () => {
        setShowSnackbar(false);
    };

    return (
        <>
            <WrappedSearchDoctor
                data={searchData}
                loading={loading}
                currentPage={currentPage}
                handleSelectedPage={handleSelectedPage}
                handleAddToFavorite={handleAddToFavorite}
            />

            {!isSignIn && showSnackbar && (
                <Snackbar
                    message={
                        'Veuillez vous authentifier pour ajouter un favori'
                    }
                    isError={Boolean(true)}
                    onClose={handleSnackClose}
                />
            )}
        </>
    );
};

export default SeachDoctor;
