/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'Types';
import { useHistory } from 'react-router-dom';
import AuthService from '../../services/auth-service';

import useForm from '../../shared/hoc/useForm';
import { logoutAsync } from '../../_actions/auth/auth.actions';
import { resetPasswordAsync } from '../../_actions/profil/profil.actions';
import ResetPassword from '../../containers/ResetPassword';
import withLoader from '../../shared/hoc/withLoader';
import WordingConstant from '../../utils/wording.json';

const WrappedResetPassword = withLoader()(ResetPassword);

const initialFValues = {
    id: 0,
    oldPassword: '',
    newPassword: '',
    confNewPassword: '',
};
const Wording = WordingConstant.ResetPassword;

const ResetPasswordScreen: FC<unknown> = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { data, isReset, loading } = useSelector(
        (state: RootState) => state.userProfilReducer,
    );

    const validate = (fieldValues = values) => {
        const temp: any = { ...errors };

        if ('oldPassword' in fieldValues) {
            temp.oldPassword = fieldValues.oldPassword
                ? ''
                : Wording.Errors.oldPassword;
        }
        if ('newPassword' in fieldValues) {
            temp.newPassword = fieldValues.newPassword
                ? ''
                : Wording.Errors.newPassword;
        }
        if ('confNewPassword' in fieldValues) {
            temp.confNewPassword = _.isEqual(
                fieldValues.confNewPassword,
                values.newPassword,
            )
                ? ''
                : Wording.Errors.noSamePassword;
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
    };
    const { values, errors, setErrors, handleInputChange } = useForm(
        initialFValues,
        true,
        validate,
    );

    const handleOnClick = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validate()) {
            dispatch(
                resetPasswordAsync.request({
                    password: values.newPassword,
                    password_confirmation: values.confNewPassword,
                }),
            );
        }
    };

    const handleLogout = () => {};

    useEffect(() => {
        if (isReset) {
            dispatch(logoutAsync.request({}));
            AuthService.logout();
            history.push('/login');
        }
    }, [isReset, dispatch, history]);

    return (
        <WrappedResetPassword
            loading={loading}
            fields={values}
            errors={errors}
            userData={data}
            handleOnClick={handleOnClick}
            handleLogout={handleLogout}
            onChange={handleInputChange}
        />
    );
};

export default ResetPasswordScreen;
