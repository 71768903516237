import React from 'react';
import Lottie from 'react-lottie';
import MaterialUIModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import animationData from '../../../utils/animation.json';
import useStyles from './style';

export const LoadingSpinner: React.FC<unknown> = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData,
    };
    const classes = useStyles();

    return (
        <div className={classes.loader}>
            <Lottie
                options={defaultOptions}
                height={180}
                width={180}
                isStopped={false}
            />
        </div>
    );
};

export const LoaderModal: React.FC<unknown> = () => {
    return (
        <div className="loadermodal">
            <MaterialUIModal
                open
                onClose={() => {}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in>
                    <LoadingSpinner />
                </Fade>
            </MaterialUIModal>
        </div>
    );
};

export type ExternalProps = {
    loading: boolean;
};

const withLoader =
    () =>
    <OwnProps extends {}>(WrappedComponent: React.ComponentType<OwnProps>) => {
        type HocProps = OwnProps & ExternalProps;

        const component: React.FC<HocProps> = ({ loading, ...props }) => {
            return (
                <div className="loaderpage">
                    {loading && <div className="preloader"></div>}

                    <WrappedComponent {...(props as OwnProps)} />
                </div>
            );
        };
        return component;
    };

export default withLoader;
