import React, { FC, useEffect } from 'react';
import { RootState } from 'Types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import withLoader from '../../shared/hoc/withLoader';
import AllSpeciality from '../../components/AllSpeciality';
import { getSpecialityAsync } from '../../_actions/speciality/specialities.actions';
import { startAppointement } from '../../_actions/apointement/appointement.actions';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import useScript from '../../utils/hooks/useScript';

const WrappedAllSpeciality = withLoader()(AllSpeciality);

const AllSpecilityScreen: FC<unknown> = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useScript('/static/js/script.js');
    useScript('/static/js/scrollbar.js');

    const { loading, data } = useSelector(
        (state: RootState) => state.specialityReducer,
    );
    useEffect(() => {
        dispatch(getSpecialityAsync.request({}));
        dispatch(startAppointement(false));
    }, [dispatch]);

    const handleOnClick = (id: string) => {
        history.push(`/doctors/${id}`);
    };

    return (
        <>
            <Header />
            <WrappedAllSpeciality
                loading={loading}
                speciality={data}
                handleOnClick={handleOnClick}
            />
            <Footer />
        </>
    );
};

export default AllSpecilityScreen;
