/* eslint-disable import/no-extraneous-dependencies */
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { IFavorite } from 'Models';

import * as favoriteAction from '../../_actions/favourite/favourite.actions';
import * as authActions from '../../_actions/auth/auth.actions';
import * as resourcesActions from '../../_actions/resources/resources.actions';

export type FavoriteState = Readonly<{
    loading: boolean;
    favorite: IFavorite | [];
    error: any;
    isAddFavorite: boolean;
}>;

export const initialState: FavoriteState = {
    loading: false,
    favorite: [],
    error: '',
    isAddFavorite: false,
};

type Actions =
    | favoriteAction.FavoriteAction
    | authActions.AuthAction
    | resourcesActions.ResourcesAction;

const appointementReducer: Reducer<FavoriteState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(favoriteAction.deleteFavoriteAsync.request):
        case getType(favoriteAction.addFavoriteAsync.request):
        case getType(favoriteAction.getFavoriteAsync.request): {
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        }

        case getType(favoriteAction.getFavoriteAsync.success): {
            return {
                ...state,
                loading: false,
                favorite: action.payload,
            };
        }

        case getType(favoriteAction.deleteFavoriteAsync.success):
        case getType(favoriteAction.addFavoriteAsync.success): {
            return {
                ...state,
                loading: false,
                isAdd: true,
            };
        }

        case getType(favoriteAction.getFavoriteAsync.failure): {
            return {
                ...state,
                loading: false,
                favorite: [],
                error: action.payload,
            };
        }

        case getType(authActions.logoutAsync.success): {
            return {
                ...state,
                loading: false,
                favorite: [],
                error: '',
                isAddFavorite: false,
            };
        }

        default:
            return { ...state };
    }
};
export default appointementReducer;
