/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC } from 'react';
import { IAppointementData } from 'Models';
import { Link } from 'react-router-dom';
import {
    converteToFormatLL,
    converteDateToTime,
    formatStatus,
    isAfter,
} from '../../utils/helpers';

type Props = {
    appointement: IAppointementData;
};

const AppointementTableCell: FC<Props> = ({ appointement }) => {
    return (
        <tr key={0}>
            <td>
                <div className="name-box">
                    <figure className="image">
                        <img
                            src={
                                appointement.attributes.resource.photo.small_url
                            }
                            alt=""
                        />
                    </figure>
                    <h5>{appointement.attributes.resource.name}</h5>
                    <span className="designation">
                        {appointement.attributes.resource.speciality}
                    </span>
                </div>
            </td>
            <td>
                <p>
                    {converteToFormatLL(
                        new Date(appointement.attributes.starts_at),
                    )}
                </p>
                <span className="time">
                    {converteDateToTime(
                        new Date(appointement.attributes.starts_at),
                    )}
                </span>
            </td>

            <td>
                {_.isEqual(appointement.attributes.state, 'validated') ? (
                    <span className="status">
                        {formatStatus(appointement.attributes.state)}
                    </span>
                ) : (
                    <span className="cancel">
                        {formatStatus(appointement.attributes.state)}
                    </span>
                )}
            </td>

            {!_.isEqual(appointement.attributes.state, 'canceled') && (
                <td>
                    <span className="view">
                        <Link to={`/appointement-details/${appointement.id}`}>
                            <i className="fas fa-eye"></i>
                            Voir
                        </Link>
                    </span>

                    {!isAfter(new Date(appointement.attributes.starts_at)) && (
                        <span className="view" style={{ marginLeft: '10px' }}>
                            <Link
                                to={`/doctors-details/${appointement.attributes.resource.id}`}
                            >
                                <i className="fas fa-briefcase-medical"></i>
                                Reprendre
                            </Link>
                        </span>
                    )}
                </td>
            )}

            {_.isEqual(appointement.attributes.video_consultation, true) && (
                <td>
                    <span className="view">
                        <i className="fas fa-video" />
                    </span>
                </td>
            )}
        </tr>
    );
};

export default AppointementTableCell;
