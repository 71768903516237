/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { RootState } from 'Types';
import { useDispatch, useSelector } from 'react-redux';

import AuthService from '../../services/auth-service';
import Doctors from '../../containers/Doctors';
import { getResourcesBySpecialityIdAsync } from '../../_actions/resources/resources.actions';
import {
    addFavoriteAsync,
    deleteFavoriteAsync,
} from '../../_actions/favourite/favourite.actions';
import { startAppointement } from '../../_actions/apointement/appointement.actions';

import withLoader from '../../shared/hoc/withLoader';
import useScript from '../../utils/hooks/useScript';
import Snackbar from '../../components/Snackbar';

const WrappedDoctors = withLoader()(Doctors);

const DoctorsScreen: FC<unknown> = () => {
    const dispatch = useDispatch();
    const isSignIn = AuthService.isAuthenticated();
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);

    useScript('/static/js/jquery.nice-select.min.js');
    useScript('/static/js/product-filter.js');
    useScript('/static/js/script.js');

    const { loading, data } = useSelector(
        (state: RootState) => state.resourcesReducer,
    );

    const url = `${window.location.pathname}`;
    const specialityId = url.split('/').pop();

    useEffect(() => {
        callDispatch();
    }, [dispatch, specialityId]);

    const callDispatch = () => {
        dispatch(
            getResourcesBySpecialityIdAsync.request({
                id: String(specialityId),
            }),
        );
    };

    const handleSnackClose = () => {
        setShowSnackbar(false);
    };

    const handleAddToFavorite = (id: string, favorited: boolean) => {
        if (!isSignIn) {
            setShowSnackbar(true);
        } else if (isSignIn && _.isEqual(favorited, false)) {
            dispatch(addFavoriteAsync.request(id));
            callDispatch();
        } else if (isSignIn && _.isEqual(favorited, true)) {
            dispatch(deleteFavoriteAsync.request(id));
            callDispatch();
        }
    };

    const handleSelectedPage = (pageNumbber: number) => {
        setCurrentPage(pageNumbber);
    };

    return (
        <>
            <WrappedDoctors
                loading={loading}
                data={data}
                currentPage={currentPage}
                handleSelectedPage={handleSelectedPage}
                handleAddToFavorite={handleAddToFavorite}
            />

            {!isSignIn && showSnackbar && (
                <Snackbar
                    message={
                        'Veuillez vous authentifier pour utiliser le service.'
                    }
                    isError={Boolean(true)}
                    onClose={handleSnackClose}
                />
            )}
        </>
    );
};

export default DoctorsScreen;
