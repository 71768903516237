/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC } from 'react';
import { IData } from 'Models';

type Props = {
    resourcesDetail: IData;
};

const ExperienceBox: FC<Props> = ({ resourcesDetail }) => {
    const isWorksAndPublications =
        typeof resourcesDetail.attributes.doctor.works_and_publications !==
            'undefined' &&
        !_.isEmpty(resourcesDetail.attributes.doctor.works_and_publications);

    const isExperiences =
        typeof resourcesDetail.attributes.doctor.experiences !== 'undefined' &&
        !_.isEmpty(resourcesDetail.attributes.doctor.experiences);

    return (
        <div className="tab" id="tab-2">
            <div className="experience-box">
                <div className="text">
                    {!!isExperiences && (
                        <div>
                            <h3>Expérience professionnelle</h3>
                            <ul className="experience-list clearfix">
                                {resourcesDetail.attributes.doctor.experiences!.map(
                                    (item, index) => {
                                        return (
                                            <li key={String(index)}>
                                                {item.ends_at} -{' '}
                                                {item.starts_at} :
                                                <p>{item.description} </p>
                                            </li>
                                        );
                                    },
                                )}
                            </ul>
                        </div>
                    )}

                    {!!isWorksAndPublications && (
                        <div>
                            <h3>Travaux et publications</h3>
                            <ul className="skills-list clearfix">
                                {resourcesDetail.attributes.doctor.works_and_publications!.map(
                                    (item, index) => {
                                        return (
                                            <li key={String(index)}>
                                                {item.year}
                                                <p>{item.description} </p>
                                            </li>
                                        );
                                    },
                                )}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ExperienceBox;
