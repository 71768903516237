import React, { FC } from 'react';
import { ISpeciality } from 'Models';
import { Link } from 'react-router-dom';

import resources from '../../../utils/resources';
import SpecialityItem from '../../SpecialityItem';
import GenericList from '../../GenericList';

type Props = {
    data: ISpeciality[];
    handleOnClick(id: string): void;
};

const Category: FC<Props> = ({ data, handleOnClick }) => {
    return (
        <section className="category-section bg-color-3 centred">
            <div className="pattern-layer">
                <div
                    className="pattern-1"
                    style={{
                        backgroundImage: `url(${resources['ic-shap-47']})`,
                    }}
                />
                <div
                    className="pattern-2"
                    style={{
                        backgroundImage: `url(${resources['ic-shap-48']})`,
                    }}
                />
            </div>

            <div className="auto-container">
                <div className="sec-title centred">
                    <p>Spécialiste</p>
                    <h2>Parcourir par spécialiste</h2>
                </div>

                <div className="row clearfix">
                    {!!data &&
                        data.slice(0, 8).map((e) => {
                            return (
                                <SpecialityItem
                                    key={e.id.toString()}
                                    id={e.id}
                                    label={e.attributes.name}
                                    src={''}
                                    handleOnClick={handleOnClick}
                                />
                            );
                        })}
                </div>
                <div className="more-btn">
                    <Link to="/all-speciality">
                        <span className="theme-btn-one">
                            Toutes les spécialités
                            <i className="icon-Arrow-Right" />
                        </span>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default Category;
