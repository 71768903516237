/* eslint-disable import/no-extraneous-dependencies */
import React, { FC } from 'react';
import _ from 'lodash';
import { IData } from 'Models';

import useScript from '../../utils/hooks/useScript';
import OverviewBox from './Box/Overview';
import ExperienceBox from './Box/Experience';

type Props = {
    resourcesDetail: IData;
};
const DoctorTabsBox: FC<Props> = ({ resourcesDetail }) => {
    useScript('/static/js/script.js');
    return (
        <div className="tabs-box">
            <div className="tab-btn-box centred">
                <ul className="tab-btns tab-buttons clearfix">
                    <li className="tab-btn active-btn" data-tab="#tab-1">
                        Aperçu
                    </li>
                    <li className="tab-btn" data-tab="#tab-2">
                        Expérience
                    </li>
                    <li className="tab-btn" data-tab="#tab-3">
                        Lieu
                    </li>
                </ul>
            </div>
            <div className="tabs-content">
                <OverviewBox resourcesDetail={resourcesDetail} />
                <ExperienceBox resourcesDetail={resourcesDetail} />

                <div className="tab" id="tab-3">
                    <div className="location-box">
                        <h3>Localisation</h3>
                        <div className="map-inner">
                            <div
                                className="google-map"
                                id="contact-google-map"
                                data-map-lat="40.712776"
                                data-map-lng="-74.005974"
                                data-icon-path="assets/images/icons/map-marker.png"
                                data-map-title="Brooklyn, New York, United Kingdom"
                                data-map-zoom="12"
                                data-markers='{
                        "marker-1": [40.712776, -74.005974, "<h4>Branch Office</h4><p>77/99 New York</p>","assets/images/icons/map-marker.png"]
                    }'
                            ></div>
                        </div>
                        {/*<h4>New Apollo Hospital:</h4>*/}
                        <ul className="location-info clearfix">
                            <li>
                                <i className="fas fa-map-marker-alt"></i>
                                {resourcesDetail.attributes.city}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DoctorTabsBox;
