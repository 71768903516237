/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import resources from '../../utils/resources';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.Footer;
const WordingAdresse = WordingConstant.Adresse;

const Footer: FC<unknown> = () => {
    return (
        <>
            <footer className="main-footer">
                <div className="footer-top">
                    <div className="pattern-layer">
                        <div
                            className="pattern-1"
                            style={{
                                backgroundImage:
                                    'url(assets/images/shape/shape-30.png)',
                            }}
                        />
                        <div
                            className="pattern-2"
                            style={{
                                backgroundImage:
                                    'url(assets/images/shape/shape-31.png)',
                            }}
                        />
                    </div>
                    <div className="auto-container">
                        <div className="widget-section">
                            <div className="row clearfix">
                                <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                                    <div className="footer-widget logo-widget">
                                        <figure className="footer-logo">
                                            <Link to="/">
                                                <img
                                                    src={
                                                        resources['ic-loctore']
                                                    }
                                                    alt=""
                                                />
                                            </Link>
                                        </figure>
                                        <div className="text">
                                            <p>{WordingAdresse.presentation}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-2 col-md-6 col-sm-12 footer-column">
                                    <div className="footer-widget links-widget">
                                        <div className="widget-title">
                                            <h3>À propos</h3>
                                        </div>
                                        <div className="widget-content">
                                            <ul className="links clearfix">
                                                {Wording.About.map(
                                                    (e, index) => {
                                                        return (
                                                            <li
                                                                key={index.toString()}
                                                            >
                                                                <a
                                                                    href={
                                                                        e.link
                                                                    }
                                                                >
                                                                    {e.text}
                                                                </a>
                                                            </li>
                                                        );
                                                    },
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                                    <div className="footer-widget links-widget">
                                        <div className="widget-title">
                                            <h3>Liens utiles</h3>
                                        </div>
                                        <div className="widget-content">
                                            <ul className="links clearfix">
                                                {Wording.UsefulLinks.map(
                                                    (e, index) => {
                                                        return (
                                                            <li
                                                                key={index.toString()}
                                                            >
                                                                <a
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    key={0}
                                                                    href={
                                                                        e.link
                                                                    }
                                                                >
                                                                    {e.text}
                                                                </a>
                                                            </li>
                                                        );
                                                    },
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                                    <div className="footer-widget contact-widget">
                                        <div className="widget-title">
                                            <h3>Contacts</h3>
                                        </div>
                                        <div className="widget-content">
                                            <ul className="info-list clearfix">
                                                <li>
                                                    <i className="fas fa-map-marker-alt"></i>
                                                    {WordingAdresse.city}
                                                </li>
                                                <li>
                                                    <i className="fas fa-microphone"></i>
                                                    <a href="tel:+33619645438">
                                                        {WordingAdresse.tel}
                                                    </a>
                                                </li>
                                                <li>
                                                    <i className="fas fa-envelope"></i>
                                                    <a href="mailto:contact@loctore.com">
                                                        {WordingAdresse.email}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="inner-box clearfix">
                            <div className="copyright pull-left">
                                <p>
                                    <Link to="/">Loctore</Link> &copy;{' '}
                                    {new Date().getFullYear()} Tous droits
                                    réservés
                                </p>
                            </div>
                            <ul className="footer-nav pull-right clearfix">
                                <li>
                                    <a
                                        href="https://loctore.com/cgu"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Conditions d'utilisation
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://loctore.com/cgu"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Politique de confidentialité
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>

            {/* Scroll to top */}
            <button className="scroll-top scroll-to-target" data-target="html">
                <span className="fa fa-arrow-up" />
            </button>
        </>
    );
};

export default Footer;
