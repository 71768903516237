/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
/*jsx-a11y/click-events-have-key-events*/
/* eslint-disable jsx-a11y/interactive-supports-focus */

import _ from 'lodash';
import React, { FC } from 'react';
import { IRelative, IRelativeData } from 'Models';

type Props = {
    relativeData: IRelative | [];
    handleShowDeleteRelativeModal(relativeId: string): void;
    handleUpdateRelative(item: IRelativeData): void;
};
const RelativesTable: FC<Props> = ({
    relativeData,
    handleUpdateRelative,
    handleShowDeleteRelativeModal,
}) => {
    return (
        <div>
            {!_.isEmpty(relativeData) &&
            _.isEqual(_.get(relativeData, 'data').length, 1) ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    {'La liste de proche est vide pour le moment'}
                </div>
            ) : (
                <div className="doctors-list">
                    <div className="table-outer">
                        <table className="doctors-table">
                            <thead className="table-header">
                                <tr>
                                    <th>Nom</th>
                                    <th>Adresse</th>
                                    <th>Téléphone</th>
                                    <th>Email</th>
                                    <th>Action</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {typeof _.get(relativeData, 'data') !==
                                    'undefined' &&
                                    _.get(relativeData, 'data')
                                        .filter((word: IRelativeData) =>
                                            _.isEqual(
                                                word.attributes.default,
                                                false,
                                            ),
                                        )
                                        .map((item: IRelativeData) => {
                                            return (
                                                <tr>
                                                    {
                                                        <td>
                                                            <div
                                                                style={{
                                                                    padding:
                                                                        '15%',
                                                                }}>
                                                                <h5>
                                                                    {
                                                                        item
                                                                            .attributes
                                                                            .full_name
                                                                    }
                                                                </h5>
                                                            </div>
                                                        </td>
                                                    }

                                                    <td>
                                                        <p>
                                                            {
                                                                item.attributes
                                                                    .address
                                                            }
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            {
                                                                item.attributes
                                                                    .phone
                                                            }
                                                        </p>
                                                    </td>

                                                    <td>
                                                        <p>
                                                            {
                                                                item.attributes
                                                                    .email
                                                            }
                                                        </p>
                                                    </td>

                                                    <td>
                                                        <span
                                                            className="accept"
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            role="button"
                                                            onClick={() =>
                                                                handleUpdateRelative(
                                                                    item,
                                                                )
                                                            }>
                                                            <i className="fas fa-pencil-alt"></i>
                                                            Modifier
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            className="cancel"
                                                            role="button"
                                                            onClick={() =>
                                                                handleShowDeleteRelativeModal(
                                                                    item.id,
                                                                )
                                                            }>
                                                            <i className="fas fa-times"></i>
                                                            Supprimer
                                                        </span>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RelativesTable;
