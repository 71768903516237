import { useState } from 'react';
import { InputEvent } from 'Types';

const useForm = (
    initialFValues: any,
    validateOnChange = false,
    validate: (arg0: { [x: number]: any }) => void,
) => {
    const [values, setValues] = useState(initialFValues);
    const [errors, setErrors] = useState<any>({});

    const handleInputChange = (e: InputEvent) => {
        const { name, value } = e.currentTarget;
        setValues({
            ...values,
            [name]: value,
        });
        if (validateOnChange) validate({ [name]: value });
    };

    const resetForm = () => {
        setValues(initialFValues);
        setErrors({});
    };

    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
    };
};

export default useForm;
