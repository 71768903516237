/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { RootState } from 'Types';
import { useDispatch, useSelector } from 'react-redux';
import Documents from '../../components/Documents';
import withLoader from '../../shared/hoc/withLoader';
import {
    getDocumentAsync,
    deleteDocumentAsync,
} from '../../_actions/document/dcument.actions';
import DocumentModal from '../../components/DocumentModal';
import WithModal from '../../utils/hooks/WithModal';

const WrappedDocuments = withLoader()(Documents);
const WrappedModal = WithModal()(DocumentModal);

const DocumentsScreen: FC<unknown> = () => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [docId, setDocId] = useState<string>('');

    const { data } = useSelector((state: RootState) => state.userProfilReducer);

    const { documents, loading, isDeleted } = useSelector(
        (state: RootState) => state.documentsReducer,
    );

    useEffect(() => {
        dispatch(getDocumentAsync.request({}));
    }, []);

    useEffect(() => {
        if (_.isEqual(isDeleted, true)) {
            dispatch(getDocumentAsync.request({}));
        }
    }, [isDeleted, dispatch]);

    const handleDelete = () => {
        setShowModal(false);
        if (!_.isEmpty(docId)) {
            dispatch(deleteDocumentAsync.request(docId));
            setShowModal(false);
        }
    };

    const handleCancel = () => {
        setShowModal(false);
        setDocId('');
    };

    const handleShowDocumentModal = (id: string) => {
        setShowModal(true);
        setDocId(id);
    };

    const handleLogout = () => {
        console.log('hjjfjj');
    };

    return (
        <>
            <WrappedDocuments
                loading={loading}
                userData={data}
                documents={documents}
                handleShowDocumentModal={handleShowDocumentModal}
                handleLogout={handleLogout}
            />
            <WrappedModal
                open={showModal}
                handleDelete={handleDelete}
                handleCancel={handleCancel}
            />
        </>
    );
};

export default DocumentsScreen;
