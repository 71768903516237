/* eslint-disable import/no-extraneous-dependencies */
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { IDocument } from 'Models';

import * as documentsAction from '../../_actions/document/dcument.actions';

export type DocumentsState = Readonly<{
    loading: boolean;
    documents: IDocument[] | [];
    error: '';
    isDeleted: boolean;
    isAdded: boolean;
}>;

export const initialState: DocumentsState = {
    loading: false,
    documents: [],
    error: '',
    isDeleted: false,
    isAdded: false,
};

type Actions = documentsAction.DocumentsAction;

const documentsReducer: Reducer<DocumentsState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(documentsAction.addDocumentAsync.request):
        case getType(documentsAction.deleteDocumentAsync.request):
        case getType(documentsAction.getDocumentAsync.request): {
            return {
                ...state,
                loading: true,
                error: '',
                isDeleted: false,
                isAdded: false,
            };
        }

        case getType(documentsAction.getDocumentAsync.success): {
            return {
                ...state,
                loading: false,
                documents: action.payload,
                error: '',
                isDeleted: false,
                isAdded: false,
            };
        }
        case getType(documentsAction.deleteDocumentAsync.success): {
            return {
                ...state,
                loading: false,
                error: '',
                isDeleted: true,
                isAdded: false,
            };
        }

        case getType(documentsAction.addDocumentAsync.success): {
            return {
                ...state,
                loading: false,
                error: '',
                isDeleted: false,
                isAdded: true,
            };
        }

        case getType(documentsAction.addDocumentAsync.failure):
        case getType(documentsAction.deleteDocumentAsync.failure):
        case getType(documentsAction.getDocumentAsync.failure): {
            return {
                ...state,
                loading: false,
                error: action.payload,
                isDeleted: false,
                isAdded: false,
            };
        }

        default:
            return { ...state };
    }
};
export default documentsReducer;
