import React, { FC, useState, useEffect } from 'react';
import { RootState } from 'Types';
import { useDispatch, useSelector } from 'react-redux';
import { IUpdateUserRequest } from 'Models';

import PatientProfile from '../../components/PatientProfile';
import { isValidEmail } from '../../utils/helpers';
import useForm from '../../shared/hoc/useForm';
import WordingConstant from '../../utils/wording.json';
import Footer from '../../components/Footer';
import withLoader from '../../shared/hoc/withLoader';
import {
    getUserProfilAsync,
    updateUserProfilAsync,
} from '../../_actions/profil/profil.actions';
import useScript from '../../utils/hooks/useScript';

const Wording = WordingConstant.PatientProfil;
const WrappedPatientProfile = withLoader()(PatientProfile);

const PatientProfileScreen: FC<unknown> = () => {
    const dispatch = useDispatch();
    useScript('/static/js/jquery.nice-select.min.js');
    useScript('/static/js/script.js');

    const { data, loading } = useSelector(
        (state: RootState) => state.userProfilReducer,
    );
    const [isChecked, setIsChecked] = useState(
        data.attributes.card.gender === null
            ? 'male'
            : data.attributes.card.gender,
    );

    useEffect(() => {
        $('#country').val(`${data.attributes.card.birth_country_code}`);
    }, []);

    const [fields] = useState({
        id: 0,
        firstname: data.attributes.card.first_name,
        lastname: data.attributes.card.last_name,
        email: data.attributes.email,
        birthdate: data.attributes.card.birthdate,
        gender:
            data.attributes.card.gender === null
                ? 'male'
                : data.attributes.card.gender,
        phone: data.attributes.card.phone,
        address: data.attributes.card.address,
        zipcode: data.attributes.card.zip_code,
        birthcity: data.attributes.card.birth_city,
    });

    const validate = (fieldValues = values) => {
        const temp: any = { ...errors };

        if ('firstname' in fieldValues) {
            temp.firstname = fieldValues.firstname
                ? ''
                : Wording.Errors.firstname;
        }
        if ('lastname' in fieldValues) {
            temp.lastname = fieldValues.lastname ? '' : Wording.Errors.lastname;
        }
        if ('email' in fieldValues) {
            temp.email = isValidEmail(fieldValues.email)
                ? ''
                : Wording.Errors.email;
        }
        if ('birthdate' in fieldValues) {
            temp.birthdate = fieldValues.birthdate
                ? ''
                : Wording.Errors.birthdate;
        }
        if ('gender' in fieldValues) {
            temp.gender = fieldValues.gender ? '' : Wording.Errors.gender;
        }
        if ('phone' in fieldValues) {
            temp.phone = fieldValues.phone ? '' : Wording.Errors.phone;
        }
        if ('address' in fieldValues) {
            temp.address = fieldValues.address ? '' : Wording.Errors.address;
        }
        if ('zipcode' in fieldValues) {
            temp.zipcode = fieldValues.zipcode ? '' : Wording.Errors.zipcode;
        }
        if ('birthcity' in fieldValues) {
            temp.birthcity = fieldValues.birthcity
                ? ''
                : Wording.Errors.birthcity;
        }
        if ('birthcountrycode' in fieldValues) {
            temp.birthcountrycode = fieldValues.birthcountrycode
                ? ''
                : Wording.Errors.birthcountrycode;
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
    };
    const handleOnClick = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validate()) {
            const birthcountrycode = $('#country').val();
            const user: IUpdateUserRequest = {
                card_attributes: {
                    gender: String(isChecked),
                    email: values.email,
                    address: values.address,
                    city: values.city,
                    country_code: '',
                    birth_city: values.birthcity,
                    birth_country_code: String(birthcountrycode),
                    first_name: values.firstname,
                    last_name: values.lastname,
                    phone: values.phone,
                    birthdate: values.birthdate,
                    zip_code: values.zipcode,
                },
            };
            console.log('names : ', user);

            dispatch(
                updateUserProfilAsync.request({
                    card_attributes: user.card_attributes,
                }),
            );
        }
    };

    const { values, errors, setErrors, handleInputChange } = useForm(
        fields,
        true,
        validate,
    );

    const handleCheck = (name: string) => {
        setIsChecked(name);
    };

    const handleLogout = () => {};

    return (
        <>
            <WrappedPatientProfile
                loading={loading}
                userData={data}
                handleInputChange={handleInputChange}
                fields={values}
                errors={errors}
                isChecked={isChecked}
                handleOnClick={handleOnClick}
                handleCheck={handleCheck}
                handleLogout={handleLogout}
            />

            <Footer />
        </>
    );
};

export default PatientProfileScreen;
