/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC } from 'react';

type Props = {
    profilImg: string;
    name: string;
    speciality: string;
    city: string;
    videoConsultation: boolean;
    biography: string;
};

const DoctorCard: FC<Props> = ({
    profilImg,
    name,
    speciality,
    city,
    videoConsultation,
    biography,
}) => {
    return (
        <div className="clinic-block-one">
            <div className="inner-box">
                <figure className="image-box">
                    <img src={profilImg} alt="" />
                </figure>
                <div className="content-box">
                    {_.isEqual(videoConsultation, true) && (
                        <div className="like-box" data-testid="fa-video">
                            <a href="#/">
                                <i
                                    className="fas fa-video"
                                    style={{
                                        animation: 'blinker 2s linear infinite',
                                    }}
                                />
                            </a>
                        </div>
                    )}

                    <ul className="name-box clearfix">
                        <li className="name">
                            <h2>{name}</h2>
                        </li>
                        <li>
                            <i className="icon-Trust-1" />
                        </li>
                        <li>
                            <i className="icon-Trust-2" />
                        </li>
                    </ul>
                    <span className="designation">{speciality}</span>
                    <div className="rating-box clearfix"></div>
                    <div className="text">
                        <p>{biography}</p>
                    </div>
                    <div className="lower-box clearfix">
                        <ul className="info clearfix">
                            <li>
                                <i className="fas fa-map-marker-alt" />
                                {city}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DoctorCard;
