/* eslint-disable import/no-extraneous-dependencies */
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import {
    IAppointementDetails,
    IAppointement,
    ICreateAppointement,
} from 'Models';

import * as appointementAction from '../../_actions/apointement/appointement.actions';
import * as authActions from '../../_actions/auth/auth.actions';

export type AppointementState = Readonly<{
    loading: boolean;
    start: boolean;
    appointement: IAppointement | [];
    appointementDetails: IAppointementDetails;
    createAppointement?: ICreateAppointement;
    isCreated: boolean;
    isUpdated: boolean;
    isCanceled: boolean;
    startUpdated: boolean;
    error: any;
    // tmpAppointement?: IAppointementDetails | undefined;
}>;

export const initialState: AppointementState = {
    loading: false,
    start: false,
    createAppointement: undefined,
    isCreated: false,
    isUpdated: false,
    isCanceled: false,
    startUpdated: false,
    error: undefined,
    appointement: {
        data: [
            {
                id: '',
                type: '',
                attributes: {
                    starts_at: '',
                    ends_at: '',
                    state: '',
                    video_consultation: false,
                    video_meet_id: '',
                    video_meet_url: '',
                    resource: {
                        id: '',
                        name: '',
                        speciality: '',
                        photo: {
                            small_url: '',
                            medium_url: '',
                            large_url: '',
                        },
                    },
                    created_at: '',
                    updated_at: '',
                },
                relationships: {
                    intervention: {
                        data: {
                            id: '',
                            type: '',
                        },
                    },
                },
            },
        ],
        included: [
            {
                id: '',
                type: '',
                attributes: {
                    name: '',
                    price: '',
                },
            },
        ],
    },
    appointementDetails: {
        data: {
            id: '',
            type: '',
            attributes: {
                starts_at: '',
                ends_at: '',
                state: '',
                video_consultation: false,
                video_meet_id: '',
                video_meet_url: '',
                resource: {
                    id: '',
                    name: '',
                    speciality: '',
                    photo: {
                        small_url: '',
                        medium_url: '',
                        large_url: '',
                    },
                },
                created_at: '',
                updated_at: '',
            },
            relationships: {
                intervention: {
                    data: {
                        id: '',
                        type: '',
                    },
                },
            },
        },
        included: [
            {
                id: '',
                type: '',
                attributes: {
                    name: '',
                    price: '',
                },
            },
        ],
    },
};
type Actions = appointementAction.AppointementAction | authActions.AuthAction;

const appointementReducer: Reducer<AppointementState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(appointementAction.cancelAppointementAsync.request):
        case getType(appointementAction.updateAppointementAsync.request):
        case getType(appointementAction.getAppointementDetailsAsync.request):
        case getType(appointementAction.createAppointementAsync.request):
        case getType(appointementAction.getAppointementAsync.request): {
            return {
                ...state,
                loading: true,
                error: undefined,
                isUpdated: false,
                isCreated: false,
                isCanceled: false,
                updateAppointement: undefined,
                start: false,
            };
        }
        case getType(appointementAction.startAppointement): {
            return {
                ...state,
                loading: false,
                start: action.payload,
                isCreated: false,
                isUpdated: false,
                isCanceled: false,
                updateAppointement: undefined,
            };
        }

        case getType(appointementAction.getAppointementAsync.success): {
            return {
                ...state,
                loading: false,
                start: false,
                appointement: action.payload,
                isCreated: false,
                isUpdated: false,
                isCanceled: false,
                updateAppointement: undefined,
            };
        }

        case getType(appointementAction.getAppointementDetailsAsync.success): {
            return {
                ...state,
                loading: false,
                start: false,
                appointementDetails: action.payload,
                isCreated: false,
                isUpdated: false,
                isCanceled: false,
                updateAppointement: undefined,
            };
        }

        case getType(appointementAction.cancelAppointementAsync.failure):
        case getType(appointementAction.updateAppointementAsync.failure):
        case getType(appointementAction.createAppointementAsync.failure):
        case getType(appointementAction.getAppointementAsync.failure): {
            return {
                ...state,
                loading: false,
                start: false,
                appointement: [],
                error: action.payload,
                isCreated: false,
                isUpdated: false,
                isCanceled: false,
                updateAppointement: undefined,
            };
        }

        case getType(appointementAction.getAppointementDetailsAsync.failure): {
            return {
                ...state,
                loading: false,
                start: false,
                appointement: [],
                error: action.payload,
                isCreated: false,
                isUpdated: false,
                isCanceled: false,
                updateAppointement: undefined,
            };
        }

        case getType(authActions.logoutAsync.success): {
            return {
                ...state,
                loading: false,
                start: false,
                appointement: [],
                appointementDetails: initialState.appointementDetails,
                isCreated: false,
                isUpdated: false,
                isCanceled: false,
                updateAppointement: undefined,
                startUpdated: false,
            };
        }

        case getType(appointementAction.createAppointementAsync.success): {
            return {
                ...state,
                loading: false,
                isCreated: true,
                start: false,
                createAppointement: action.payload,
                isUpdated: false,
                isCanceled: false,
                updateAppointement: undefined,
                startUpdated: false,
            };
        }

        case getType(appointementAction.updateAppointementAsync.success): {
            return {
                ...state,
                loading: false,
                isCreated: false,
                start: false,
                createAppointement: action.payload,
                isUpdated: true,
                isCanceled: false,
                updateAppointement: undefined,
                startUpdated: false,
            };
        }

        case getType(appointementAction.cancelAppointementAsync.success): {
            return {
                ...state,
                loading: false,
                isCreated: false,
                start: false,
                createAppointement: action.payload,
                isUpdated: false,
                isCanceled: true,
                updateAppointement: undefined,
                startUpdated: false,
            };
        }

        case getType(appointementAction.resetAppointementFeedBack): {
            return {
                ...state,
                loading: false,
                isCreated: false,
                start: false,
                createAppointement: undefined,
                isUpdated: false,
                isCanceled: false,
                updateAppointement: undefined,
                startUpdated: false,
                error: undefined,
            };
        }

        case getType(appointementAction.startUpdatedAppointement): {
            return {
                ...state,
                loading: false,
                isCreated: false,
                start: false,
                createAppointement: undefined,
                isUpdated: false,
                startUpdated: action.payload,
                isCanceled: false,
            };
        }

        default:
            return { ...state };
    }
};
export default appointementReducer;
