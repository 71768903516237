/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import React, { FC, useState, useEffect } from 'react';
import { IUserData, IRelativeData } from 'Models';
import { InputEvent } from 'Types';

import BackgroundElement from '../../components/BackgroundElement';
import ProfileInfoSidbar from '../../components/ProfileInfoSidbar';
import useScript from '../../utils/hooks/useScript';
import Input from '../../components/Input';
import Button from '../../components/Button';
import documentType from '../../utils/documentType.json';
import Form from '../../components/Form';
import resources from '../../utils/resources';

type Props = {
    userData: IUserData;
    fields: any;
    errors: any;
    handleLogout(): void;
    handleInputChange(e: InputEvent): void;
    handleSearchFile(e: React.ChangeEvent<HTMLInputElement>): void;
    handleOnClick(e: React.FormEvent<HTMLFormElement>): void;
};

const DocumentForm: FC<Props> = ({
    userData,
    fields,
    errors,
    handleLogout,
    handleInputChange,
    handleSearchFile,
    handleOnClick,
}) => {
    useScript('/static/js/jquery.nice-select.min.js');
    useScript('/static/js/script.js');
    return (
        <>
            <BackgroundElement
                title="Ajouter un document"
                linkTitle="Tableau de bord du patient"
                subTitle="Ajouter un document"
                linkPath="/patient-dashboard"
            />
            <section className="doctors-dashboard bg-color-3">
                <ProfileInfoSidbar
                    userData={userData}
                    handleLogout={handleLogout}
                />
                <div className="right-panel">
                    <div className="content-container">
                        <div className="outer-container">
                            <div className="add-listing my-profile">
                                <div className="single-box">
                                    <div className="title-box">
                                        <h3>Ajouter un document</h3>
                                    </div>
                                    <div className="inner-box">
                                        <div className="profile-title">
                                            <figure className="image-box">
                                                <img
                                                    src={
                                                        resources['ic-profil-1']
                                                    }
                                                    alt=""
                                                />
                                            </figure>
                                            <div className="upload-photo">
                                                <input
                                                    type="file"
                                                    accept="application/pdf, image/png, image/jpeg "
                                                    id="file"
                                                    className="input-file"
                                                    onChange={(e) =>
                                                        handleSearchFile(e)
                                                    }
                                                    required
                                                    multiple
                                                />
                                                {/*<a href="my-profile.html">
                                                    <i className="icon-Upload"></i>
                                                    Télécharge un document
                                                </a>*/}
                                                <span>
                                                    Les documents autorisés JPG,
                                                    PNG et PDF
                                                </span>
                                            </div>
                                        </div>
                                        <Form
                                            className="registration-form needs-validation"
                                            autoComplete="off"
                                        >
                                            <div className="row clearfix">
                                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        label="Nom du document"
                                                        placeholder="Entrer votre Nom"
                                                        required
                                                        htmlFor="validationCustom03"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={fields.name}
                                                        error={errors.name}
                                                        className=""
                                                        disabled={false}
                                                        autocomplete=""
                                                        onClick={() => {}}
                                                        checked={false}
                                                        id="name"
                                                    />
                                                </div>

                                                <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                                    <label>
                                                        Type de document
                                                    </label>
                                                    <select
                                                        id="documentType"
                                                        className="wide"
                                                    >
                                                        {documentType.map(
                                                            (item) => {
                                                                return (
                                                                    <option
                                                                        value={
                                                                            item.key
                                                                        }
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </option>
                                                                );
                                                            },
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                                <div className="btn-box">
                                    <Button
                                        title=""
                                        className="theme-btn-one"
                                        onClick={handleOnClick}
                                    >
                                        Ajouter
                                        <i className="icon-Arrow-Right"></i>
                                    </Button>
                                    <Button
                                        onClick={() => {}}
                                        title=""
                                        className="cancel-btn"
                                    >
                                        Annuler
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default DocumentForm;
