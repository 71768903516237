import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { IData, IAvailability } from 'Models';

import * as summaryAction from '../../_actions/summary/summary.actions';
import * as authActions from '../../_actions/auth/auth.actions';

export type SummaryState = Readonly<{
    loading: boolean;
    doctor: IData;
    intervention: summaryAction.InterventionSummary;
    availability: IAvailability;
}>;

export const initialState: SummaryState = {
    loading: false,
    doctor: {
        id: '',
        type: '',
        attributes: {
            name: '',
            created_at: new Date(),
            speciality: '',
            video_consultation: false,
            doctor: {
                email: '',
                first_name: '',
                last_name: '',
                photo: {
                    small_url: '',
                    medium_url: '',
                    large_url: '',
                },
                city: '',
                video_consultation: false,
            },
            city: '',
            country_code: '',
            favorited: false,
        },
        meta: {
            interventions_url: '',
        },
    },
    intervention: {
        id: '',
        name: '',
        price: '',
    },
    availability: {
        id: '',
        starts_at: new Date(),
        ends_at: new Date(),
        time: '',
    },
};
type Actions = summaryAction.SummaryAction | authActions.AuthAction;

const summaryReducer: Reducer<SummaryState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(summaryAction.setDoctorSummary): {
            return {
                ...state,
                loading: false,
                doctor: {
                    id: action.payload.id,
                    type: action.payload.type,
                    attributes: action.payload.attributes,
                    meta: action.payload.meta,
                },
            };
        }

        case getType(summaryAction.setInterventionSummary): {
            return {
                ...state,
                loading: false,
                intervention: {
                    id: action.payload.id,
                    name: action.payload.name,
                    price: action.payload.price,
                },
            };
        }

        case getType(summaryAction.setAvailabilitiesSummary): {
            return {
                ...state,
                loading: false,
                availability: {
                    id: action.payload.id,
                    time: action.payload.time,
                    starts_at: action.payload.starts_at,
                    ends_at: action.payload.ends_at,
                },
            };
        }

        case getType(summaryAction.resetDoctorSummary): {
            return {
                ...initialState,
            };
        }

        case getType(authActions.logoutAsync.success): {
            return {
                ...state,
                loading: false,
                doctor: initialState.doctor,
                intervention: initialState.intervention,
                availability: initialState.availability,
            };
        }

        default:
            return { ...state };
    }
};
export default summaryReducer;
