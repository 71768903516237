/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
/*jsx-a11y/click-events-have-key-events*/
/* eslint-disable jsx-a11y/interactive-supports-focus */
import _ from 'lodash';
import React, { FC } from 'react';
import { IDocument, IDocumentData } from 'Models';
import { converteToFormatLL, formatDocumentType } from '../../utils/helpers';

type Props = {
    documents: IDocument[];
    handleShowDocumentModal(docId: string): void;
};

const DocumentTable: FC<Props> = ({ documents, handleShowDocumentModal }) => {
    return (
        <div>
            {_.isEmpty(_.get(documents, 'data')) ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    {"Vous n'avez pas document pour le moment"}
                </div>
            ) : (
                <div className="doctors-list">
                    <div className="table-outer">
                        <table className="doctors-table">
                            <thead className="table-header">
                                <tr>
                                    <th>Nom</th>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {typeof _.get(documents, 'data') !==
                                    'undefined' &&
                                    _.get(documents, 'data').map(
                                        (item: IDocumentData) => {
                                            return (
                                                <tr>
                                                    {
                                                        <td>
                                                            <div
                                                                style={{
                                                                    padding:
                                                                        '15%',
                                                                }}>
                                                                <h5>
                                                                    {
                                                                        item
                                                                            .attributes
                                                                            .name
                                                                    }
                                                                </h5>
                                                            </div>
                                                        </td>
                                                    }

                                                    <td>
                                                        <p>
                                                            {formatDocumentType(
                                                                item.attributes
                                                                    .document_type,
                                                            )}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            {converteToFormatLL(
                                                                new Date(
                                                                    item.attributes.created_at,
                                                                ),
                                                            )}
                                                        </p>
                                                    </td>

                                                    <td>
                                                        <a
                                                            href={
                                                                item.attributes
                                                                    .file_url
                                                            }
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="accept"
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            role="button"
                                                            onClick={() => {}}>
                                                            <i className="fas fa-eye"></i>
                                                            Voir
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <span
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            className="cancel"
                                                            role="button"
                                                            onClick={() =>
                                                                handleShowDocumentModal(
                                                                    item.id,
                                                                )
                                                            }>
                                                            <i className="fas fa-times"></i>
                                                            Supprimer
                                                        </span>
                                                    </td>
                                                </tr>
                                            );
                                        },
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DocumentTable;
