import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { IRootAvailability } from 'Models';

import * as availabilitiesAction from '../../_actions/availabilities/availabilities.actions';
import * as authActions from '../../_actions/auth/auth.actions';

export type ResourcesState = Readonly<{
    loading: boolean;
    availabilities: IRootAvailability;
    error: string;
}>;

export const initialState: ResourcesState = {
    loading: false,
    availabilities: {
        data: [],
    },
    error: '',
};

type Actions =
    | availabilitiesAction.AvailabilitiesAction
    | authActions.AuthAction;

const availabilitiesReducer: Reducer<ResourcesState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        /*labilitiesAsync.request): {
            return {
                ...state,
                loading: true,
                error: '',
            };
        }*/

        case getType(availabilitiesAction.getAvailabilitiesAsync.success): {
            return {
                ...state,
                loading: false,
                availabilities: {
                    data: action.payload.data,
                },
            };
        }

        case getType(availabilitiesAction.getAvailabilitiesAsync.failure): {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }

        case getType(authActions.logoutAsync.success): {
            return {
                ...state,
                loading: false,
                availabilities: {
                    data: [],
                },
                error: '',
            };
        }

        default:
            return { ...state };
    }
};
export default availabilitiesReducer;
