/* eslint-disable import/no-extraneous-dependencies */
import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import { createAsyncAction, isActionOf, ActionType } from 'typesafe-actions';
import { RootAction, RootState, Services } from 'Types';

import {
    IUserRootObject,
    IUpdateUserRequest,
    IResetUserPasswordRequest,
} from 'Models';

import {
    GET_USER_PROFIL_REQUEST,
    GET_USER_PROFIL_SUCCESS,
    GET_USER_PROFIL_FAILURE,
    UPDATE_USER_PROFIL_REQUEST,
    UPDATE_USER_PROFIL_SUCCESS,
    UPDATE_USER_PROFIL_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
} from '../actionTypes';

const getUserProfilAsync = createAsyncAction(
    GET_USER_PROFIL_REQUEST,
    GET_USER_PROFIL_SUCCESS,
    GET_USER_PROFIL_FAILURE,
)<any, IUserRootObject, any>();

const updateUserProfilAsync = createAsyncAction(
    UPDATE_USER_PROFIL_REQUEST,
    UPDATE_USER_PROFIL_SUCCESS,
    UPDATE_USER_PROFIL_FAILURE,
)<IUpdateUserRequest, IUserRootObject, any>();

const resetPasswordAsync = createAsyncAction(
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
)<IResetUserPasswordRequest, IUserRootObject, any>();

const preparePayloadResetPassword = ({
    password,
    password_confirmation,
}: IResetUserPasswordRequest) => {
    return {
        password,
        password_confirmation,
    };
};

const prepareAddRelativePayload = (card_attributes: IUpdateUserRequest) => {
    return {
        card_attributes,
    };
};

export type ProfilAction =
    | ActionType<typeof getUserProfilAsync>
    | ActionType<typeof resetPasswordAsync>
    | ActionType<typeof updateUserProfilAsync>;

const mapGetProfil = (action: RootAction, { apiRequest }: Services) => {
    return apiRequest<IUserRootObject>({
        path: '/me',
        method: 'get',
        body: '',
    }).pipe(
        mergeMap((response: IUserRootObject) => {
            if (response) {
                return of(getUserProfilAsync.success(response));
            }
            return of(getUserProfilAsync.failure(response));
        }),
        catchError((error) => {
            return of(getUserProfilAsync.failure(error));
        }),
    );
};
const mapUpdateProfil = (action: RootAction, { apiRequest }: Services) => {
    const payload = prepareAddRelativePayload(action.payload);
    return apiRequest<IUserRootObject>({
        path: '/me',
        method: 'put',
        body: payload.card_attributes,
    }).pipe(
        mergeMap((response: IUserRootObject) => {
            if (response) {
                return of(updateUserProfilAsync.success(response));
            }
            return of(updateUserProfilAsync.failure(response));
        }),
        catchError((error) => {
            return of(updateUserProfilAsync.failure(error));
        }),
    );
};

const mapPostResetPassword = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadResetPassword(action.payload);
    return apiRequest<IUserRootObject>({
        path: '/me',
        method: 'put',
        body: payload,
    }).pipe(
        mergeMap((response: IUserRootObject) => {
            if (response) {
                return of(resetPasswordAsync.success(response));
            }
            return of(resetPasswordAsync.failure(response));
        }),
        catchError((error) => {
            return of(resetPasswordAsync.failure(error));
        }),
    );
};

const userProfilEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(getUserProfilAsync.request)),
        mergeMap((action) => mapGetProfil(action, dependency)),
    );

const updateProfilEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(updateUserProfilAsync.request)),
        mergeMap((action) => mapUpdateProfil(action, dependency)),
    );

const resetUserPasswordEpic: Epic<RootAction, RootAction, RootState, Services> =
    (action$, state$, dependency) =>
        action$.pipe(
            filter(isActionOf(resetPasswordAsync.request)),
            mergeMap((action) => mapPostResetPassword(action, dependency)),
        );

export {
    getUserProfilAsync,
    userProfilEpic,
    updateProfilEpic,
    updateUserProfilAsync,
    resetPasswordAsync,
    resetUserPasswordEpic,
};
