import React, { FC } from 'react';
import { IUserData, IRelativeData } from 'Models';
import { InputEvent } from 'Types';
import { Helmet } from 'react-helmet';

import BackgroundElement from '../../components/BackgroundElement';
import ProfileInfoSidbar from '../../components/ProfileInfoSidbar';
import useScript from '../../utils/hooks/useScript';
import Input from '../../components/Input';
import Button from '../../components/Button';
import relatives from '../../utils/relation.json';
import country from '../../utils/country.json';
import Form from '../../components/Form';

type Props = {
    userData: IUserData;
    fields: any;
    errors: any;
    gender: string;
    relative: IRelativeData;
    handleLogout(): void;
    handleInputChange(e: InputEvent): void;
    handleOnClick(e: React.FormEvent<HTMLFormElement>): void;
    handleCheck(naem: string): void;
};

const RelativeForm: FC<Props> = ({
    userData,
    handleLogout,
    handleInputChange,
    handleCheck,
    gender,
    handleOnClick,
    fields,
    errors,
    relative,
}) => {
    useScript('/static/js/jquery.nice-select.min.js');
    useScript('/static/js/script.js');

    return (
        <>
            <Helmet>
                <script src="/static/js/jquery.nice-select.min.js"></script>
                <script src="/static/js/script.js"></script>
            </Helmet>

            <BackgroundElement
                title="Ajouter un proche"
                linkTitle="Tableau de bord du patient"
                subTitle="Ajouter un proche"
                linkPath="/patient-dashboard"
            />

            <section className="patient-dashboard bg-color-3">
                <ProfileInfoSidbar
                    userData={userData}
                    handleLogout={handleLogout}
                />
                <div className="right-panel">
                    <div className="content-container">
                        <div className="outer-container">
                            <div className="add-listing">
                                <div className="single-box">
                                    <div className="title-box">
                                        {typeof relative !== 'undefined' ? (
                                            <h3>Modifier un proche</h3>
                                        ) : (
                                            <h3>Ajouter un proche</h3>
                                        )}
                                    </div>
                                    <div className="inner-box">
                                        <Form
                                            className="registration-form needs-validation"
                                            autoComplete="off"
                                            noValidate
                                        >
                                            <div className="row clearfix">
                                                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <div className="custom-check-box">
                                                        <div className="custom-controls-stacked">
                                                            <label className="custom-control material-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="material-control-input"
                                                                    id="male"
                                                                    value="male"
                                                                    onClick={() =>
                                                                        handleCheck(
                                                                            'male',
                                                                        )
                                                                    }
                                                                    checked={
                                                                        gender ===
                                                                        'male'
                                                                    }
                                                                />
                                                                <span className="material-control-indicator"></span>
                                                                <span className="description">
                                                                    Homme
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="custom-check-box">
                                                        <div className="custom-controls-stacked">
                                                            <label className="custom-control material-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="material-control-input"
                                                                    id="female"
                                                                    value="female"
                                                                    onClick={() =>
                                                                        handleCheck(
                                                                            'female',
                                                                        )
                                                                    }
                                                                    checked={
                                                                        gender ===
                                                                        'female'
                                                                    }
                                                                />
                                                                <span className="material-control-indicator"></span>
                                                                <span className="description">
                                                                    Femme
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                                    <label>
                                                        Lien de parenté
                                                    </label>
                                                    <select
                                                        id="relation"
                                                        className="wide"
                                                    >
                                                        {relatives.map(
                                                            (item) => {
                                                                return (
                                                                    <option
                                                                        value={
                                                                            item.key
                                                                        }
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </option>
                                                                );
                                                            },
                                                        )}
                                                    </select>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                    <Input
                                                        type="text"
                                                        name="lastname"
                                                        label="Nom"
                                                        placeholder="Entrer votre Nom"
                                                        required
                                                        htmlFor="validationCustom03"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={fields.lastname}
                                                        error={errors.lastname}
                                                        className=""
                                                        disabled={false}
                                                        autocomplete=""
                                                        onClick={() => {}}
                                                        checked={false}
                                                        id="lastname"
                                                    />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                    <Input
                                                        type="text"
                                                        name="firstname"
                                                        label="Prénom"
                                                        placeholder="Entrer votre Prénom"
                                                        required
                                                        htmlFor="validationCustom03"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={fields.firstname}
                                                        error={errors.firstname}
                                                        className=""
                                                        disabled={false}
                                                        autocomplete=""
                                                        onClick={() => {}}
                                                        checked={false}
                                                        id="firstname"
                                                    />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                    <Input
                                                        type="text"
                                                        name="email"
                                                        label="email"
                                                        placeholder="Entrer votre email"
                                                        required
                                                        htmlFor="validationCustom03"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={fields.email}
                                                        error={errors.email}
                                                        className=""
                                                        disabled={false}
                                                        autocomplete=""
                                                        onClick={() => {}}
                                                        checked={false}
                                                        id="email"
                                                    />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                    <Input
                                                        type="text"
                                                        name="birthdate"
                                                        label="Date de naissance"
                                                        placeholder="Entrer votre date de naissance"
                                                        required
                                                        htmlFor="validationCustom03"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={fields.birthdate}
                                                        error={errors.birthdate}
                                                        className=""
                                                        disabled={false}
                                                        autocomplete=""
                                                        onClick={() => {}}
                                                        checked={false}
                                                        id="birthdate"
                                                    />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                    <Input
                                                        type="text"
                                                        name="birthcity"
                                                        label="Ville de naissance"
                                                        placeholder="Entrer votre ville de naissance"
                                                        required
                                                        htmlFor="validationCustom03"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={fields.birthcity}
                                                        error={errors.birthcity}
                                                        className=""
                                                        disabled={false}
                                                        autocomplete=""
                                                        onClick={() => {}}
                                                        checked={false}
                                                        id="birthcity"
                                                    />
                                                </div>

                                                <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                                    <label>
                                                        Pays de naissance
                                                    </label>
                                                    <select
                                                        id="country"
                                                        className="wide"
                                                    >
                                                        {country.map((item) => {
                                                            return (
                                                                <option
                                                                    value={item.alpha2.toLocaleUpperCase()}
                                                                >
                                                                    {item.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                    <Input
                                                        type="text"
                                                        name="phone"
                                                        label="Téléphone"
                                                        placeholder="Entrer votre Téléphone"
                                                        required
                                                        htmlFor="validationCustom03"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={fields.phone}
                                                        error={errors.phone}
                                                        className=""
                                                        disabled={false}
                                                        autocomplete=""
                                                        onClick={() => {}}
                                                        checked={false}
                                                        id="phone"
                                                    />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                    <Input
                                                        type="text"
                                                        name="address"
                                                        label="Address"
                                                        placeholder="Entrer votre Address"
                                                        required
                                                        htmlFor="validationCustom03"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={fields.address}
                                                        error={errors.address}
                                                        className=""
                                                        disabled={false}
                                                        autocomplete=""
                                                        onClick={() => {}}
                                                        checked={false}
                                                        id="address"
                                                    />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                    <Input
                                                        type="text"
                                                        name="city"
                                                        label="Ville"
                                                        placeholder="Entrer votre ville"
                                                        required
                                                        htmlFor="validationCustom03"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={fields.city}
                                                        error={errors.city}
                                                        className=""
                                                        disabled={false}
                                                        autocomplete=""
                                                        onClick={() => {}}
                                                        checked={false}
                                                        id="city"
                                                    />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                    <Input
                                                        type="text"
                                                        name="zipcode"
                                                        label="Code postale"
                                                        placeholder="Entrer votre code postale"
                                                        required
                                                        htmlFor="validationCustom03"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={fields.zipcode}
                                                        error={errors.zipcode}
                                                        className=""
                                                        disabled={false}
                                                        autocomplete=""
                                                        onClick={() => {}}
                                                        checked={false}
                                                        id="zipcode"
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                                <div className="btn-box">
                                    <Button
                                        title=""
                                        className="theme-btn-one"
                                        onClick={handleOnClick}
                                    >
                                        Ajouter
                                        <i className="icon-Arrow-Right"></i>
                                    </Button>
                                    <Button
                                        onClick={() => {}}
                                        title=""
                                        className="cancel-btn"
                                    >
                                        Annuler
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default RelativeForm;
