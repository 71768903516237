import React from 'react';
import Modal from '../../../components/Modal';

export type InjectedProps = {};

export type ExternalProps = {
    open: boolean;
};

const withModal =
    () =>
    <OwnProps extends InjectedProps>(
        WrappedComponent: React.ComponentType<OwnProps>,
    ): any => {
        type HocProps = OwnProps & ExternalProps;

        const component: React.FC<HocProps> = ({ open, ...props }) => {
            return (
                <Modal open={open}>
                    <WrappedComponent {...(props as OwnProps)} />
                </Modal>
            );
        };
        return component;
    };

export default withModal;
